import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SettingsFacade } from '@salary/common/facade';
import { Observable, ReplaySubject, merge, of, skip, take } from 'rxjs';
import { ChipsFilterData } from '../chips/chips-filter.component';

@Component({
  selector: 'salary-filter-button',
  template: `
    <salary-chip-button
      [title]="title()"
      [badgeText]="badgeText()"
      [panelClass]="configuration()?.panelClass"
      [menuContent]="menuTemplate"
    >
    </salary-chip-button>
    <ng-template #menuTemplate>
      <salary-filter-button-chips-popup
        [orientation]="configuration().orientation"
        [multiple]="configuration().multiple"
        [allowNothingSelected]="configuration().allowNothingSelected"
        [filterData]="filterData$ | async"
        (filterChanged)="filterChanged($event)"
      />
    </ng-template>
  `,

  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FilterButtonComponent {
  title = input<string>('Filtern');
  configuration = input.required<FilterButtonConfiguration>();
  protected badgeText = signal<number>(undefined);
  private settingFacade = inject(SettingsFacade);
  private destroyRef = inject(DestroyRef);
  protected filterData$ = new ReplaySubject<ChipsFilterData[]>(1);

  constructor() {
    const ref = effect(() => {
      if (this.configuration()) {
        untracked(() => {
          this.configuration().orientation = 'vertical';
          if (this.configuration().settingsKey) {
            this.handleSettingsKey();
          } else {
            this.setFilterData(this.configuration().chipsFilterData$);
          }
          ref.destroy();
        });
      }
    });
  }

  private handleSettingsKey() {
    this.configuration()
      .chipsFilterData$.pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        const setting = this.settingFacade.selectBenutzerSettingByKey<
          ChipsFilterSetting[]
        >(this.configuration().settingsKey)();
        if (setting?.value && data) {
          const initialSelectedIndex = data.findIndex((v) => v.selected);
          const intialData = data.map((initialValue) => {
            const selectedFromSetting = setting.value.find(
              (v) => v.name === (initialValue.settingsKey || initialValue.name),
            )?.selected;
            if (selectedFromSetting != null) {
              return { ...initialValue, selected: selectedFromSetting };
            }
            return initialValue;
          });
          if (
            !this.configuration().allowNothingSelected &&
            !intialData.some((d) => d.selected) &&
            initialSelectedIndex >= 0
          ) {
            intialData[initialSelectedIndex].selected = true;
          }
          this.setFilterData(
            merge(
              this.configuration().chipsFilterData$.pipe(skip(1)),
              of(intialData),
            ),
            false,
          );
        } else {
          this.setFilterData(this.configuration().chipsFilterData$, false);
        }
      });
  }

  private setFilterData(
    chipsData$: Observable<ChipsFilterData[]>,
    saveSettings = true,
  ) {
    this.filterData$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.filterChanged(data, saveSettings);
      });
    chipsData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((v) => this.filterData$.next(v));
  }

  protected filterChanged(data: ChipsFilterData[], saveSettings = true) {
    const selectedDataCount = data?.filter((d) => d.selected).length;
    this.badgeText.set(selectedDataCount === 0 ? undefined : selectedDataCount);
    if (saveSettings && this.configuration().settingsKey) {
      this.settingFacade.createOrUpdateUserSetting<ChipsFilterSetting[]>({
        key: this.configuration().settingsKey,
        value: data.map((d) => ({
          name: d.settingsKey || d.name,
          selected: d.selected,
        })),
      });
    }
    this.configuration().chipsFilterChanged(data);
  }
}

export interface FilterButtonConfiguration {
  chipsFilterData$: Observable<ChipsFilterData[]>;
  chipsFilterChanged: (chipsData: ChipsFilterData[]) => void;
  multiple?: boolean;
  /** default: true */
  allowNothingSelected?: boolean;
  orientation?: 'vertical' | 'horizontal';
  panelClass?: string;
  settingsKey?: string;
}

export interface ChipsFilterSetting {
  name: string;
  selected: boolean;
}
