import { Pipe, PipeTransform } from '@angular/core';
import { EmptyStateSize } from '@salary/common/dumb-components';
import { ToolbarDefinition } from '../utils';

@Pipe({
    name: 'listEmptyStateToolbarDefinition',
    standalone: false
})
export class ListEmptyStateToolbarDefinitionPipe implements PipeTransform {
  transform(
    toolbarDefinition: ToolbarDefinition,
    size: EmptyStateSize = 'large',
  ): ToolbarDefinition {
    const result = {
      ...toolbarDefinition,
    };
    if (size !== 'small') {
      result.buttonCSSClass = 'mat-mdc-raised-button mdc-button--raised';
    }
    return result;
  }
}
