import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  OnInit,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Observable, Unsubscribable, map } from 'rxjs';
import { SubNavigationLinkConfig } from './sub-navigation-link-config';
import { SubNavigationValidationService } from './sub-navigation-validation.service';
import { SubNavigationVisibleService } from './sub-navigation-visible.service';

@Component({
    selector: 'salary-sub-navigation-list-item',
    template: `
    <h2>
      @if ((isValid$ | async) === false) {
        <salary-validation-icon
          [testId]="link().name + '_link-icon'"
          [connectedTo]="titleElement"
        />
      }
      <a
        #titleElement
        class="linkColorAccent"
        [attr.data-testid]="link().name + '_link' | convertSpecialCharacter"
        queryParamsHandling="preserve"
        [routerLink]="[]"
        [fragment]="link().name"
        [class.active-sub-link]="isActive$ | async"
        [innerHtml]="link().caption"
      >
      </a>
    </h2>
  `,
    styles: `
    .active-sub-link {
      font-weight: 500;
    }
    :host ::ng-deep salary-validation-icon .validation-icon {
      vertical-align: baseline;
    }
    h2 {
      margin: 16px 0;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SubNavigationListItemComponent implements OnInit, AfterViewInit {
  link = input.required<SubNavigationLinkConfig>();
  private destroyRef = inject(DestroyRef);
  private isVisibleSubscription: Unsubscribable;
  protected isValid$: Observable<boolean>;
  protected isActive$: Observable<boolean>;

  private readonly subNavigationValidationService = inject(
    SubNavigationValidationService,
  );
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private readonly subNavigationVisibleService = inject(
    SubNavigationVisibleService,
  );

  ngOnInit(): void {
    this.isActive$ = this.activatedRoute.fragment.pipe(
      map((fragment) => this.link().name === fragment),
    );
    this.isValid$ = this.subNavigationValidationService.getValidState$(
      this.link().name,
    );
  }

  ngAfterViewInit(): void {
    this.isActive$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isActive) => {
        if (isActive) {
          this.scrollIntoView();
        } else {
          this.isVisibleSubscription?.unsubscribe();
        }
      });
  }

  private scrollIntoView() {
    if (this.elementRef?.nativeElement.offsetWidth) {
      this.scrollIntoViewCore();
    } else {
      this.isVisibleSubscription?.unsubscribe();
      this.isVisibleSubscription =
        this.subNavigationVisibleService.isSubNavigationVisible$
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.isVisibleSubscription.unsubscribe();
            this.scrollIntoViewCore();
          });
    }
  }

  private scrollIntoViewCore() {
    this.elementRef.nativeElement.scrollIntoView({
      block: 'nearest',
    });
  }

  ngOnDestroy(): void {
    this.isVisibleSubscription?.unsubscribe();
  }
}
