import { Pipe, PipeTransform } from '@angular/core';
import { replaceSpecialCharacters } from '@salary/common/dumb';

@Pipe({
    name: 'convertSpecialCharacter',
    standalone: false
})
export class SpecialCharacterConverterPipe implements PipeTransform {
  transform(value: string): string {
    return replaceSpecialCharacters(value);
  }
}
