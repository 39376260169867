import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'salary-overview',
  template: `
    <div class="info-container small-scrollbar">
      <ng-content></ng-content>
    </div>
  `,
  styles: `
    .info-container {
      display: grid;
      align-content: flex-start;
      grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
      height: 100%;
      align-items: stretch;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OverviewComponent {}
