import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { EmptyStateSize } from '@salary/common/dumb-components';

@Component({
    selector: 'salary-server-error-empty-state',
    template: `
    <salary-empty-state
      testId="server_error_empty_state"
      iconName="sentiment_dissatisfied"
      [size]="size()"
      label="Hoppla!"
      description="Es gibt Schwierigkeiten Ihre Anfrage zu beantworten. Versuchen Sie es bitte in ein paar Minuten erneut."
    />
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ServerErrorEmptyStateComponent {
  size = input<EmptyStateSize>('large');
}
