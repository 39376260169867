import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@salary/common/formly';
import { IBAN_MASK_CONFIG } from '@salary/common/utils';
import { IBANInputFormlyFieldConfig } from './iban-input-field-config';

@Component({
    selector: 'salary-iban-input',
    template: `
    <input
      matInput
      [required]="('required' | toSignal: field())()"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [salaryFormlyAttributes]="field()"
      [imask]="maskConfig"
      [unmask]="true"
      [placeholder]="('placeholder' | toSignal: field())()"
      [attr.data-testid]="field().testId"
      salaryDetectBankleitzahl
      [salaryDetectBankleitzahlSuspended]="!field().bankleitzahlDetection"
      (salaryDetectBankleitzahlChange)="
        field().bankleitzahlChange?.(field(), $event)
      "
    />
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SalaryIbanInputTypeComponent extends FieldType<IBANInputFormlyFieldConfig> {
  public static readonly defaultOptions: IBANInputFormlyFieldConfig = {
    defaultValue: null,
  };
  protected maskConfig = IBAN_MASK_CONFIG;
}
