import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCalendar } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { distinctUntilChanged, map, startWith } from 'rxjs';

@Component({
    selector: 'salary-lohnkontext-datepicker-header',
    template: '<mat-calendar-header/>',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LohnkontextDatepickerHeaderComponent {
  constructor(calendar: MatCalendar<DateTime>) {
    calendar.stateChanges
      .pipe(
        map(() => calendar.dateClass),
        startWith(calendar.dateClass),
        distinctUntilChanged(),
        takeUntilDestroyed(),
      )
      .subscribe(() =>
        setTimeout(() => {
          calendar?.yearView?._init();
          calendar?.yearView?._focusActiveCell();
        }),
      );
  }
}
