import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
    name: 'formatDateRelative',
    standalone: false
})
export class FormatDateRelativePipe implements PipeTransform {
  transform(
    date: DateTime,
    dateFormatElse?: string,
    dateFormatAfterRelative?: string,
  ): string {
    const fmtObj = {
      sameDay: `'Heute'` + (dateFormatAfterRelative ?? ''),
      yesterday: `'Gestern'` + (dateFormatAfterRelative ?? ''),
      other: dateFormatElse,
    };
    const format = this.getCalendarFormat(date);
    return date.toFormat(fmtObj[format]);
  }

  private getCalendarFormat(
    myDateTime: DateTime,
  ): 'other' | 'sameDay' | 'yesterday' {
    const diff = myDateTime.diff(DateTime.now().startOf('day'), 'days').days;
    if (diff < -1) {
      return 'other';
    }
    if (diff < 0) {
      return 'yesterday';
    }
    if (diff < 1) {
      return 'sameDay';
    }
    return 'other';
  }
}
