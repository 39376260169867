import {
  DestroyRef,
  Injectable,
  Injector,
  OnDestroy,
  effect,
  inject,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from '@salary/common/dumb';
import { ConfigService } from '@salary/common/facade';
import { filterNil } from '@salary/common/utils';
import { Observable, Subject, debounceTime, map } from 'rxjs';
import { BreadcrumbService } from '../layout/breadcrumb/breadcrumb.service';
import { StripHtmlPipe } from '../pipes';
import { SubNavigationLinkConfig } from '../sub-navigation';
import { SubNavigationLink } from '../sub-navigation/sub-navigation-link';
import { BaseComponent } from './utils/base-component';
import { EntityType } from './utils/detail-base-configuration';
import { DetailSingleBaseComponent } from './utils/detail-single-base-component';

export interface SubLinkValidChangedArgs {
  subLinkName: string;
  componentId: string;
  valid: boolean;
}

@Injectable()
export class ComponentSublinksInteractionService implements OnDestroy {
  private injector = inject(Injector);
  private _sublinks: SubNavigationLink[] = [];
  setSublinks(value: SubNavigationLinkConfig[]) {
    this._sublinks.length = 0;
    value?.forEach((link) => {
      const subNavigationLink: SubNavigationLink = {
        config: link,
      };
      effect(
        () => {
          if (link.visible) {
            link.visible();
            untracked(() => {
              this.reportAvailableBreadcrumbs();
            });
          }
        },
        { injector: this.injector },
      );
      this._sublinks.push(subNavigationLink);
    });
  }
  get sublinks(): SubNavigationLink[] {
    if (!this.configService.printing.printMode) {
      return this._sublinks;
    }
    return this._sublinks.filter((link) =>
      this.configService.printing.sublinksToPrint?.some(
        (toPrint) => toPrint === link.config.caption,
      ),
    );
  }

  private activeSublink$: Observable<SubNavigationLinkConfig>;
  componentValidState$ = new Subject<SubLinkValidChangedArgs>();
  private readonly components = new Map<
    string,
    { component: BaseComponent; subLinkName: string }
  >();
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private breadcrumbService = inject(BreadcrumbService);
  private destroyRef = inject(DestroyRef);
  private configService = inject(ConfigService);

  public registerComponent(component: BaseComponent, subLinkName: string) {
    const componentId = Guid.create();
    this.components.set(componentId, { component, subLinkName });
    component.form.valueChanges
      .pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateAllValidStates());
    this.triggerSubLinkValidChange(
      subLinkName,
      componentId,
      !component.form.invalid,
    );
    if (component.entityType === EntityType.AggregateRoot) {
      this.handleRootComponent(<DetailSingleBaseComponent>component);
    }
  }

  public deregisterComponent(component: BaseComponent) {
    let entryToDelete: [
      string,
      { component: BaseComponent; subLinkName: string },
    ] = undefined;
    for (const [key, value] of this.components.entries()) {
      if (value.component === component) {
        entryToDelete = [key, value];
        break;
      }
    }
    if (entryToDelete != null) {
      const [key, value] = entryToDelete;
      this.components.delete(key);
      this.triggerSubLinkValidChange(value.subLinkName, key, true);
    }
  }

  private handleRootComponent(component: DetailSingleBaseComponent) {
    this.setSublinks(component.componentConfiguration.subnavigationLinks);
    if (!this.activatedRoute.snapshot.fragment) {
      this.navigateDefaultSubNavigation();
    }
    if (this.sublinks?.length > 0) {
      this.activeSublink$ = this.activatedRoute.fragment.pipe(
        map(
          (fragement) =>
            this.sublinks?.find((s) => s.config.name === fragement)?.config,
        ),
      );

      this.activeSublink$
        .pipe(filterNil(), takeUntilDestroyed(this.destroyRef))
        .subscribe((sublink) =>
          this.breadcrumbService.detailViewBreadcrumb.set({
            label: StripHtmlPipe.transform(sublink.caption),
          }),
        );
      this.reportAvailableBreadcrumbs();
    }
  }

  private reportAvailableBreadcrumbs() {
    this.breadcrumbService.availableDetailViewBreadcrumbs.set(
      this.sublinks
        .filter((sl) => sl.config.visible?.() !== false)
        .map((sublink) => ({
          label: StripHtmlPipe.transform(sublink.config.caption),
          url: [],
          fragment: sublink.config.name,
        })),
    );
  }

  public navigateDefaultSubNavigation() {
    const firstVisibleLink = this.sublinks.find(
      (l) => l.config.visible?.() !== false,
    );
    if (firstVisibleLink) {
      this.navigateSublink(firstVisibleLink.config.name);
    }
  }

  private navigateSublink(sublink: string) {
    this.router.navigate([], {
      fragment: sublink,
      queryParamsHandling: 'preserve',
      replaceUrl: true,
    });
  }

  public areSublinksAvailable() {
    return this.sublinks?.length > 0;
  }

  private updateAllValidStates(): void {
    for (const [componentId, { component, subLinkName }] of this.components) {
      this.triggerSubLinkValidChange(
        subLinkName,
        componentId,
        !component.form.invalid,
      );
    }
  }

  private triggerSubLinkValidChange(
    subLinkName: string,
    componentId: string,
    valid: boolean,
  ) {
    if (subLinkName) {
      this.componentValidState$.next({
        subLinkName,
        valid: valid,
        componentId,
      });
    }
  }

  ngOnDestroy(): void {
    this.components.clear();
    this.componentValidState$.complete();
    this.breadcrumbService.detailViewBreadcrumb.set(undefined);
    this.breadcrumbService.availableDetailViewBreadcrumbs.set(undefined);
  }
}
