import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { FieldType, convertToSignal } from '@salary/common/formly';
import { getFullCaption } from '../../../utils/salary-print-utils';
import { ExpandablePanelPrintTypeConfig } from './expandable-panel-print-config';

@Component({
    selector: 'salary-expandable-panel-print',
    template: `
    @if (exists()) {
      @if (title != null) {
        <salary-header-container [bigSubTitle]="title()" />
      }
      @for (field of field().fieldGroup; track field.id) {
        <salary-form-field [field]="field" />
      }
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SalaryExpandablePanelPrintTypeComponent extends FieldType<ExpandablePanelPrintTypeConfig> {
  protected exists = computed(
    () => this.field().model?.() != null || !this.field().addRemoveAvailable,
  );

  protected title = computed(() =>
    getFullCaption(
      convertToSignal('headerTitle', this.field()),
      this.field(),
    )(),
  );
}
