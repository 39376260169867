import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  computed,
  inject,
  input,
} from '@angular/core';
import { LohnkontextFacade, LohnkontextReadonly } from '@salary/common/facade';
import { FieldConfig } from '@salary/common/formly';
import { DateTime } from 'luxon';

@Component({
    selector: 'salary-abrechnungszeitraum-buttons',
    template: `
    <div class="button-container">
      <button
        matTooltip="Vorheriger Monat"
        data-testid="lohnkontext_abrechnungszeitraum_previous"
        mat-icon-button
        (salaryDebounceClick)="switchToPreviousMonth()"
        [disabled]="disabled()"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div>
        <ng-content />
      </div>
      <ng-container matSuffix>
        <ng-container
          [ngTemplateOutlet]="toggle()"
          [ngTemplateOutletContext]="{ field: field() }"
        />
      </ng-container>
      <button
        class="next-button"
        matTooltip="Nächster Monat"
        data-testid="lohnkontext_abrechnungszeitraum_next"
        mat-icon-button
        (salaryDebounceClick)="switchToNextMonth()"
        [disabled]="disabled()"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  `,
    styles: `
    .button-container {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .next-button {
      margin-left: -5px;
    }
    :host ::ng-deep {
      .mdc-icon-button {
        display: flex !important;
        align-items: center;
        justify-content: center;
        height: 30px !important;
        width: 30px !important;
        line-height: 30px !important;
        padding: 0 !important;
        .mat-icon,
        .mat-datepicker-toggle-default-icon {
          height: 20px !important;
          width: 20px !important;
          font-size: 20px !important;
          line-height: 20px !important;
        }
        .mat-mdc-button-touch-target {
          width: 30px !important;
          height: 30px !important;
        }
      }

      input {
        display: flex;
        color: currentColor;
        font-size: 16px;
        width: 60px;
      }
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AbrechnungszeitraumButtonsComponent {
  private lohnkontextFacade = inject(LohnkontextFacade);
  protected readonly disabled = computed(
    () => this.lohnkontextFacade.select.readonly() === LohnkontextReadonly.All,
  );
  field = input.required<FieldConfig>();
  toggle = input.required<TemplateRef<unknown>>();

  protected switchToPreviousMonth() {
    const previousMonth = this.getAbrechnungszeitraum().minus({ months: 1 });
    this.lohnkontextFacade.setAbrechnungszeitraum(previousMonth);
  }

  protected switchToNextMonth() {
    const nextMonth = this.getAbrechnungszeitraum().plus({ months: 1 });
    this.lohnkontextFacade.setAbrechnungszeitraum(nextMonth);
  }

  private getAbrechnungszeitraum(): DateTime {
    return this.lohnkontextFacade.select.abrechnungszeitraum();
  }
}
