import {
  ConnectedOverlayPositionChange,
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Type,
  inject,
  input,
} from '@angular/core';
import { Observable, Subject, take, takeUntil, timer } from 'rxjs';

@Directive({
    selector: '[salaryTooltip]',
    standalone: false
})
export class TooltipDirective implements OnInit {
  private defaultConnectionPositions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: 5,
    },
    {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -5,
    },
  ];

  componentSettings = input(undefined, { alias: 'salaryTooltip' });
  componentType = input<Type<DirectiveTooltipComponent>>();
  connectionPositions = input<ConnectedPosition[]>(
    this.defaultConnectionPositions,
  );

  private overlayRef: OverlayRef;
  private breakTimer = new Subject<void>();
  private positionChanges: Observable<ConnectedOverlayPositionChange>;
  private overlay = inject(Overlay);
  private overlayPositionBuilder = inject(OverlayPositionBuilder);
  private elementRef = inject(ElementRef);

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.connectionPositions());
    this.positionChanges = positionStrategy.positionChanges;

    this.overlayRef = this.overlay.create({ positionStrategy, minHeight: 10 });
  }

  @HostListener('mouseenter')
  show() {
    timer(300)
      .pipe(take(1), takeUntil(this.breakTimer))
      .subscribe(() => {
        const tooltipRef: ComponentRef<DirectiveTooltipComponent> =
          this.overlayRef.attach(new ComponentPortal(this.componentType()));
        tooltipRef.instance.settings = this.componentSettings();
        tooltipRef.instance.connectionPositionObserver = this.positionChanges;
      });
  }

  @HostListener('mouseleave')
  hide() {
    this.breakTimer.next();
    this.overlayRef.detach();
  }
}

export interface DirectiveTooltipComponent {
  settings: unknown;
  connectionPositionObserver?: Observable<ConnectedOverlayPositionChange>;
}
