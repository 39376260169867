import {
  CellFocusedEvent,
  ColDef,
  Column,
  ColumnHeaderMouseOverEvent,
} from '@ag-grid-community/core';
import { Directive, ElementRef, HostListener, inject } from '@angular/core';
import { FACADE, StandardFacade } from '@salary/common/standard-facade';
import { HelpOverlayService } from '../help/help-overlay.service';
import { FocusFieldInfoService } from '../utils';

@Directive({
    selector: '[salaryListhelp]',
    providers: [HelpOverlayService],
    standalone: false
})
export class ListHelpDirective {
  private helpOverlayService = inject(HelpOverlayService);
  private focusFieldInfoService = inject(FocusFieldInfoService);
  private facade = inject<StandardFacade<unknown>>(FACADE, { optional: true });
  private elementRef = inject(ElementRef);

  @HostListener('columnHeaderMouseOver', ['$event'])
  columnHeaderMouseOver(event: ColumnHeaderMouseOverEvent) {
    if (!this.facade) {
      return;
    }
    const colDef: ColDef = (event?.column as Column)?.['colDef'];
    if (colDef) {
      const sortIndicator =
        colDef.type !== 'rightAligned'
          ? this.elementRef.nativeElement.querySelector(
              `div[col-id="${colDef.field}"] .ag-sort-indicator-icon:not(.ag-hidden)`,
            )
          : undefined;
      const headerElement =
        sortIndicator ??
        this.elementRef.nativeElement.querySelector(
          `div[col-id="${colDef.field}"] .ag-header-cell-text`,
        ) ??
        this.elementRef.nativeElement.querySelector(
          `div[col-id="${colDef.field}"] .ag-header-cell-comp-wrapper`,
        );
      if (headerElement?.childNodes)
        this.helpOverlayService.show(
          {
            columnFocusedInfo: {
              modelName: this.facade?.singularModelCaption,
              fieldId: colDef.cellRendererParams?.fieldId,
              propertyName: colDef.headerName,
            },
          },
          headerElement,
        );
    }
  }

  @HostListener('cellFocused', ['$event'])
  cellFocused(event: CellFocusedEvent) {
    if (!this.facade) {
      return;
    }
    const colDef: ColDef = (event?.column as Column)?.['colDef'];
    this.focusFieldInfoService.reportColumnFocused({
      modelName: this.facade?.singularModelCaption,
      fieldId: colDef.cellRendererParams?.fieldId,
      propertyName: colDef.headerName,
    });
  }
}
