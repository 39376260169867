import { AgGridAngular } from '@ag-grid-community/angular';
import { ColDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import {
  Directive,
  effect,
  inject,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Permission } from '../config/authorization-config';
import { AuthorizationService } from '../services/authorization.service';

@Directive({
    selector: '[salaryApplyListPermission]',
    standalone: false
})
export class ApplyListPermissionDirective {
  newItemRowData = input<unknown[]>(undefined, {
    alias: 'salaryApplyListPermissionNewItemRowData',
  });
  columnDefs = input<ColDef[]>(undefined, {
    alias: 'salaryApplyListPermissionColumnDefs',
  });
  columnsInitialized = output({
    alias: 'salaryApplyListPermissionColumnsInitialized',
  });
  private agGrid = inject(AgGridAngular);
  private authorizationService = inject(AuthorizationService);
  private activatedRoute = inject(ActivatedRoute);

  constructor() {
    const allowChange = this.getPermission(Permission.AllowChange);
    const allowAll = this.getPermission(Permission.AllowAll);
    const gridApi = signal<GridApi>(undefined);
    this.agGrid.gridReady
      .pipe(takeUntilDestroyed())
      .subscribe((readyArgs: GridReadyEvent) => gridApi.set(readyArgs.api));
    effect(() => {
      if (gridApi() == null || allowAll == null || allowChange == null) {
        return;
      }
      const columnDefs = this.columnDefs();
      untracked(() => {
        this.updateColumnDefinitions(
          allowChange,
          allowAll,
          columnDefs,
          gridApi(),
        );
      });
    });
    effect(() => {
      const api = gridApi();
      const data = this.newItemRowData();
      untracked(() => {
        this.updateNewItemRow(allowAll, data, api);
      });
    });
  }

  private getPermission(permission: Permission) {
    return this.authorizationService.hasPermission(
      permission,
      this.activatedRoute.snapshot,
    );
  }

  private updateColumnDefinitions(
    allowChange: boolean,
    allowAll: boolean,
    columns: ColDef[],
    gridApi: GridApi,
  ) {
    if (!allowChange) {
      columns.forEach((c) => (c.editable = false));
    }
    if (!allowAll) {
      const firstColumnClass = columns?.[0]?.cellClass;
      if (firstColumnClass?.toString()?.includes('cell-button-style')) {
        columns[0].hide = true;
        columns[0]['hiddenBySecurity'] = true;
      }
    }
    this.agGrid.columnDefs = columns;
    this.columnsInitialized.emit();
    gridApi?.setGridOption('columnDefs', this.agGrid.columnDefs);
  }

  private updateNewItemRow(
    allowAll: boolean,
    newItemRowData: unknown[],
    gridApi: GridApi,
  ) {
    this.agGrid.pinnedTopRowData = allowAll ? newItemRowData : undefined;
    gridApi?.setGridOption('pinnedTopRowData', this.agGrid.pinnedTopRowData);
  }
}
