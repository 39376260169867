import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldConfig, FieldType } from '@salary/common/formly';

/** @ignore */
@Component({
    selector: 'salary-form-group',
    template: `
    @for (f of field?.().fieldGroup; track f.id) {
      <salary-form-field [field]="f" />
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SalaryFormlyGroupComponent extends FieldType<FieldConfig> {}

export function isGroupField(field: FieldConfig) {
  return field?.type === 'formly-group';
}
