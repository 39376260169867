import {
  ChangeDetectorRef,
  Directive,
  TemplateRef,
  ViewContainerRef,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';
import { ToolbarDefinition } from '../../utils';
import { ToolbarComponent } from '../toolbar.component';
import { ToolbarItemVisibleService } from './toolbar-item-visible.service';

@Directive({
  selector: '[salaryToolbarItemVisible]',
  providers: [ToolbarItemVisibleService],
  standalone: false,
})
export class ToolbarItemVisibleDirective {
  toolbarDefinition = input.required<ToolbarDefinition>({
    alias: 'salaryToolbarItemVisible',
  });
  private toolbarItemVisibleService = inject(ToolbarItemVisibleService);
  private toolbar = inject(ToolbarComponent);
  private visible = this.toolbarItemVisibleService.createItemVisibleSignal(
    this.toolbar,
    this.toolbarDefinition,
  );

  private hasView = false;
  private templateRef = inject(TemplateRef);
  private viewContainer = inject(ViewContainerRef);
  private changeDetector = inject(ChangeDetectorRef);

  constructor() {
    effect(
      () => {
        const visible = this.visible();
        untracked(() => this.showHideHost(visible));
      },
      { forceRoot: true },
    );
  }

  private showHideHost(visible: boolean) {
    if (visible && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      setTimeout(() => this.changeDetector.markForCheck());
    } else if (!visible && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
      setTimeout(() => this.changeDetector.markForCheck());
    }
  }
}
