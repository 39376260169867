import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  signal,
  untracked,
} from '@angular/core';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { FieldType } from '@salary/common/formly';
import { IdentificationPanelFieldConfig } from './identification-panel-field-config';

@Component({
    selector: 'salary-identification-panel',
    template: `
    <mat-expansion-panel
      [@.disabled]="disableAnimation()"
      [expanded]="isExpanded()"
      (opened)="isExpanded.set(true)"
      (closed)="isExpanded.set(false)"
      data-testid="identification_panel"
      hideToggle
      [id]="id"
    >
      <mat-expansion-panel-header
        data-testid="identification_header"
        [@roundBorder]="isExpanded() ? 'expanded' : 'collapsed'"
      >
        <mat-panel-title #titleElement>
          <div style="display:flex; width: 100%">
            <salary-invalid-control-icon
              [field]="field()"
              [connectedTo]="titleElement"
            />
            <mat-icon
              class="expand-icon"
              style="display:flex;"
              [@indicatorRotate]="isExpanded() ? 'expanded' : 'collapsed'"
              data-testid="identification-expand-icon"
              >double_arrow
            </mat-icon>
            <div style="flex:1"></div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="margin-top:25px"></div>
      @for (childField of field().fieldGroup; track childField.id) {
        <salary-form-field [field]="childField" />
      }
    </mat-expansion-panel>
  `,
    styles: `
    mat-expansion-panel-header .mat-expansion-panel-header-title {
      margin: 0;
    }
    mat-expansion-panel {
      margin-right: 8px !important;
      box-shadow: unset !important;
    }
    salary-invalid-control-icon {
      display: flex;
      align-items: center;
      flex: 1;
    }
    :host ::ng-deep .mat-expansion-panel-content {
      border: 1px solid;
      border-top: 0;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  `,
    animations: [
        trigger('roundBorder', [
            state('collapsed', style({ 'border-radius': 'inherit' })),
            state('expanded', style({ 'border-radius': 0 })),
            transition('expanded <=> collapsed', [
                group([
                    query('@indicatorRotate', animateChild()),
                    animate('225ms cubic-bezier(0.4,0.0,0.2,1)'),
                ]),
            ]),
        ]),
        trigger('indicatorRotate', [
            state('collapsed', style({ transform: 'rotate(90deg)' })),
            state('expanded', style({ transform: 'rotate(270deg)' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
        ]),
    ],
    providers: [
        {
            provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
            useValue: { expandedHeight: '35px', collapsedHeight: '35px' },
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SalaryIdentificationPanelTypeComponent extends FieldType<IdentificationPanelFieldConfig> {
  protected disableAnimation = signal(true);
  protected isExpanded = signal(false);

  constructor() {
    super();
    effect(() => {
      const isHinzufuegenRoute = this.field().hinzufuegenRoute?.();
      if (isHinzufuegenRoute) {
        untracked(() => this.isExpanded.set(true));
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.disableAnimation.set(false));
  }
}
