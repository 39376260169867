import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Type,
  inject,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldConfig, FormlyOptions } from '@salary/common/formly';

@Component({
    selector: 'salary-formly-dialog',
    template: ` @if (dialogData.caption) {
      <h2 mat-dialog-title>
        {{ dialogData.caption }}
      </h2>
    }
    <mat-dialog-content>
      <form style="height: 100%" [formGroup]="form">
        <salary-form
          [model]="dialogData.model"
          [fields]="getFields"
          [options]="options"
          [form]="form"
        />
      </form>
    </mat-dialog-content>
    @if (
      !(dialogData.cancelButton === false) ||
      !(dialogData.saveButton === false) ||
      dialogData.removeButton
    ) {
      <mat-dialog-actions>
        @if (dialogData.removeButton) {
          <button
            mat-button
            [mat-dialog-close]="'delete'"
            data-testid="formly_dialog_remove_button"
          >
            Entfernen
          </button>
        }
        @if (!(dialogData.cancelButton === false)) {
          <button
            mat-button
            style="margin-left: auto"
            [mat-dialog-close]="null"
            data-testid="formly_dialog_cancel_button"
          >
            Abbrechen
          </button>
        }
        @if (!(dialogData.saveButton === false)) {
          <button
            mat-button
            type="submit"
            form="form"
            [disabled]="!form.valid"
            [mat-dialog-close]="dialogData.model ?? true"
            data-testid="formly_dialog_save_button"
          >
            {{ dialogData.saveButtonCaption ?? 'Speichern' }}
          </button>
        }
      </mat-dialog-actions>
    }`,
    styles: `
    form {
      padding-top: 5px;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormlyDialogComponent<T> {
  protected dialogRef = inject(MatDialogRef);
  protected dialogData = inject<FormlyDialogData<T>>(MAT_DIALOG_DATA);
  protected form = new UntypedFormGroup({});
  protected options: FormlyOptions = {
    formState: { modelClass: this.dialogData.modelClass },
  };
  @HostListener('keydown.enter')
  saveOnEnter() {
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close(this.dialogData.model);
  }

  protected getFields = () => {
    return (this.dialogData?.fields ?? []) as FieldConfig[];
  };
}

export interface FormlyDialogData<T> {
  fields: FieldConfig[];
  model?: T;
  modelClass?: Type<T>;
  caption?: string;
  saveButton?: boolean;
  cancelButton?: boolean;
  removeButton?: boolean;
  saveButtonCaption?: string;
}
