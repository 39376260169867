import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import {
  Column,
  GridApi,
  GroupCellRendererParams,
} from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  signal,
  untracked,
} from '@angular/core';
import { SalaryLink, getRendererCellValue } from '../utils';
import { RenderValueParams } from '../utils/get-renderer-cell-value';

@Component({
    selector: 'salary-group-row-cell',
    template: ` <span>{{ groupText() }}</span>
    <span> ({{ aggregateText() }})</span>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: ':host {position:relative}',
    standalone: false
})
export class GroupRowInnerRendererComponent
  implements ICellRendererAngularComp
{
  private params: GroupCellRendererParams;
  private paramsSignal = signal(undefined);
  private update = signal(undefined, { equal: () => false });
  protected groupText = computed(() => {
    const params = this.paramsSignal();
    this.update();
    return untracked(() => getGroupText(params));
  });
  protected aggregateText = computed(() => {
    const params = this.paramsSignal();
    this.update();
    return untracked(() => getAggregateText(params, params.api));
  });
  agInit(params: GroupCellRendererParams): void {
    this.params = params;
    this.paramsSignal.set(params);
    this.params.api.addEventListener('rowDataUpdated', () =>
      this.update.set(undefined),
    );
  }

  refresh(): boolean {
    return false;
  }
}

export function getGroupText(params: RenderValueParams) {
  let formattedGroupValue = getFormattedValue(
    params.node.allLeafChildren[0]?.data,
    params.node.rowGroupColumn,
    params,
  );
  formattedGroupValue = SalaryLink.getWithoutLink(formattedGroupValue);
  const groupColumnTitle =
    params.node.rowGroupColumn.getDefinition().headerName;
  return groupColumnTitle + ': ' + formattedGroupValue;
}

function getFormattedValue(
  data: unknown,
  column: Column,
  params: RenderValueParams,
) {
  return getRendererCellValue(
    {
      ...params,
      node: { ...params.node, data: data },
      colDef: column.getColDef(),
    },
    1,
  );
}

export function getAggregateText(params: RenderValueParams, gridApi: GridApi) {
  const countAggregateText = 'Anzahl: ' + params.node.allChildrenCount;
  return [
    countAggregateText,
    ...Object.keys(params.node.aggData ?? {}).map((key) => {
      const aggColumn = gridApi.getColumn(key);
      return (
        aggColumn.getDefinition().headerName +
        ': ' +
        getFormattedValue(
          {
            ...(params.node.allLeafChildren[0].data as object),
            [key]: params.node.aggData[key],
            params,
          },
          aggColumn,
          params,
        )
      );
    }),
  ].join(', ');
}
