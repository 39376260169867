import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DokumenteFacade } from '@salary/common/facade';
import { debounceSignal } from '@salary/common/utils';
import { Observable, of, switchMap } from 'rxjs';
import { getRendererCellValue } from '../utils';
import { BaseCellRenderer } from './utils';

export interface FileDownloadCellRendererOptions {
  iconName?: (node: IRowNode) => string;
  retrieveDokumentIdHandler?: (node: IRowNode) => Observable<string>;
}

@Component({
  selector: 'salary-file-download-cell-renderer',
  template: `
    @if (!isNewItemRow() && (displayText() || iconName())) {
      <div
        class="link-container"
        [class.centered-content]="centeredContent()"
        [attr.data-testid]="testId() | convertSpecialCharacter"
      >
        @if (displayText()) {
          <a
            mat-button
            disableRipple
            [salaryLoadingButton]="showLoadingIndicator()"
            class="link linkColorAccent"
            (salaryDebounceClick)="onLinkClick()"
          >
            <span salaryEllipsis>
              {{ displayText() }}
            </span>
          </a>
          <mat-icon (salaryDebounceClick)="onLinkClick()" class="link-icon"
            >open_in_new</mat-icon
          >
        } @else if (iconName()) {
          <button
            mat-icon-button
            [salaryLoadingButton]="showLoadingIndicator()"
            (salaryDebounceClick)="onLinkClick()"
          >
            <mat-icon class="button-link-icon">{{ iconName() }}</mat-icon>
          </button>
        }
      </div>
    }
  `,
  styles: `
    .link-container {
      height: 100%;
      display: flex;
      align-items: center;
      &:hover .link-icon {
        display: inherit;
      }
      &.centered-content {
        justify-content: center;
      }
    }
    .link-container .link {
      font-weight: unset;
      letter-spacing: unset;
      padding: 0;
      justify-content: start;
      min-width: 0;
    }
    :host ::ng-deep .link {
      &:hover .mat-mdc-button-persistent-ripple::before {
        opacity: 0;
      }
      .mdc-button__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .button-link-icon {
      --mat-text-button-icon-offset: 0;
      --mat-text-button-icon-spacing: 0;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FileDownloadCellRendererComponent
  implements BaseCellRenderer, AgRendererComponent
{
  isValid = true;
  errorMessage = undefined;
  params: ICellRendererParams;
  private readonly dokumenteFacade = inject(DokumenteFacade);
  private readonly destroyRef = inject(DestroyRef);
  protected readonly isNewItemRow = signal(false);
  private readonly isDownloading = signal(false);
  protected showLoadingIndicator = debounceSignal(
    this.isDownloading,
    (isDownloading) => (isDownloading ? 250 : 0),
  );
  protected readonly iconName = signal<string>(undefined);
  protected readonly centeredContent = computed(() => !!this.iconName());
  protected readonly testId = signal<string>(undefined);
  protected readonly displayText = signal<string>(undefined);

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isNewItemRow.set(this.params.node.rowPinned === 'top');
    this.iconName.set(
      this.params.colDef.cellRendererParams?.fileDownloadCellRendererOptions?.iconName?.(
        this.params.node,
      ),
    );
    this.testId.set(
      `renderer_${this.params.colDef.field}_${this.params.node.rowIndex}`,
    );
    this.displayText.set(getRendererCellValue(this.params, 1));
  }

  refresh(params: ICellRendererParams): boolean {
    this.agInit(params);
    return true;
  }

  onLinkClick() {
    if (this.isDownloading()) {
      return;
    }
    this.isDownloading.set(true);
    const getDokumentId: FileDownloadCellRendererOptions['retrieveDokumentIdHandler'] =
      this.params.colDef.cellRendererParams?.fileDownloadCellRendererOptions
        ?.retrieveDokumentIdHandler;
    const dokumentId$ = getDokumentId
      ? getDokumentId(this.params.node)
      : of(this.params.node.data.id as string);
    dokumentId$
      .pipe(
        switchMap((dokumentId) =>
          dokumentId
            ? this.dokumenteFacade.getBlobById(dokumentId)
            : of(undefined),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((blob) => {
        this.isDownloading.set(false);
        this.dokumenteFacade.openDocument(blob);
      });
  }
}
