import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  TemplateRef,
  afterRenderEffect,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';
import { DateTimeFormats } from '@salary/common/dumb';
import {
  LohnkontextAbrechnungenFacade,
  LohnkontextFacade,
  LohnkontextReadonly,
} from '@salary/common/facade';
import { FieldConfig } from '@salary/common/formly';
import { DateTime } from 'luxon';
import { map, merge, of, switchMap, timer } from 'rxjs';
import { DatepickerFieldConfig } from '../../salary-formly';
import { LohnkontextDatepickerHeaderComponent } from './lohnkontext-datepicker-header.component';

@Component({
  selector: 'salary-lohnkontext-container',
  template: `
    <salary-lohnkontext-border [disabled]="disabled()">
      <salary-abrechnungszeitraum-buttons
        [field]="firstField()"
        [toggle]="toggle()"
        matBadge="!"
        [matBadgeHidden]="!message()"
        [matBadgeOverlap]="true"
        [matTooltip]="message()"
      >
        <form [formGroup]="form" [class.date-changed]="dateChangedByLogic()">
          <salary-form [fields]="fields" [form]="form" />
        </form>
      </salary-abrechnungszeitraum-buttons>
    </salary-lohnkontext-border>
  `,
  styles: `
    ::ng-deep .mat-datepicker-popup:has(.lohnkontext-datepicker-overlay) {
      margin-left: -39px;
      margin-top: 10px;
    }

    ::ng-deep .lohnkontext-datepicker-overlay {
      .mat-calendar-header {
        padding-top: 0;
      }
      .mat-calendar-controls {
        margin-top: 0;
        margin-bottom: 0;
      }
      .month-closed {
        .mat-calendar-body-cell-content {
          display: flex;
          flex-direction: row-reverse;
          gap: 2px;
        }
        .mat-calendar-body-cell-content::after {
          font-family: 'Material Symbols Outlined';
          content: 'lock';
          width: 13px;
        }
      }
      .mat-calendar-arrow {
        display: none;
      }
      .mat-calendar-body-label {
        display: none;
      }
    }

    :host ::ng-deep .date-changed input {
      animation: zoom-in-out 2s;
      position: relative;
      z-index: 1;
    }
    @keyframes zoom-in-out {
      0% {
        transform: scale(1);
      }
      30%,
      70% {
        transform: scaleY(2) scaleX(2.2) translateX(6px);
      }
      100% {
        transform: scale(1);
      }
    }
    :host ::ng-deep salary-abrechnungszeitraum-buttons .mat-badge-content {
      --mat-badge-background-color: var(--salary-warning-color);
      margin-bottom: -18px !important;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LohnkontextAbrechnungszeitraumComponent {
  private lohnkontextFacade = inject(LohnkontextFacade);
  private lohnkontextAbrechnungenFacade = inject(LohnkontextAbrechnungenFacade);
  protected form = new UntypedFormGroup({});
  protected readonly toggle = signal<TemplateRef<unknown>>(undefined);
  private loadedYearInDatepicker: number = undefined;
  protected readonly dateChangedByLogic = toSignal(
    this.lohnkontextFacade.dateChangedByLogic$.pipe(
      switchMap(() => merge(of(true), timer(2000).pipe(map(() => false)))),
    ),
    { initialValue: false },
  );
  protected readonly disabled = computed(
    () => this.lohnkontextFacade.select.readonly() === LohnkontextReadonly.All,
  );
  protected readonly message = this.lohnkontextFacade.select.zeitraumMessage;
  private injector = inject(Injector);
  protected firstField = signal<FieldConfig>(undefined);
  protected fields: () => DatepickerFieldConfig[] = () => {
    const result = [
      {
        key: 'abrechnungszeitraum',
        type: 'lohnkontextDatepicker',
        dateFormat: DateTimeFormats.MONTH_YEAR,
        required: true,
        testId: 'form_lohnkontext_abrechnungszeitraum',
        hooks: {
          onInit: (field) =>
            effect(() => this.toggle.set(field.suffix()), {
              injector: this.injector,
            }),
        },
        dateChange: (field) =>
          this.updateLohnkontext(field.model()?.abrechnungszeitraum),
        calendarHeaderComponent: LohnkontextDatepickerHeaderComponent,
        datepickerOverlayClass: 'lohnkontext-datepicker-overlay',
        dateClass: computed(() => {
          const closedByMonth = this.lohnkontextAbrechnungenFacade.select
            .entities()
            .map((abrechnung) => ({
              isClosed: this.lohnkontextAbrechnungenFacade.getIsClosedByMonth(
                abrechnung.abrechnungsmonat,
              ),
              month: abrechnung.abrechnungsmonat,
            }));
          return untracked(() => {
            const lohnkontext =
              this.lohnkontextFacade.select.selectedLohnkontext();
            return (date: DateTime, view: 'month' | 'year' | 'multi-year') => {
              if (view != 'year' || lohnkontext.abrechnungskreis == null) {
                return undefined;
              }
              const selectedYear = date.get('year');
              if (
                selectedYear != lohnkontext.abrechnungszeitraum.get('year') &&
                selectedYear != this.loadedYearInDatepicker
              ) {
                this.loadedYearInDatepicker = selectedYear;
                this.lohnkontextAbrechnungenFacade.queryAbrechnungen({
                  abrechnungskreisId: lohnkontext.abrechnungskreis.id,
                  abrechnungszeitraum: date,
                });
                return undefined;
              }
              const foundEntry = closedByMonth.find((c) =>
                c.month.hasSame(date, 'month'),
              );
              return foundEntry?.isClosed ? 'month-closed' : undefined;
            };
          });
        }),
        alwaysShowToggle: true,
        disabled: this.disabled,
      },
    ];
    this.firstField.set(result[0]);
    return result;
  };

  constructor() {
    afterRenderEffect({
      read: () => {
        const date = this.lohnkontextFacade.select.abrechnungszeitraum();
        untracked(() => {
          this.form.controls['abrechnungszeitraum']?.setValue(date);
        });
      },
    });
  }

  private updateLohnkontext(date: DateTime) {
    this.lohnkontextFacade.setAbrechnungszeitraum(date);
  }
}
