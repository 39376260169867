import { Injectable, InjectionToken, inject, signal } from '@angular/core';
import { NameFunction } from '@salary/common/dumb';
import { StandardFacade } from '@salary/common/standard-facade';
import { PRELOADING_STRATEGY } from '@salary/common/utils';
import { ColumnDefinition } from '../list/column';

@Injectable({ providedIn: 'root' })
export class ListRegistrationService {
  private listConfigs: ListConfig[] = [];
  private importConfigsAdded = 0;
  private preloadingStrategy = inject(PRELOADING_STRATEGY);

  private modulesToPreload = [
    this.preloadingStrategy.preloadMasterdata,
    this.preloadingStrategy.preloadTransaction,
    this.preloadingStrategy.preloadCalculation,
  ];
  public registrationCompleted = signal(false);

  public preloadModules() {
    this.modulesToPreload.forEach((func) => {
      func.call(this.preloadingStrategy);
    });
  }

  public addListConfigs(
    listConfigs: ListConfig[],
    facadeConfig: Record<string, InjectionToken<unknown>> = undefined,
  ) {
    listConfigs.forEach((lc) => this.addListConfigCore(lc, facadeConfig));
    this.importConfigsAdded++;
    if (this.importConfigsAdded === this.modulesToPreload.length) {
      this.registrationCompleted.set(true);
    }
  }

  private addListConfigCore(
    listConfig: ListConfig,
    facadeConfig: Record<string, InjectionToken<StandardFacade<unknown>>>,
  ) {
    this.listConfigs = this.listConfigs.concat(listConfig);
    if (listConfig.nestedPrefix && facadeConfig) {
      for (const [propertyName, token] of Object.entries(facadeConfig)) {
        if (propertyName.startsWith(listConfig.nestedPrefix)) {
          const facade = inject(token);
          if (listConfig.nestedListConfigs?.find((lc) => lc.facade == facade)) {
            continue;
          }
          listConfig.nestedListConfigs = (
            listConfig.nestedListConfigs ?? []
          ).concat({
            facade: facade,
          });
        }
      }
    }
  }

  public getListConfigs() {
    return this.listConfigs;
  }

  public getMatchingListConfig(datenquelle: string): ListConfig {
    const listConfigs = this.getListConfigs().reduce(
      (acc: ListConfig[], listConfig) => {
        acc.push(listConfig);
        if (listConfig?.nestedListConfigs) {
          acc.push(...listConfig.nestedListConfigs);
        }
        return acc;
      },
      [],
    );
    return listConfigs.find(
      (l) =>
        l.facade.getIdentifier() ===
        ListRegistrationService.getFacadeIdentifierFromDatenquelle(datenquelle),
    );
  }

  static getFacadeIdentifierFromDatenquelle(datenQuelle: string): string {
    return datenQuelle?.split(',')?.[0];
  }
}

export interface ListConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  facade: StandardFacade<any>;
  expansionMapping?: Map<string, string>;
  /**If not defined, default columndefinitions will
    be generated with help of fieldId decorators */
  columnDefinitions?: (ColumnDefinition<unknown> | NameFunction<unknown>)[];
  /**use a prefix to auto-create a nestedListConfig for all facade with this prefix as slice-name*/
  nestedPrefix?: string;
  /**nested listConfigs that are used as "detaildatenquelle" */
  nestedListConfigs?: ListConfig[];
}
