import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { SalaryLink } from '../utils';

@Component({
    selector: 'salary-search-input-empty-state',
    template: `
    <salary-empty-state
      iconName="biotech"
      size="small"
      testId="emptysearchinputstateicon"
      label="Hoppla!"
      [contentShift]="-12"
      [description]="description()"
      [class.with-extended-description]="extendedDescription()"
    >
      @if (extendedDescription()) {
        @if (isExtendedDescriptionLink()) {
          <a
            [routerLink]="extendedDescriptionLink()"
            [fragment]="extendedDescriptionLinkFragment()"
            class="linkColorAccent"
            data-testid="emptystateextendeddescriptionlink"
            >{{ extendedDescriptionLinkText() }}</a
          >
        } @else {
          {{ extendedDescription() }}
        }
      }
      @if (hinzufuegenLink() != null) {
        <button
          mat-raised-button
          color="primary"
          (click)="onHinzufuegenButtonClicked()"
          data-testid="emptystatehinzufuegenbutton"
        >
          Hinzufügen
        </button>
      }
    </salary-empty-state>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchInputEmptyStateComponent {
  extendedDescription = input<string>();
  hinzufuegenLink = input<{
    actionHandler?: () => void;
  }>();

  protected onHinzufuegenButtonClicked() {
    this.hinzufuegenLink().actionHandler();
  }

  protected isExtendedDescriptionLink = computed(() =>
    SalaryLink.includesSalaryLink(this.extendedDescription()),
  );

  protected extendedDescriptionLink = computed(() =>
    SalaryLink.getLink(this.extendedDescription()),
  );

  protected extendedDescriptionLinkText = computed(() =>
    SalaryLink.getLinkText(this.extendedDescription()),
  );

  protected extendedDescriptionLinkFragment = computed(() =>
    SalaryLink.getLinkFragment(this.extendedDescription()),
  );

  protected description = computed(() => {
    let result = 'Wir konnten nichts Passendes finden.';
    if (this.hinzufuegenLink())
      result += `<br> Hier können Sie einen Datensatz hinzufügen.`;
    return result;
  });
}
