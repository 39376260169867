import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
  TemplateRef,
} from '@angular/core';
import { getFadeInAnimation } from '@salary/common/dumb-components';

@Component({
  selector: 'salary-chip-button',
  template: `<mat-chip
      [matMenuTriggerFor]="menu"
      (menuOpened)="isMenuOpen.set(true)"
      class="selection-chip"
      [attr.data-testid]="
        'filter_button_chip_' + title() | convertSpecialCharacter
      "
    >
      {{ title() }}
      @let selectedCount = badgeText();
      <mat-icon
        matChipTrailingIcon
        [matBadge]="selectedCount"
        [salaryAnimatedBadge]="selectedCount"
      >
        @if (selectedCount != null) {
          <div @fadeInAnimation>filter_alt</div>
        } @else {
          <div @fadeInAnimation>filter_alt_off</div>
        }
      </mat-icon>
    </mat-chip>
    <mat-menu #menu="matMenu" [classList]="panelClass() + ' chip-button-panel'">
      <ng-template matMenuContent>
        @if (isMenuOpen()) {
          <ng-container [ngTemplateOutlet]="menuContent()" />
        }
      </ng-template>
    </mat-menu>`,
  styles: `
    ::ng-deep .chip-button-panel {
      margin-top: 5px;
      border-radius: 24px !important;
      max-width: unset !important;
    }

    ::ng-deep .chip-button-panel-width {
      width: 500px !important;
    }
    .selection-chip {
      height: 40px;
    }
    :host ::ng-deep .mat-badge-content {
      z-index: 1;
    }
  `,
  animations: [getFadeInAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ChipButtonComponent {
  menuContent = input<TemplateRef<unknown>>();
  panelClass = input('chip-button-panel-width', {
    transform: (value: string) => value ?? 'chip-button-panel-width',
  });
  title = input<string>('Filtern');
  badgeText = input<number>();
  protected isMenuOpen = signal(false);
}
