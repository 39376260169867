import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'salary-list-papierkorb-empty-state',
    template: `
    <salary-empty-state
      iconName="delete"
      [contentShift]="-12"
      testId="empty_papierkorb_state"
      label="Hoppla!"
      description="Der Papierkorb ist zur Zeit leer."
    >
    </salary-empty-state>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListPapierkorbEmptyStateComponent {}
