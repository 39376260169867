import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AuthenticationService } from '../../services';
@Component({
    selector: 'salary-no-tasks-page',
    templateUrl: './no-tasks-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NoTasksPageComponent {
  private readonly authenticationService = inject(AuthenticationService);
  protected relogin() {
    this.authenticationService.logout();
  }
}
