import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import {
  BehaviorSubject,
  Observable,
  catchError,
  debounceTime,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { ComponentInteractionService } from '../detail-container';
import { FocusFieldService } from '../detail-container/utils/focus-field.service';
import {
  DetailSearchResult,
  DetailSearchService,
} from './detail-search.service';

@Component({
    selector: 'salary-detail-search',
    templateUrl: './detail-search.component.html',
    styleUrl: './detail-search.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DetailSearchComponent implements AfterViewInit {
  private autoCompleteTrigger = viewChild(MatAutocompleteTrigger);
  private destroyRef = inject(DestroyRef);
  protected userInput$ = new BehaviorSubject<string>(undefined);
  private detailSearchService = inject(DetailSearchService);
  protected searchResults$: Observable<DetailSearchResult[]> =
    this.userInput$.pipe(
      tap(() => {
        this.isLoading.set(true);
      }),
      debounceTime(200),
      switchMap((searchTerm) => {
        if (!searchTerm) {
          this.isLoading.set(false);
          return of([]);
        }
        return this.detailSearchService.searchFields(searchTerm).pipe(
          catchError(() => {
            this.isLoading.set(false);
            return of([]);
          }),
        );
      }),
      tap(() => {
        this.isLoading.set(false);
      }),
    );

  protected isLoading = signal<boolean>(false);
  protected getFieldIcon(fieldType: string): string {
    switch (fieldType) {
      case 'tabPage':
        return 'tab';
      case 'datepicker':
        return 'today';
      case 'dateRangepicker':
        return 'date_range';
      case 'search':
        return 'search';
      case 'checkbox':
        return 'check_box';
      case 'expandablePanel':
        return 'bottom_panel_open';
      default:
        return 'edit_square';
    }
  }

  private matInput = viewChild(MatInput);
  private matInputElement = viewChild(MatInput, { read: ElementRef });
  private focusMonitor = inject(FocusMonitor);
  private focusFieldService = inject(FocusFieldService);
  private componentInteractionService = inject(ComponentInteractionService);

  ngAfterViewInit() {
    (this.matInputElement().nativeElement as HTMLElement).addEventListener(
      'keydown',
      this.onKeyDown,
      { capture: true },
    );
    const activeRootComponents =
      this.componentInteractionService.registeredRootRequestManagers.filter(
        (root) => !root.markedForDestroy,
      );
    activeRootComponents[0].rootComponent.model$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.clearInput();
      });
  }

  private onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.executeSeletedOption(this.autoCompleteTrigger().activeOption?.value);
    }
  };

  protected onClearButtonClick() {
    this.clearInput();
    this.focusMonitor.focusVia(this.matInputElement().nativeElement, 'program');
  }

  private clearInput() {
    this.matInput().value = '';
    this.userInput$.next('');
  }

  protected optionClicked(event, clickedSearchResult: DetailSearchResult) {
    event.preventDefault();
    event.stopPropagation();
    this.executeSeletedOption(clickedSearchResult);
  }

  private executeSeletedOption(clickedSearchResult: DetailSearchResult) {
    if (!clickedSearchResult) {
      return;
    }
    this.autoCompleteTrigger().closePanel();
    const focusFieldObservable: Observable<unknown> =
      clickedSearchResult.fieldConfig
        ? this.focusFieldService.focusByFieldConfig(
            clickedSearchResult.fieldConfig,
          )
        : this.focusFieldService.focus(clickedSearchResult.fieldPath);

    focusFieldObservable
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        clickedSearchResult?.fieldConfig?._highlightField?.set(true);
      });
  }

  ngOnDestroy() {
    (this.matInputElement().nativeElement as HTMLElement).removeEventListener(
      'keydown',
      this.onKeyDown,
      { capture: true },
    );
  }
}
