@if (showApplication()) {
  <salary-layout
    [class.fade-in]="showFadeInAnimation"
    (leftPanelOverlayModeChanged)="disableExpandCollapse.set($event)"
  >
    <salary-layout-left [isExpanded]="isLeftSideNavExpanded()">
      <salary-navigation-menu
        [disableExpandCollapse]="disableExpandCollapse()"
        (expandedChanged)="isLeftSideNavExpanded.set($event)"
      >
        <salary-current-date-label />
      </salary-navigation-menu>
    </salary-layout-left>
    <salary-layout-content>
      <div
        style="height: 100%; position: relative"
        [@outletTrigger]="outletTrigger()"
        (@outletTrigger.start)="startOutletAnimation()"
        (@outletTrigger.done)="endOutletAnimation()"
      >
        <router-outlet (activate)="onActivate()" #outlet="outlet" />
      </div>
    </salary-layout-content>
    <salary-layout-right>
      <router-outlet name="sidebar" />
    </salary-layout-right>
  </salary-layout>
} @else {
  <salary-loading-page
    [closeComponent]="initialized()"
    (closeAnimationDone)="loadingAnimationDone.set(true)"
  />
}
