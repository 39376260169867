import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonAuthorizationModule } from '@salary/common/authorization';
import { CommonDialogModule } from '@salary/common/dialog';
import { CommonDumbComponentsModule } from '@salary/common/dumb-components';
import {
  APP_LONG_RUNNING_PROCESS_SERVICE_TOKEN,
  PROCESS_MANAGER_SERVICE_TOKEN,
} from '@salary/common/utils';
import { IMaskModule } from 'angular-imask';
import { BannerComponent, HtmlTextBannerComponent } from './banner';
import { ButtonSpeedDialComponent } from './button-speed-dial';

import {
  CommonFormlyModule,
  FORMLY_CONFIG,
  FORMLY_FORM_PROVIDERS,
  FormlyConfig,
  SalaryValidationMessageOption,
  ValidationMessages,
} from '@salary/common/formly';
import { CarouselItemDirective } from './carousel/carousel-item.directive';
import { CarouselComponent } from './carousel/carousel.component';
import { ChipButtonComponent } from './chip-button/chip-button.component';
import { ChipsFilterComponent } from './chips/chips-filter.component';
import { CurrentDateLabelComponent } from './current-date-label/current-date-label.component';
import {
  AppDownloadComponent,
  AppSettingsDialogComponent,
  AppSettingsResetConfirmationDialogComponent,
  CurrentUserDialogComponent,
  CurrentUserToolbarItemComponent,
} from './current-user';
import { ItemNotFoundEmptyStateComponent } from './detail-container';
import { DetailOutputDialogComponent } from './detail-container/detail-output/detail-output-dialog.component';
import { DetailOutputComponent } from './detail-container/detail-output/detail-output.component';
import { DetailSearchComponent } from './detail-search/detail-search.component';
import {
  ActiveTabPageNavigationDirective,
  ComponentDirective,
  ComponentDirectiveWithParameter,
  DebounceClickDirective,
  DetectBankleitzahlDirective,
  ElevationDirective,
  EllipsisDirective,
  FocusDirective,
  FocusMatOptionDirective,
  GapDirective,
  ListHelpDirective,
  LoadingButtonDirective,
  PagebreakDirective,
  RowEditingDirective,
  SearchAnimationDirective,
  TooltipDirective,
} from './directives';
import {
  FilterButtonChipsPopupComponent,
  FilterButtonComponent,
} from './filter-button';
import { FilterButtonDateComponent } from './filter-button-date/filter-button-date.component';
import { DetailHeaderComponent } from './header/detail-header.component';
import { HeaderContainerComponent } from './header/header-container.component';
import { HelpButtonComponent } from './help';
import { HotkeysDialogComponent } from './hotkeys-dialog/hotkeys-dialog.component';
import { HtmlEditDialogComponent, HTMLEditorComponent } from './html-editor';
import {
  ApplicationToolbarItemComponent,
  BreadcrumbComponent,
  BreadcrumbPanelComponent,
  BrzIconComponent,
  GlobalSearchComponent,
  LayoutComponent,
  NotificationToolbarItemComponent,
  SalaryLayoutContentDirective,
  SalaryLayoutLeftDirective,
  SalaryLayoutRightDirective,
  TipsToolbarItemComponent,
  TopToolbarComponent,
} from './layout';
import {
  CheckboxEditorComponent,
  DateInputEditorComponent,
  EnumInputEditorComponent,
  FileDownloadCellRendererComponent,
  FormlyGridEditorWrapperComponent,
  InputEditorComponent,
  ListCellRendererTreeExpanderComponent,
  SearchInputEditorComponent,
} from './list';
import {
  DetailListEmptyStateComponent,
  ListEmptyStateToolbarDefinitionPipe,
  ListSearchEmptyStateWrapperComponent,
  ListSortWithFallbackDirective,
} from './list-container';
import { ListEmptyStateComponent } from './list-container/list-empty-state.component';
import { ListImportDialogComponent } from './list-container/list-import-dialog';
import { ListPapierkorbEmptyStateComponent } from './list-container/list-papierkorb-empty-state.component';
import { ListSearchEmptyStateComponent } from './list-container/list-search-empty-state.component';
import { ListDsmPerspectivesContainerComponent } from './list-dsm-perspectives-container/list-dsm-perspectives-container.component';
import { PerspectivesToolbarComponent } from './list-perspectives/perspectives-toolbar.component';
import { CheckboxRendererComponent } from './list/checkbox.renderer.component';
import { ColumnChooserDialogComponent } from './list/column-chooser-dialog';
import { CustomTooltipComponent } from './list/custom-tooltip.component';
import { GroupRowInnerRendererComponent } from './list/group-row-inner-renderer.component';
import { ListButtonRendererComponent } from './list/list-button.renderer.component';
import { ListValidationRendererComponent } from './list/list-validation.renderer.component';
import { ListComponent } from './list/list.component';
import { ListInputLineRendererComponent } from './list/list.input.line.renderer.component';
import { ListInputRendererComponent } from './list/list.input.renderer.component';
import { LoadingHeaderComponent } from './list/loading-header.component';
import {
  LoadingPageComponent,
  LongLoadingInformationComponent,
} from './loading-page';
import {
  AbrechnungszeitraumButtonsComponent,
  LohnkontextAbrechnungszeitraumComponent,
  LohnkontextBorderComponent,
  LohnkontextClosedIconComponent,
  LohnkontextDatepickerHeaderComponent,
  LohnkontextSearchComponent,
  LohnkontextSearchTypeComponent,
} from './lohnkontext';
import {
  AppLongRunningProcessDialogComponent,
  AppLongRunningProcessToolbarItemComponent,
} from './long-running-process';
import { MaskedAutocompleteDirective } from './masked-autocomplete';
import {
  MasterDetailComponent,
  MultiselectComponent,
} from './master-detail-component';
import {
  NavigationMenuComponent,
  NavigationMenuItemComponent,
  NavigationMenuSearchComponent,
  NavigationMenuSearchInputComponent,
} from './navigation/components';
import { OverviewCardComponent, OverviewComponent } from './overview';
import {
  AsObservablePipe,
  ColumnFieldNamePipe,
  FilterPipe,
  FormatByFieldTypePipe,
  FormatDateRelativePipe,
  HighlightPipe,
  StripHtmlPipe,
} from './pipes';
import { AppLongRunningProcessService } from './process-management/long-running-process.service';
import { ProcessManagerService } from './process-management/process-manager.service';
import { ResizeObserverDirective } from './resize-observer';
import {
  addDSMHandling,
  addExpandablePanelNoDefaultValueIfHiddenFeature,
  addFieldId,
  addFieldType,
  addLabel,
  addTestId,
  adjustDisabled,
  AuthorizationExtension,
  CheckboxTypeComponent,
  CrudSearchDialogComponent,
  FocusFieldInfoExtension,
  HelpButtonExtension,
  InputTypeComponent,
  InvalidControlIconComponent,
  SalaryButtonTypeComponent,
  SalaryCardTypeComponent,
  SalaryChipListTypeComponent,
  SalaryComponentTypeComponent,
  SalaryCrudSearchTypeComponent,
  SalaryDatepickerTypeComponent,
  SalaryDateRangePickerTypeComponent,
  SalaryExpandablePanelPrintTypeComponent,
  SalaryExpandablePanelTypeComponent,
  SalaryFlexLayoutTypeComponent,
  SalaryFormlyGroupComponent,
  SalaryIbanInputTypeComponent,
  SalaryIdentificationPanelTypeComponent,
  SalaryLabelTypeComponent,
  SalaryPrintTypeComponent,
  SalaryRepeatExpandablePanelPrintTypeComponent,
  SalaryRepeatExpandablePanelTypeComponent,
  SalaryRepeatTypeComponent,
  SalarySearchTypeComponent,
  SalarySelectTypeComponent,
  SalaryTabGroupTypeComponent,
  SalaryTabWithEnterExtension,
  SalaryTextareaTypeComponent,
  SalaryValidationExtension,
  SelectableChipListTypeComponent,
} from './salary-formly';
import { PrintModeExtension } from './salary-formly/extensions/print-mode.extension';
import { AccordionTypeComponent } from './salary-formly/types/accordion';
import {
  AutoCompleteHeightDirective,
  MatOptionData,
  PopupEnableDirective,
  SearchInputComponent,
  SearchInputEmptyStateComponent,
  SearchInputOptionItemDirective,
  SearchInputPanelHeaderDirective,
  SearchInputSuffixDirective,
  TwoLineOptionComponent,
} from './search';
import { ServerErrorEmptyStateComponent } from './server-error-empty-state';
import { ShimmerComponent } from './shimmer';
import { SubNavigationListItemComponent } from './sub-navigation';
import {
  SubNavigationContentComponent,
  SubNavigationContentContainerDirective,
} from './sub-navigation/sub-navigation-content.component';
import { SubNavigationComponent } from './sub-navigation/sub-navigation.component';
import { TermineCarouselComponent } from './termine/termine-carousel.component';
import {
  ToolbarComponent,
  ToolbarItemComponent,
  ToolbarItemVisibleDirective,
  ToolbarMenuButtonComponent,
} from './toolbar';

const declarations = [
  ListComponent,
  ToolbarComponent,
  ColumnFieldNamePipe,
  ElevationDirective,
  ComponentDirective,
  SearchAnimationDirective,
  ComponentDirectiveWithParameter,
  TooltipDirective,
  RowEditingDirective,
  FocusDirective,
  PagebreakDirective,
  DebounceClickDirective,
  AutoCompleteHeightDirective,
  PopupEnableDirective,
  CarouselItemDirective,
  LoadingButtonDirective,
  ListHelpDirective,
  HighlightPipe,
  FilterPipe,
  SubNavigationComponent,
  LohnkontextSearchComponent,
  LohnkontextAbrechnungszeitraumComponent,
  LohnkontextClosedIconComponent,
  ListInputRendererComponent,
  ListInputLineRendererComponent,
  CheckboxRendererComponent,
  ListEmptyStateComponent,
  DetailListEmptyStateComponent,
  ListSearchEmptyStateWrapperComponent,
  ListPapierkorbEmptyStateComponent,
  ListSearchEmptyStateComponent,
  InputEditorComponent,
  CheckboxEditorComponent,
  CurrentDateLabelComponent,
  CurrentUserDialogComponent,
  CurrentUserToolbarItemComponent,
  ListDsmPerspectivesContainerComponent,
  MaskedAutocompleteDirective,
  HotkeysDialogComponent,
  ListButtonRendererComponent,
  ListValidationRendererComponent,
  LoadingHeaderComponent,
  SearchInputComponent,
  SearchInputEditorComponent,
  SearchInputEmptyStateComponent,
  DateInputEditorComponent,
  ToolbarItemComponent,
  ToolbarMenuButtonComponent,
  ColumnChooserDialogComponent,
  HeaderContainerComponent,
  DetailHeaderComponent,
  MatOptionData,
  GapDirective,
  DetailOutputComponent,
  DetailOutputDialogComponent,
  EllipsisDirective,
  StripHtmlPipe,
  CustomTooltipComponent,
  BannerComponent,
  HtmlTextBannerComponent,
  SubNavigationContentContainerDirective,
  SubNavigationContentComponent,
  SubNavigationListItemComponent,
  SelectableChipListTypeComponent,
  FormatByFieldTypePipe,
  FormatDateRelativePipe,
  AsObservablePipe,
  AppSettingsDialogComponent,
  AppSettingsResetConfirmationDialogComponent,
  LoadingPageComponent,
  LongLoadingInformationComponent,
  EnumInputEditorComponent,
  PerspectivesToolbarComponent,
  ItemNotFoundEmptyStateComponent,
  ServerErrorEmptyStateComponent,
  AppDownloadComponent,
  ChipsFilterComponent,
  ListImportDialogComponent,
  ToolbarItemVisibleDirective,
  ListEmptyStateToolbarDefinitionPipe,
  CarouselComponent,
  TermineCarouselComponent,
  ActiveTabPageNavigationDirective,
  FileDownloadCellRendererComponent,
  AppLongRunningProcessDialogComponent,
  AppLongRunningProcessToolbarItemComponent,
  DetectBankleitzahlDirective,
  ShimmerComponent,
  LohnkontextDatepickerHeaderComponent,
  DetailSearchComponent,
  GroupRowInnerRendererComponent,
  LohnkontextSearchTypeComponent,
  FormlyGridEditorWrapperComponent,
  SearchInputSuffixDirective,
  SearchInputOptionItemDirective,
  CrudSearchDialogComponent,
  SalaryFormlyGroupComponent,
  SalaryDatepickerTypeComponent,
  InputTypeComponent,
  SalaryButtonTypeComponent,
  SalaryTextareaTypeComponent,
  SalarySelectTypeComponent,
  SalaryPrintTypeComponent,
  SalaryExpandablePanelPrintTypeComponent,
  SalaryRepeatExpandablePanelPrintTypeComponent,
  CheckboxTypeComponent,
  SalaryRepeatExpandablePanelTypeComponent,
  SalaryComponentTypeComponent,
  SalaryLabelTypeComponent,
  SalarySearchTypeComponent,
  SalaryFlexLayoutTypeComponent,
  SalaryTabGroupTypeComponent,
  SalaryCardTypeComponent,
  ResizeObserverDirective,
  TwoLineOptionComponent,
  ButtonSpeedDialComponent,
  FocusMatOptionDirective,
  SearchInputPanelHeaderDirective,
  HTMLEditorComponent,
  HtmlEditDialogComponent,
  MasterDetailComponent,
  MultiselectComponent,
  LayoutComponent,
  SalaryLayoutLeftDirective,
  SalaryLayoutContentDirective,
  SalaryLayoutRightDirective,
  BreadcrumbComponent,
  BreadcrumbPanelComponent,
  ApplicationToolbarItemComponent,
  BrzIconComponent,
  GlobalSearchComponent,
  TopToolbarComponent,
  NavigationMenuComponent,
  NavigationMenuItemComponent,
  NavigationMenuSearchComponent,
  NavigationMenuSearchInputComponent,
  FilterButtonComponent,
  FilterButtonChipsPopupComponent,
  AccordionTypeComponent,
  ListSortWithFallbackDirective,
  NotificationToolbarItemComponent,
  TipsToolbarItemComponent,
  LohnkontextBorderComponent,
  AbrechnungszeitraumButtonsComponent,
  SalaryExpandablePanelTypeComponent,
  SalaryCrudSearchTypeComponent,
  SalaryIbanInputTypeComponent,
  SalaryChipListTypeComponent,
  SalaryRepeatTypeComponent,
  SalaryDateRangePickerTypeComponent,
  SalaryIdentificationPanelTypeComponent,
  HelpButtonComponent,
  InvalidControlIconComponent,
  ListCellRendererTreeExpanderComponent,
  OverviewCardComponent,
  OverviewComponent,
  ChipButtonComponent,
  FilterButtonDateComponent,
];

const imports = [
  CommonModule,
  RouterModule,
  CommonDialogModule,
  CommonAuthorizationModule,
  CommonDumbComponentsModule,
  IMaskModule,
  AgGridModule,
  CommonFormlyModule,
];

@NgModule({
  declarations: declarations,
  imports: imports,
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: () => {
        return {
          fieldTypes: [
            {
              name: 'formly-group',
              component: SalaryFormlyGroupComponent,
            },
            { name: 'tabgroup', component: SalaryTabGroupTypeComponent },
            { name: 'fxLayout', component: SalaryFlexLayoutTypeComponent },
            { name: 'card', component: SalaryCardTypeComponent },
            {
              name: 'input',
              component: InputTypeComponent,
              matFormField: true,
            },
            {
              name: 'textarea',
              component: SalaryTextareaTypeComponent,
              matFormField: true,
            },
            {
              name: 'search',
              component: SalarySearchTypeComponent,
              matFormField: true,
            },
            {
              name: 'datepicker',
              component: SalaryDatepickerTypeComponent,
              matFormField: true,
            },
            { name: 'label', component: SalaryLabelTypeComponent },
            {
              name: 'select',
              component: SalarySelectTypeComponent,
              matFormField: true,
            },
            {
              name: 'checkbox',
              component: CheckboxTypeComponent,
              matFormField: true,
            },
            { name: 'component', component: SalaryComponentTypeComponent },
            {
              name: 'repeatPanel',
              component: SalaryRepeatExpandablePanelTypeComponent,
            },
            {
              name: 'lohnkontextDatepicker',
              component: SalaryDatepickerTypeComponent,
            },
            {
              name: 'lohnkontextSearch',
              component: LohnkontextSearchTypeComponent,
            },
            {
              name: 'expandablePanel',
              component: SalaryExpandablePanelTypeComponent,
            },
            {
              name: 'crudSearch',
              component: SalaryCrudSearchTypeComponent,
              matFormField: true,
            },
            {
              name: 'ibanInput',
              component: SalaryIbanInputTypeComponent,
              matFormField: true,
            },
            {
              name: 'dateRangepicker',
              component: SalaryDateRangePickerTypeComponent,
              matFormField: true,
            },
            {
              name: 'chipList',
              component: SalaryChipListTypeComponent,
              matFormField: true,
            },
            {
              name: 'chipListSelect',
              component: SelectableChipListTypeComponent,
            },
            { name: 'repeat', component: SalaryRepeatTypeComponent },
            {
              name: 'identificationPanel',
              component: SalaryIdentificationPanelTypeComponent,
            },
            { name: 'accordion', component: AccordionTypeComponent },
            { name: 'button', component: SalaryButtonTypeComponent },
            { name: 'print', component: SalaryPrintTypeComponent },
            {
              name: 'repeatPanelPrint',
              component: SalaryRepeatExpandablePanelPrintTypeComponent,
            },
            {
              name: 'expandablePanelPrint',
              component: SalaryExpandablePanelPrintTypeComponent,
            },
          ],
          validationMessages:
            ValidationMessages.defaultValidationMessages as SalaryValidationMessageOption[],
          prePopulateExtensions: [
            addLabel,
            addFieldType,
            addFieldId,
            (field) => inject(AuthorizationExtension).addSecurity(field),
            adjustDisabled,
            addTestId,
            addDSMHandling,
            addExpandablePanelNoDefaultValueIfHiddenFeature,
            (field) => inject(HelpButtonExtension).addMouseEnterListener(field),
            (field) => inject(FocusFieldInfoExtension).addFocusListener(field),
            (field) =>
              inject(SalaryTabWithEnterExtension).addTabWithEnterFeature(field),
            (field) => inject(PrintModeExtension).modifyTypeToPrint(field),
          ],
          postPopulateExtensions: [
            (field) =>
              inject(SalaryValidationExtension).registerClientValdationErrors(
                field,
              ),
          ],
        } as FormlyConfig;
      },
    },
  ],
  exports: [...imports, ...declarations],
})
export class CommonComponentsModule {
  constructor() {
    FORMLY_FORM_PROVIDERS.push(AuthorizationExtension);
  }
  static forRoot(): ModuleWithProviders<CommonComponentsModule> {
    return {
      ngModule: CommonComponentsModule,
      providers: [
        {
          provide: PROCESS_MANAGER_SERVICE_TOKEN,
          useClass: ProcessManagerService,
        },
        {
          provide: APP_LONG_RUNNING_PROCESS_SERVICE_TOKEN,
          useClass: AppLongRunningProcessService,
        },
        Title,
      ],
    };
  }
}
