import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  linkedSignal,
  signal,
  untracked,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatChipSelectionChange } from '@angular/material/chips';
import { DateTimeFormats } from '@salary/common/dumb';
import { SettingsFacade } from '@salary/common/facade';
import { DateTime } from 'luxon';
import { DatepickerFieldConfig, FormConfigBuilder } from '../salary-formly';

@Component({
  selector: 'salary-filter-button-date',
  template: `
    <div [matTooltip]="tooltip()" matTooltipClass="filter-button-date-tooltip">
      <salary-chip-button
        [title]="title()"
        [badgeText]="badgeText()"
        panelClass="chip-date-button-panel"
        [menuContent]="menuTemplate"
      >
      </salary-chip-button>
      <ng-template #menuTemplate>
        <div (click)="onContainerClick($event)" class="outer-container">
          <mat-chip-listbox>
            <div class="chip-grid">
              @for (option of options(); track option.type) {
                <mat-chip-option
                  [selected]="option.selected"
                  [attr.data-testid]="
                    'chip_' + option.type | convertSpecialCharacter
                  "
                  (selectionChange)="selectionChanged($event, option)"
                >
                  {{ option.type }}
                </mat-chip-option>
              }
            </div>
          </mat-chip-listbox>
          <form [formGroup]="form">
            <salary-form
              [fields]="fields"
              [form]="form"
              [model]="selectedRange()"
            />
          </form>
        </div>
      </ng-template>
    </div>
  `,
  styles: `
    .outer-container {
      padding: 0 8px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .chip-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 6px;
    }
    mat-chip {
      margin: 5px;
    }
    ::ng-deep .chip-date-button-panel {
      width: 300px !important;
    }
    ::ng-deep .filter-button-date-tooltip {
      width: 120px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FilterButtonDateComponent {
  private readonly defaultConfig = {
    dateRangeOptions: [
      { type: DateRangeType.CurrentMonth },
      { type: DateRangeType.LastMonth },
      { type: DateRangeType.LastThreeMonth },
      { type: DateRangeType.LastHalfYear },
      { type: DateRangeType.CurrentYear },
      { type: DateRangeType.LastYear },
    ],
  };
  private settingsFacade = inject(SettingsFacade);
  private disableSelectionChangedEvent = false;
  title = input<string>('Zeitraum');
  configuration = input(undefined, {
    transform: (value: FilterButtonDateConfiguration) => {
      if (!value.dateRangeOptions) {
        value.dateRangeOptions = this.defaultConfig.dateRangeOptions;
      }
      return value;
    },
  });
  protected options = linkedSignal<DateRangeOption[]>(
    () => this.configuration()?.dateRangeOptions,
  );
  protected selectedRange = signal<DateRange>(undefined);
  protected form = new UntypedFormGroup({});
  protected badgeText = signal<number>(undefined);
  protected tooltip = signal<string>(undefined);
  protected onContainerClick(event: MouseEvent) {
    event.stopPropagation();
  }
  protected fields: () => DatepickerFieldConfig[] = () => {
    const b = new FormConfigBuilder();
    b.dateRangePicker({
      label: 'Zeitraum',
    }).withFields((b) => [
      b.datepicker('from', {
        placeholder: 'Von',
        dateChange: ({ model }) => this.dateChange(model()),
      }),
      b.datepicker('to', {
        placeholder: 'Bis',
        dateChange: ({ model }) => this.dateChange(model()),
      }),
    ]);
    return b.build();
  };
  protected selectionChanged(
    event: MatChipSelectionChange,
    item: DateRangeOption,
  ) {
    if (this.disableSelectionChangedEvent || !event.isUserInput) {
      return;
    }
    const selectedType = event.selected ? item.type : undefined;
    this.options().forEach(
      (o) => (o.selected = event.selected && item.type === o.type),
    );
    this.setRangePickerFromType(selectedType);
    this.saveSetting({ dateRangeType: selectedType });
    this.selectedRangeChanged(this.form.value);
  }

  private setRangePickerFromType(item: DateRangeType) {
    this.form.controls['from'].setValue(this.calculateDateFrom(item));
    this.form.controls['to'].setValue(this.calculateDateTo(item));
  }

  constructor() {
    const effectRef = effect(() => {
      const con = this.configuration();
      if (!con.settingsKey) {
        return;
      }
      untracked(() => {
        effectRef.destroy();
        const setting =
          this.settingsFacade.selectBenutzerSettingByKey<DateRangeSetting>(
            con.settingsKey,
          )()?.value;
        if (setting) {
          let range: DateRange;
          if (setting.dateRange) {
            if (setting.dateRange.from || setting.dateRange.to) {
              range = setting.dateRange;
            }
          } else if (setting.dateRangeType) {
            this.options().find(
              (c) => c.type === setting.dateRangeType,
            ).selected = true;
            this.options.set(this.options());
            range = {
              from: this.calculateDateFrom(setting.dateRangeType),
              to: this.calculateDateTo(setting.dateRangeType),
            };
          }
          this.selectedRange.set(range);
          this.selectedRangeChanged(range);
        }
      });
    });
  }

  private calculateDateFrom(type: DateRangeType): DateTime<boolean> {
    switch (type) {
      case DateRangeType.LastMonth:
        return DateTime.now().minus({ month: 1 }).startOf('month');
      case DateRangeType.CurrentMonth:
        return DateTime.now().startOf('month');
      case DateRangeType.LastThreeMonth:
        return DateTime.now().minus({ month: 3 }).startOf('day');
      case DateRangeType.CurrentYear:
        return DateTime.now().startOf('year');
      case DateRangeType.LastHalfYear:
        return DateTime.now().minus({ month: 6 }).startOf('day');
      case DateRangeType.LastYear:
        return DateTime.now().minus({ year: 1 }).startOf('year');
    }
  }

  private calculateDateTo(type: DateRangeType): DateTime<boolean> {
    switch (type) {
      case DateRangeType.LastMonth:
        return DateTime.now().minus({ month: 1 }).endOf('month');
      case DateRangeType.CurrentMonth:
        return DateTime.now().endOf('month');
      case DateRangeType.LastThreeMonth:
        return DateTime.now().endOf('day');
      case DateRangeType.CurrentYear:
        return DateTime.now().endOf('year');
      case DateRangeType.LastHalfYear:
        return DateTime.now().endOf('day');
      case DateRangeType.LastYear:
        return DateTime.now().minus({ year: 1 }).endOf('year');
    }
  }

  protected dateChange(model: DateRange) {
    if (this.configuration().settingsKey) {
      this.saveSetting({ dateRange: model });
    }
    this.disableSelectionChangedEvent = true;
    this.options().forEach((o) => (o.selected = false));
    this.options.set(this.options());
    this.disableSelectionChangedEvent = false;
    this.selectedRangeChanged(model);
  }

  private selectedRangeChanged(model: DateRange) {
    this.configuration().filterChanged?.({ ...model });
    this.badgeText.set(model.from || model.to ? 1 : undefined);
    this.tooltip.set(
      `Von: ${model.from?.toFormat(DateTimeFormats.DATE) ?? 'nicht gesetzt'} bis: ${model.to?.toFormat(DateTimeFormats.DATE) ?? 'nicht gesetzt'}`,
    );
  }

  private saveSetting(setting: DateRangeSetting) {
    if (this.configuration().settingsKey) {
      this.settingsFacade.createOrUpdateUserSetting<DateRangeSetting>({
        key: this.configuration().settingsKey,
        value: setting,
      });
    }
  }
}

export interface FilterButtonDateConfiguration {
  settingsKey?: string;
  dateRangeOptions?: DateRangeOption[];
  filterChanged?: (dateRange: DateRange) => void;
}

interface DateRangeOption {
  type: DateRangeType;
  selected?: boolean;
}

export enum DateRangeType {
  CurrentMonth = 'aktueller Monat',
  LastMonth = 'letzter Monat',
  LastThreeMonth = 'letzte 3 Monate',
  LastHalfYear = 'letztes halbes Jahr',
  CurrentYear = 'aktuelles Jahr',
  LastYear = 'letztes Jahr',
}

export interface DateRangeSetting {
  dateRange?: DateRange;
  dateRangeType?: DateRangeType;
}

export interface DateRange {
  from: DateTime;
  to: DateTime;
}
