import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  Injector,
  isSignal,
  OnDestroy,
  OnInit,
  OutputEmitterRef,
  untracked,
  viewChild,
} from '@angular/core';
import { convertToSignal, FieldType } from '@salary/common/formly';
import { BaseComponent } from '../../../detail-container';
import { ComponentDirective } from '../../../directives/component-directive';
import { IObserver } from '../../../utils';
import { ComponentFieldConfig } from './component-field-config';

export interface SalaryFormlyComponentTypeComponent {
  updateData(data);
}

@Component({
    selector: 'salary-component-type',
    template: `<ng-template salaryComponent />`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SalaryComponentTypeComponent
  extends FieldType<ComponentFieldConfig<unknown>>
  implements OnInit, OnDestroy
{
  private salaryComponentDirective = viewChild(ComponentDirective);
  private componentRef: SalaryFormlyComponentTypeComponent;
  private observer: IObserver<unknown>;
  private readonly injector = inject(Injector);

  override ngOnInit(): void {
    super.ngOnInit();
    const viewContainerRef = this.salaryComponentDirective().viewContainerRef;
    viewContainerRef.clear();

    const newComponentRef = viewContainerRef.createComponent(
      this.field()?.component,
      !this.field()?.providers
        ? undefined
        : {
            injector: Injector.create({
              providers: this.field().providers,
              parent: this.injector,
            }),
          },
    );
    const newComponent = newComponentRef.instance;

    if (newComponent) {
      (newComponent as BaseComponent).componentTypeParent = {
        formly: this.field(),
        component: this.field().options.formState.component,
      };
      if (this.field()?.inputs) {
        Object.entries(this.field().inputs).forEach(([inputName, value]) => {
          if (isSignal(value)) {
            effect(() => newComponentRef.setInput(inputName, value()), {
              injector: this.injector,
            });
          } else {
            newComponentRef.setInput(inputName, value);
          }
        });
      }
      if (this.field()?.outputs) {
        Object.entries(this.field().outputs).forEach(
          ([outputName, handler]) => {
            const output = newComponent[outputName];
            if (
              output instanceof OutputEmitterRef &&
              typeof handler === 'function'
            ) {
              output.subscribe(handler as () => void);
            }
          },
        );
      }
    }

    this.componentRef = newComponent as SalaryFormlyComponentTypeComponent;
    if (this.field().data != null) {
      const dataSignal = convertToSignal('data', this.field());
      effect(
        () => {
          const data = dataSignal();
          untracked(() => {
            this.componentRef.updateData(data);
          });
        },
        { injector: this.injector },
      );
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.observer?.unsubscribe();
    this.observer = undefined;
  }
}
