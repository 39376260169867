import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'salary-brz-icon',
    template: `<a
    data-testid="applicationToolbar_homeLink"
    [routerLink]="['/']"
    class="home"
    matTooltip="Home"
  >
    <img src="assets/images/logo.svg" alt="Home" />
    baulohn 365
  </a>`,
    styles: `
    .home {
      font-size: 18px;
      padding-top: 3.5px;
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BrzIconComponent {}
