import { Directive, ViewContainerRef, inject, input } from '@angular/core';

@Directive({
    selector: '[salaryComponent]',
    standalone: false
})
export class ComponentDirective {
  public viewContainerRef = inject(ViewContainerRef);
}

@Directive({
    selector: '[salaryComponentWithParameter]',
    standalone: false
})
export class ComponentDirectiveWithParameter {
  parameter = input<unknown>(undefined, {
    alias: 'salaryComponentWithParameter',
  });
  public viewContainerRef = inject(ViewContainerRef);
}
