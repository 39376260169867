import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'salary-overview-card',
  template: `
    <mat-card
      salaryElevation
      [routerLink]="path()"
      class="card"
      [attr.data-testid]="
        'overview_card_' + caption() | convertSpecialCharacter
      "
    >
      <mat-card-header>
        <mat-icon class="card-icon" mat-card-avatar>
          {{ iconName() }}
        </mat-icon>
        <mat-card-title>
          <div salaryEllipsis>{{ caption() }}</div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div salaryEllipsis>{{ description() }}</div>
      </mat-card-content>
    </mat-card>
  `,
  styles: `
    .card {
      margin: 6px 10.5px 10.5px 12px;
      height: calc(100% - 16.5px); /** 6+10.5px top+bottom margins */
      &:hover {
        cursor: pointer;
      }
    }
    .card-icon {
      font-size: 40px;
      border-radius: 0;
      line-height: 29px;
    }

    :host ::ng-deep .mat-mdc-card-header-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OverviewCardComponent {
  iconName = input.required<string>();
  caption = input.required<string>();
  description = input.required<string>();
  path = input.required<string>();
}
