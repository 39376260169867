import {
  ColumnFunctionCallbackParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { Type } from '@angular/core';
import {
  NameFunction,
  capitalizeFirstLetter,
  nameof,
} from '@salary/common/dumb';
import { Observable } from 'rxjs';
import { SortOrder } from '../../utils/sort-order.enum';
import { CellRendererOptions } from './cell-renderer-options';
import { CheckboxEditorColumnOptions } from './checkbox-editor-column-options';
import { DateEditorColumnOptions } from './date-editor-column-options';
import { EnumEditorColumnOptions } from './enum-editor-column-options';
import { HourSymbolEditorColumnOptions } from './hour-symbol-editor-column-options';
import { InputEditorColumnOptions } from './input-editor-column-options';
import { SearchEditorColumnOptions } from './search-editor-column-options';

export interface ColumnDefinition<T = unknown> {
  columnTitle?: string;
  modelPropertyName?: string | NameFunction<T>;
  sortModelPropertyName?: string | NameFunction<T>;
  identificationProperty?: boolean;
  /**group columns in columnChooser by columnGroupName, makes column titles with same detail-caption unique */
  columnGroupName?: string;
  sortOrder?: SortOrder;
  editable?: boolean | ((params: ColumnFunctionCallbackParams) => boolean);
  /**if modelMapping is specified the function parameter will be in mapped format */
  displayText?: (model) => string;
  displayFormat?: string;
  cellEditor?:
    | 'inputEditor'
    | 'searchInputEditor'
    | 'dateInputEditor'
    | 'enumInputEditor'
    | 'checkboxEditor'
    | Type<unknown>;
  cellRenderer?:
    | 'inputCellRenderer'
    | 'checkboxCellRenderer'
    | 'buttonCellRenderer'
    | 'fileDownloadCellRenderer'
    | Type<unknown>;
  cellRendererOptions?: CellRendererOptions;
  editorOptions?:
    | InputEditorColumnOptions
    | SearchEditorColumnOptions<T>
    | EnumEditorColumnOptions
    | DateEditorColumnOptions
    | CheckboxEditorColumnOptions
    | HourSymbolEditorColumnOptions;

  headerClass?: string;
  /** true means visible (checked in columnchooser); false means not visible (available only by searching in columnChooser);
   * onlyInColumnChooser means available in columnchooser but not checked */
  visibility?: boolean | 'onlyInColumnChooser';
  alignRight?: boolean;
  minWidth?: number;
  maxWidth?: number;
  cellStyle?: Record<string, string | number>;
  /**default false */
  showBorderOnSelectedCell?: boolean;
  headerTooltip?: string;
  /**default false */
  alwaysShowHeaderTooltip?: boolean;
  /** if true, data will be grouped by this column, only works in clientSide grids */
  rowGroup?: boolean;
  /** defines if an aggregation should be calculated for the data of the column (at least one column should be a rowGroup column), only works in clientSide grids  */
  aggFunc?: 'sum' | 'min' | 'max' | 'count' | 'avg' | 'first' | 'last';
  lockVisible?: boolean;
  /** disable sorting even if column has a bounded fieldName (no calculated value) */
  disableSorting?: boolean;
  columnType?: 'text' | 'date' | 'enum' | 'boolean';
  /**if observables value changes, cellRenderers will be updated. Value of observable is rowIndex of row with cellrenderers to refresh.
   * value undefined will refresh every row.
   */
  refreshCellRenderer$?: Observable<number>;
  valueGetter?: (params: ValueGetterParams) => unknown;
  /**fields without model mapping can also have a fieldId */
  fieldId?: string;
}

export function addColumnGroupName<T = unknown>(
  groupName: string,
  ...def: (ColumnDefinition<T> | NameFunction<T>)[]
): ColumnDefinition<T>[] {
  return def.map((cd) => {
    const columnDefinition: ColumnDefinition<T> =
      typeof cd === 'function'
        ? { modelPropertyName: nameof(cd), visibility: false }
        : cd;
    columnDefinition.columnGroupName = capitalizeFirstLetter(groupName);
    return columnDefinition;
  });
}
