<div (salaryOnResize)="handleResize($event)">
  <mat-toolbar id="toolbar" [class]="classes()">
    <div
      style="
        display: flex;
        place-content: center space-between;
        min-width: 100%;
        height: 100%;
      "
    >
      <div
        class="left-container"
        [style.min-width]="moreOptionsLeft() ? '44px' : 0"
      >
        @for (
          toolbarDefinition of definitionsLeft();
          track identifyToolbarDefinition(toolbarDefinition)
        ) {
          <salary-toolbar-item
            *salaryToolbarItemVisible="toolbarDefinition"
            [toolbarDefinition]="toolbarDefinition"
            [style]="{
              'align-self': toolbarDefinition.stretch ? 'stretch' : 'center',
              'flex-shrink': toolbarDefinition.shrink ? '1' : '0',
            }"
            class="toolbar-item"
          />
        }
      </div>
      <div class="right-container">
        @for (
          toolbarDefinition of definitionsRight();
          track identifyToolbarDefinition(toolbarDefinition)
        ) {
          <salary-toolbar-item
            *salaryToolbarItemVisible="toolbarDefinition"
            [toolbarDefinition]="toolbarDefinition"
            [style]="{
              'align-self': toolbarDefinition.stretch ? 'stretch' : 'center',
              'flex-shrink': toolbarDefinition.shrink ? '1' : '0',
            }"
            class="toolbar-item"
          />
        }
      </div>
    </div>
  </mat-toolbar>
</div>
@if (!finishedMeasuring()) {
  <div
    #measuringContainer
    style="position: absolute; display: flex; visibility: hidden"
  >
    @for (def of toolbarDefinitions() | filter: noWidth; track def) {
      <salary-toolbar-item
        [toolbarDefinition]="def"
        [style]="{
          'align-self': def.stretch ? 'stretch' : 'center',
          'flex-shrink': def.shrink ? '1' : '0',
        }"
        class="toolbar-item"
      />
    }
  </div>
}
