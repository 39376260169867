import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import {
  FieldArrayType,
  FieldConfig,
  convertToSignal,
} from '@salary/common/formly';

import { getFullCaption } from '../../../utils/salary-print-utils';
import { RepeatExpandablePanelPrintChildConfig } from './repeat-expandable-panel-print-config';

@Component({
    selector: 'salary-repeat-expandable-panel-print',
    template: `
    <div>
      @for (field of sortedFieldGroups(); track field.id) {
        <salary-header-container [bigSubTitle]="getTitle(field)()" />
        <salary-form-field [field]="field" />
      }
    </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SalaryRepeatExpandablePanelPrintTypeComponent extends FieldArrayType<FieldConfig> {
  protected sortedFieldGroups = computed(() => {
    this.field().model?.();

    return [...(this.field().fieldGroup ?? [])];
  });

  protected getTitle(field: RepeatExpandablePanelPrintChildConfig) {
    return computed(() =>
      getFullCaption(convertToSignal('headerTitle', field), field)(),
    );
  }
}
