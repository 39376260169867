import { Pipe, PipeTransform } from '@angular/core';
import { NameFunction, getFieldName } from '@salary/common/dumb';

@Pipe({
    name: 'getColumnFieldName',
    standalone: false
})
export class ColumnFieldNamePipe implements PipeTransform {
  transform(value: string | NameFunction<unknown>) {
    return getFieldName(value);
  }
}
