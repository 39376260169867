import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AbrechnungskreisHinweisDialogData {
  hinweise: { hinweisText?: string; istWichtig?: boolean }[];
  abrechnungskreis: { nummer?: string; bezeichnung?: string };
}

@Component({
    selector: 'salary-abrechnungskreis-hinweis-dialog',
    template: ` <h2 mat-dialog-title>
      {{
        'Hinweise für ' +
          data.abrechnungskreis?.nummer +
          ' - ' +
          data.abrechnungskreis?.bezeichnung
      }}
    </h2>
    <mat-dialog-content>
      @for (hinweis of data.hinweise; track $index; let i = $index) {
        <mat-card
          appearance="outlined"
          [attr.data-testid]="'abrechnungskreis-hinweise-dialog-hinweis_' + i"
        >
          @if (hinweis.istWichtig) {
            <mat-card-header>
              <mat-icon
                matTooltip="Wichtiger Hinweis"
                style="border-radius: unset"
                mat-card-avatar
                [attr.data-testid]="
                  'abrechnungskreis-hinweise-dialog-hinweis-icon_' + i
                "
                >announcement</mat-icon
              >
            </mat-card-header>
          }
          <mat-card-content>
            {{ hinweis.hinweisText }}
          </mat-card-content>
        </mat-card>
      }
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        mat-dialog-close
        cdkFocusInitial
        data-testid="abrechnungskreis-hinweise-dialog-close-button"
      >
        Schließen
      </button>
    </mat-dialog-actions>`,
    styles: `
    .mat-mdc-dialog-content {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .mat-mdc-card {
      flex-basis: 28%;
    }
    .mat-mdc-card-avatar {
      user-select: none;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AbrechnungskreisHinweiseDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AbrechnungskreisHinweisDialogData,
  ) {}
}
