@let title = toolbarItemTitle();
<ng-container
  [ngTemplateOutlet]="
    isMenuButton()
      ? menuButton
      : isButton()
        ? simpleButton
        : isToggleButton()
          ? toggleButton
          : isFilterButton()
            ? filterButton
            : isFilterButtonDate()
              ? filterButtonDate
              : isCustomComponent()
                ? customComponent
                : inputButton
  "
/>

<ng-template #customComponent />
<ng-template #menuButton>
  @if (hasChildren()) {
    <salary-toolbar-menu-button [toolbarDefinition]="toolbarDefinition()" />
  }
</ng-template>

<ng-template #simpleButton>
  @if (!isMenuItem()) {
    <div [matTooltip]="tooltip()">
      <button
        style="display: flex"
        [salaryLoadingButton]="toolbarDefinition().loading?.()"
        mat-button
        #toolbarButton
        [class]="buttonClass()"
        [attr.data-testid]="
          toolbarDefinition().testId ?? 'button_' + title
            | convertSpecialCharacter
        "
        (salaryDebounceClick)="executingAction(toolbarButton, null)"
        [debounceTime]="toolbarDefinition().debounceTime"
        [disabled]="toolbarDefinition().buttonDisabled?.()"
      >
        @if (isIconButton()) {
          <mat-icon>{{ toolbarDefinition().iconName }}</mat-icon>
        } @else {
          {{ title }}
        }
      </button>
    </div>
  } @else {
    <button
      style="display: flex"
      mat-menu-item
      #toolbarButton
      [class]="buttonClass()"
      [attr.data-testid]="
        toolbarDefinition().testId ?? 'button_' + title
          | convertSpecialCharacter
      "
      (click)="executingAction(toolbarButton, null)"
      [disabled]="toolbarDefinition().buttonDisabled?.()"
      [matTooltip]="tooltip()"
    >
      @if (isIconButton()) {
        <mat-icon>{{ toolbarDefinition().iconName }}</mat-icon>
      } @else {
        {{ title }}
      }
    </button>
  }
</ng-template>

<ng-template #toggleButton>
  <mat-slide-toggle
    #toggleToolbarButton
    class="toolbar-list-view-mode"
    (change)="executingAction(toggleToolbarButton, $event.checked)"
    [attr.data-testid]="'toggleButton' + title | convertSpecialCharacter"
  >
    {{ title }}</mat-slide-toggle
  >
</ng-template>

<ng-template #inputButton>
  <div class="toolbar-input-button" salarySearchAnimation>
    <mat-icon class="search-icon">search</mat-icon>
    <input
      #textInput
      [salaryEllipsis]="textInput.value"
      class="toolbar-input"
      type="text"
      matInput
      [placeholder]="title"
      (input)="executingAction(textInput, $any($event).target.value)"
      data-testid="list_search_input"
    />
    @if (textInput.value) {
      <button
        class="small-button"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (salaryDebounceClick)="
          textInput.value = ''; executingAction(textInput, '')
        "
        [debounceTime]="toolbarDefinition().debounceTime"
        data-testid="list_search_clear"
      >
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>
</ng-template>

<ng-template #filterButton>
  <salary-filter-button
    [title]="title"
    [configuration]="toolbarDefinition().filterButtonConfiguration"
  />
</ng-template>

<ng-template #filterButtonDate>
  <salary-filter-button-date
    [title]="title"
    [configuration]="toolbarDefinition().filterButtonDateConfiguration"
  />
</ng-template>
