import {
  DestroyRef,
  Directive,
  HostListener,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject, skip } from 'rxjs';

@Directive({
    selector: '[salaryPopupEnabled]',
    standalone: false
})
export class PopupEnableDirective {
  component = input.required<PopupEnabledSupport>({
    alias: 'salaryPopupEnabled',
  });
  enablePopupWithF7 = input(true);
  private autocompleteTrigger = inject(MatAutocompleteTrigger);
  private destroyRef = inject(DestroyRef);

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent) {
    if (
      !this.enablePopupWithF7() ||
      event.relatedTarget == null ||
      (event.relatedTarget as HTMLElement).tagName === 'MAT-OPTION'
    ) {
      return;
    }

    this.component().popupModeEnabled$.next(false);
  }

  @HostListener('keydown.F7', ['$event'])
  onF7Key() {
    if (!this.enablePopupWithF7()) {
      return;
    }
    this.component().popupModeEnabled$.next(
      !this.component().popupModeEnabled$.value,
    );
  }

  @HostListener('keydown.ESC')
  onEscKey() {
    if (!this.enablePopupWithF7()) {
      return;
    }
    this.component().popupModeEnabled$.next(false);
  }

  ngAfterViewInit() {
    this.component()
      .popupModeEnabled$.pipe(takeUntilDestroyed(this.destroyRef), skip(1))
      .subscribe((enabled) => {
        this.autocompleteTrigger.autocompleteDisabled = !enabled;
        if (enabled) {
          this.autocompleteTrigger.openPanel();
        }
      });
  }
}

export interface PopupEnabledSupport {
  popupModeEnabled$: BehaviorSubject<boolean>;
}
