import { DateTime } from 'luxon';
import { caption } from '../../decorators';
import { BaseModel, EnumClass } from '../base';

export enum AbrechnungsStatus {
  Abrechnung = 1,
  Abschluss = 3,
}

export class ProcessStatus extends EnumClass {
  static readonly unknown = 0;
  @caption('In Warteschlange...')
  static readonly ordered = 1;
  @caption('Wird ausgeführt...')
  static readonly running = 2;
  @caption('Wurde übersprungen')
  static readonly skipped = 3;
  @caption('Fertig')
  static readonly done = 4;
  @caption('Fertig mit Fehlern')
  static readonly doneWithError = 5;
  @caption('In Warteschlange...')
  static readonly intended = 6;
  @caption('Automatische Prozessausführung angefordert...')
  static readonly intendedBis = 7;
  @caption('Automatische Prozessausführung läuft...')
  static readonly waiting = 8;
}

/** index of array represents value of ProcessStatus */
export const iconNameByStatus = [
  undefined,
  'hourglass_empty',
  'autorenew',
  'step_over',
  'done',
  'priority_high',
  'hourglass_empty',
  'hourglass_empty',
  'hourglass_empty  ',
];

export class Abrechnung extends BaseModel {
  abrechnungskreisId?: string;
  abrechnungsmonat?: DateTime;
  abrechnungsStatus?: AbrechnungsStatus;
}
