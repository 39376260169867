import { Directive } from '@angular/core';
import { BaseModel } from '@salary/common/dumb';
import { BreadcrumbItem } from '@salary/common/utils';
import { ListDsmPerspectivesComponentBase } from '../list-container';
import { ToolbarDefinition } from '../utils';
import { OverviewConfig } from './overview-config';

@Directive()
export abstract class OverviewListComponentBase<
  T extends BaseModel,
> extends ListDsmPerspectivesComponentBase<T> {
  constructor() {
    super();
    this.toolbarDefinitions.update((definitions) => [
      ...definitions,
      this.schliessenToolbarDefinition,
    ]);
    this.entfernenVisible.set(false);
    this.hinzufuegenVisible.set(false);
    this.papierkorbVisible.set(false);
    this.modifyBreadcrumb();
  }

  private modifyBreadcrumb() {
    this.disableBreadcrumbTitle = true;
    const currentUrl = this.router.url;
    const parentUrl = this.isDsmPerspective()
      ? currentUrl.split('/').slice(0, -2).join('/')
      : currentUrl.substring(0, currentUrl.lastIndexOf('/'));
    const breadcrumbItems: BreadcrumbItem[] = this.getOverviewConfigs().map(
      (config) => ({
        label: config.caption,
        url: `${parentUrl}/${config.path}`,
      }),
    );
    const currentUrlBreadcrumb = this.isDsmPerspective()
      ? currentUrl.substring(0, currentUrl.lastIndexOf('/'))
      : currentUrl;
    this.breadcrumbService.detailViewRecordBreadcrumb.set(
      breadcrumbItems.find(({ url }) => url === currentUrlBreadcrumb),
    );
    this.breadcrumbService.availableDetailViewRecordBreadcrumbs.set(
      breadcrumbItems,
    );
  }
  private revertBreadcrumbModification() {
    this.breadcrumbService.detailViewRecordBreadcrumb.set(undefined);
    this.breadcrumbService.availableDetailViewRecordBreadcrumbs.set(undefined);
  }

  override switchShowNewItemRow() {
    //never activate hinzufugen in overviews. they are readonly
  }

  override showCopyToolbarActions() {
    //never activate copy-actions in overviews. they are readonly
  }

  protected readonly schliessenToolbarDefinition: ToolbarDefinition = {
    title: 'Schließen',
    iconName: 'close',
    actionHandler: () => {
      if (this.isDsmPerspective()) {
        this.routeBackService.navigateBack({
          fixedRoute: '../../',
          extras: { relativeTo: this.activatedRoute },
        });
      } else {
        this.routeBackService.navigateBack({
          relativeTo: this.activatedRoute,
        });
      }
    },
    alwaysRoot: true,
    alignment: 'right',
    visibleInEmptyState: true,
  };

  protected abstract getOverviewConfigs(): OverviewConfig[];

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.revertBreadcrumbModification();
  }
}
