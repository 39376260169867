import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  computed,
  effect,
  inject,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { BaseModel } from '@salary/common/dumb';
import { FieldType, convertToSignal } from '@salary/common/formly';
import { StandardFacade } from '@salary/common/standard-facade';
import { startWith } from 'rxjs';
import { SearchInputComponent } from '../../../search/search.input.component';
import { ChipListFieldConfig } from './chip-list-field-config';

@Component({
  template: `
    <mat-chip-listbox
      class="mat-chip-list-stacked"
      aria-orientation="vertical"
      #chipList
      [attr.data-testid]="field().testId + '_chip_list'"
    >
      @for (item of currentItems(); track item.id; let i = $index) {
        <mat-chip-option
          (removed)="remove(i)"
          [attr.data-testid]="field().testId + '_chip(' + i + ')'"
          [selected]="true"
        >
          {{ this.displayModelData(item) }}
          @if (
            !('readonly' | toSignal: field())() &&
            !('disabled' | toSignal: field())()
          ) {
            <mat-icon
              matChipRemove
              [attr.data-testid]="field().testId + '_chip_delete(' + i + ')'"
              >cancel</mat-icon
            >
          }
        </mat-chip-option>
      }
      <salary-search-input
        [hidden]="!inputPossible()"
        ngDefaultControl
        [formlyField]="field()"
        [errorStateMatcher]="errorStateMatcher"
        [lookupFacade]="lookupFacade"
        [sortExpression]="field().sortExpression"
        [displayFormat]="field().displayFormat"
        [displayText]="field().displayText"
        [mappingParent]="field().parent.model()"
        [placeholder]="('placeholder' | toSignal: field())()"
        [formControl]="searchFormControl"
        [queryParameters]="('queryParameters' | toSignal: field())()"
        [endpointConfiguration]="field().endpointConfiguration"
        (optionSelected)="selected($any($event))"
        [customFilter]="isNotAlreadyAssigned.bind(this)"
        [modelMapping]="field().modelMapping"
        [boundFieldname]="field().key"
      />
    </mat-chip-listbox>
  `,
  styles: `
    salary-search-input {
      width: 100%;
      flex: 1 0 100%;
      margin-left: 8px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SalaryChipListTypeComponent
  extends FieldType<ChipListFieldConfig>
  implements OnInit, OnDestroy
{
  private injector = inject(Injector);
  private changeDetector = inject(ChangeDetectorRef);
  protected searchFormControl = new UntypedFormControl();
  protected currentItems = signal<BaseModel[]>([], {
    equal: (a, b) =>
      a?.length === b?.length &&
      a.every(
        (aItem) =>
          !!b?.find(
            (bItem) =>
              this.field().identifyBy(bItem) === this.field().identifyBy(aItem),
          ),
      ),
  });
  protected inputPossible = computed(() => {
    const field = this.field();
    if (field?.maxChips != null) {
      const maxChips = convertToSignal('maxChips', this.field())();
      return maxChips == null || this.currentItems().length < maxChips;
    }
    return true;
  });
  private _lookupFacade: StandardFacade<BaseModel>;
  public static readonly defaultOptions: ChipListFieldConfig = {
    floatLabel: 'always' as const,
    identifyBy: (item: BaseModel) => item?.id,
  };

  private formFieldControl = viewChild(SearchInputComponent);

  get deletedItems(): BaseModel[] {
    return this.form['deleted'];
  }
  set deletedItems(items: BaseModel[]) {
    this.form['deleted'] = items;
  }
  get modifiedItems(): unknown[] {
    return this.form['modified'];
  }
  set modifiedItems(items: unknown[]) {
    this.form['modified'] = items;
  }

  /**always use this to change currentItems.
   *  It's good to these changes done immediately, before possible destruction of component */
  private setCurrentItems(items: BaseModel[], updateFormControl = true) {
    this.currentItems.set(items);
    if (updateFormControl) {
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
      this.formControl.setValue(items);
    }
  }

  constructor() {
    super();
    effect(() => {
      const disabled = convertToSignal('disabled', this.field())();
      untracked(() => {
        if (disabled) {
          this.searchFormControl.disable();
        } else {
          this.searchFormControl.enable();
        }
      });
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    if (!this.deletedItems) {
      this.deletedItems = [];
    }
    if (!this.modifiedItems) {
      this.modifiedItems = [];
    }

    if (this.field().resetSelectedItems)
      this.field()
        .resetSelectedItems.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.clearCurrentItems());

    this.formControl.valueChanges
      .pipe(
        startWith(this.formControl.value),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => this.setCurrentItems(value, false));
    if (this.field().refreshChipsDisplayTexts) {
      this.field()
        .refreshChipsDisplayTexts.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.changeDetector.markForCheck();
        });
    }
  }

  protected isNotAlreadyAssigned(item: BaseModel) {
    const identifier = item != null ? this.field().identifyBy(item) : null;
    return !this.currentItems()?.some(
      (i) => this.field().identifyBy(i) === identifier,
    );
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.searchFormControl = null;
  }

  get lookupFacade() {
    return this.field().lookupFacadeType
      ? (this._lookupFacade ??
          (this._lookupFacade = this.injector.get<StandardFacade<BaseModel>>(
            this.field().lookupFacadeType,
          )))
      : undefined;
  }

  selected(selectedOption: unknown): void {
    if (!selectedOption) return;
    this.setCurrentItems([
      ...this.currentItems(),
      this.convertLookupModelToChipModel(selectedOption),
    ]);
    this.clearInput();
    this.modifiedItems.push(this.field().identifyBy(selectedOption));
    this.removeFromDeleted(selectedOption);
  }

  remove(i: number): void {
    const items = this.currentItems();
    const itemRemoved = items[i];
    this.setCurrentItems([
      ...items.slice(0, i),
      ...items.slice(i + 1, items.length),
    ]);
    this.deletedItems.push(itemRemoved);
    this.removeFromModified(this.field().identifyBy(itemRemoved));
    this.formFieldControl().refreshRequired = true;
  }
  private removeFromDeleted(model: BaseModel) {
    const indexOfItem = this.deletedItems.indexOf(model);
    if (indexOfItem === -1) return;
    this.deletedItems.splice(indexOfItem, 1);
  }
  private removeFromModified(identifier: unknown) {
    const indexOfItem = this.modifiedItems.indexOf(identifier);
    if (indexOfItem === -1) return;
    this.modifiedItems.splice(indexOfItem, 1);
  }
  private clearCurrentItems() {
    for (let index = this.currentItems().length - 1; index >= 0; index--) {
      this.remove(index);
    }
  }
  private clearInput() {
    this.formFieldControl().input().nativeElement.value = null;
    this.formFieldControl()
      .input()
      .nativeElement.dispatchEvent(
        new Event('input', {
          bubbles: true,
          cancelable: true,
        }),
      );
  }
  protected displayModelData(chipModel: unknown): string {
    return this.formFieldControl()?.createDisplayTextLine1?.(
      this.convertChipModelToLookupModel(chipModel),
    );
  }

  private convertChipModelToLookupModel(chipModel: unknown): unknown {
    if (!this.field().modelMapping) {
      return chipModel;
    }
    const result = {};
    this.field().modelMapping?.forEach((mapping) => {
      result[mapping[0]] = chipModel[mapping[1]];
    });
    return result;
  }
  private convertLookupModelToChipModel(lookupModel: unknown): unknown {
    if (!this.field().modelMapping) {
      return lookupModel;
    }
    const result = {};
    this.field().modelMapping?.forEach((mapping) => {
      result[mapping[1]] = lookupModel[mapping[0]];
    });
    return result;
  }
}
