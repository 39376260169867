import { OverlayContainer } from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  computed,
  DestroyRef,
  Directive,
  effect,
  ElementRef,
  inject,
  Injector,
  isSignal,
  OnDestroy,
  OnInit,
  Provider,
  signal,
  untracked,
  viewChild,
  WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  EndpointConfigurationQuery,
  isSalaryError,
  SalaryError,
} from '@salary/common/api/base-http-service';
import { Permission } from '@salary/common/authorization';
import {
  ConfirmUnsaveChangesResult,
  ConfirmUnsavedChanges,
  DialogService,
  UnsavedChangesService,
} from '@salary/common/dialog';
import {
  BaseModel,
  DateTimeFormats,
  getFieldName,
  Guid,
  NameFunction,
  Validation,
} from '@salary/common/dumb';
import { LohnkontextFacade, SettingsFacade } from '@salary/common/facade';
import {
  FACADE,
  PagingInfo,
  QueryPagePayload,
  StandardFacade,
} from '@salary/common/standard-facade';
import {
  BoolListSubject,
  debounceSignal,
  isMacLikeSystem,
  mergeDeep,
  NOTIFICATION_SERVICE_TOKEN,
  PROCESS_MANAGER_SERVICE_TOKEN,
  ProcessDefinition,
  removeQueryParameter,
  stringifyEquals,
} from '@salary/common/utils';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pairwise,
  ReplaySubject,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { ImportConfig } from '../import';
import { BreadcrumbService } from '../layout';
import {
  ColumnDefinition,
  ColumnDefinitionDecoratorHandler,
  GridRefreshMode,
  ListComponent,
  RequestPageParams,
  RowSaveEventArgs,
} from '../list';
import { ListOutputService, PDFPrintService } from '../list-output';
import { ListPerspectivesService } from '../list-perspectives/list-perspectives-service';
import { PerspectivesToolbarComponent } from '../list-perspectives/perspectives-toolbar.component';
import {
  ColumnChooserService,
  ColumnDefitionChooserData,
} from '../list/column-chooser-dialog';
import { ColumnChooserData } from '../list/column-chooser-dialog/column-definition-chooser-data';
import { createColumnDefinitions } from '../list/column/column-creation.utils';
import { ListConfiguration, TitleOption } from '../list/utils';
import { DataExchangeService } from '../router-management';
import { RouteBackService } from '../router-management/route-back.service';
import { ToolbarDefinition } from '../utils/toolbar-definition';
import { EmptyStateLohnkontextProperty } from './list-empty-state.component';
import { ListImportDialogService } from './list-import-dialog/list-import-dialog.service';
import { WrappedList } from './utils';

export const rootListProviders: Provider[] = [
  ListPerspectivesService,
  PDFPrintService,
  ListOutputService,
];

@Directive()
export abstract class ListContainerComponent<T extends BaseModel>
  implements OnInit, OnDestroy, ConfirmUnsavedChanges, WrappedList<T>
{
  public static readonly LIST_CONTAINER_TEMPLATE = `
  <div style="height:100%; display:flex; flex-direction:column">
    <salary-html-text-banner [bannerConfiguration]="listConfiguration()?.onboardingTextKeys"/>
    @if (toolbarDefinitions().length) {
      <salary-toolbar
        data-testid="list-toolbar"
        [style.visibility]="toolbarVisible() ? 'visible' : 'hidden'"
        [toolbarDefinitions]="toolbarDefinitions()"
        [viewModeDeletedVisible]="papierkorbListViewMode()"
        [emptyStateVisible]="listEmpty()"
      />
    }
    <salary-list-empty-state-wrapper style="height:100%" [wrappedList]="this">
      <salary-list
        [rowModelType]="rowModelType()"
        data-testid="list"
        [newItemRow]="newItemRow()"
        [disableTabToNextCell] = "disableTabToNextCell()"
        (requestPage)="onRequestPage($event)"
        [(sizeColumnsToFitContent)]="sizeColumnsToFitContent"
        (loadingOverlayShown)="onLoadingOverlayShown($event)"
        (selectionChanged)="onSelectionChanged($event)"
        (focusedRowChanged)="onFocusedRowChanged($event)"
        (invisibleRowsSelected)="onInvisibleRowsSelected($event)"
        (saveRowData)="onSaveRowData($event)"
        [columnDefinitions]="columnDefinitions"
        [readyToLoad]="readyToLoad()"
        (validChanged)="isValid.set($event)"
        [requestConfirmation]="requestConfirmation()"
        [selectedRowOnSubsequentLoads]="selectedRowOnSubsequentLoads()"
        [selectedRowOnFirstLoad]="selectedRowOnFirstLoad()"
        [rowSelection]="rowSelection()"
        [fullRowEditing]="fullRowEditing()"
        [singleClickEdit]="singleClickEdit()"
        [getRowId]="getRowId()"
        [checkboxSelection]="checkboxSelection()"
        (editingStateChanged)="isEditing.set($event)"
        (rowEditingStarted)="rowEditingStarted$.next($event)"
        (columnStateChanged)="onColumnStateChanged($event)"
        [selectFirstRow]="selectFirstRow()"
        [(showLoadingOverlay)]="showLoadingOverlay"
        [disableColumnMoving]="listConfiguration()?.disableColumnMoving"
        [disableColumnSorting]="listConfiguration()?.disableColumnSorting"
        [validationSettings]="validationSettings"
        [selectableRowCallback]="listConfiguration()?.selectableRowCallback"          
        [suppressShowLoadingOverlay]="suppressShowLoadingOverlay()"
        [rowDoubleClickHandler]="listConfiguration()?.rowDoubleClickHandler"
        [modelCaptionPlural]="shortTitle()"
        [salaryListSortWithFallback]="pageRequestError()"
        [salaryListSortWithFallbackNotificationSource]="facade.notificationSource"
        [serverModePageSize]="serverModePageSize()"
        [treeNodeIsExpandable]="listConfiguration()?.tree?.isExpandable"
      />
   </salary-list-empty-state-wrapper>
  </div>`;
  static readonly COPY_TARGET_LIZENZNEHMER = 'CopyToLizenznehmer';
  static readonly COPY_TARGET_ABRECHNUNGSKREIS = 'CopyToAbrechnungskreis';
  protected lohnkontextFacade = inject(LohnkontextFacade);
  private elementRef = inject(ElementRef);
  private columnSizeSettingKey =
    this.elementRef.nativeElement.tagName.toLowerCase() + '_columnWidth';
  public salaryList = viewChild(ListComponent<T>);
  public facade = inject<StandardFacade<T>>(FACADE);
  public endpointConfigurationReady$ = new BoolListSubject(true);
  public loading$ = new BoolListSubject(false, 'OR');
  public pageRequestError = signal<SalaryError>(undefined);
  public refreshData$ = new Subject<GridRefreshMode>();
  public newItemRowVisibleByUser = signal(false);
  public listEmpty = signal(false);
  public listEmptySearch = computed(() => {
    const searchTerm = this.searchTerm();
    const pagingInfo = this.pagingInfo();
    return untracked(() => !!searchTerm && pagingInfo?.paging?.rowCount === 0);
  });
  private _listConfiguration = signal<ListConfiguration<T>>(undefined);
  public listConfiguration = this._listConfiguration.asReadonly();
  public loadingOverlayShown = signal(true);
  public papierkorbListViewMode = signal(false);
  public newItemRow = computed(
    () => !this.papierkorbListViewMode() && this.newItemRowVisibleByUser(),
  );
  public perspectiveKeySuffix: string = undefined;
  public alternativePerspectiveKey: string = undefined;
  public searchTerm = signal<string>(undefined);
  public rowModelType = signal<'clientSide' | 'serverSide'>('serverSide');
  public lastRequestPayload: QueryPagePayload;
  private lohnkontextState = computed(
    () => this.lohnkontextFacade.select.selectedLohnkontext(),
    { equal: stringifyEquals },
  );
  public listTitle = computed(() => {
    const titleConfig = this.listConfiguration()?.listTitle;
    if (typeof titleConfig === 'string') {
      return titleConfig;
    }
    if (isSignal(titleConfig)) {
      return titleConfig();
    }
    if (!this.facade) return undefined;
    let result = this.facade.pluralModelCaption;
    const lk = this.lohnkontextState();
    if (titleConfig == null || !lk) {
      return result;
    }
    if (lk.lizenznehmer && titleConfig === TitleOption.Lizenznehmer) {
      result += ` (${lk?.lizenznehmer?.nummer} - ${lk?.lizenznehmer?.bezeichnung})`;
    } else if (titleConfig === TitleOption.Year) {
      result += ` (${lk.abrechnungszeitraum?.toFormat(DateTimeFormats.YEAR)})`;
    } else if (titleConfig === TitleOption.MonthYear) {
      result += ` (${lk.abrechnungszeitraum?.toFormat(
        DateTimeFormats.MONTH_YEAR_LONG,
      )})`;
    }
    return result;
  });
  protected shortTitle = computed(() =>
    this.listTitle().replace(/\s*\([^)]*\)/, ''),
  );
  public disableBreadcrumbTitle = undefined;

  protected injector = inject(Injector);
  protected destroyRef = inject(DestroyRef);
  public endpointConfiguration: EndpointConfigurationQuery = {};
  protected disableTabToNextCell = computed(
    () => this.listConfiguration()?.disableTabToNextCell ?? false,
  );
  protected refreshModeForNextRefresh = GridRefreshMode.hideContent;
  protected showLoadingOverlay = signal<boolean>(true);
  protected settingsFacade = inject(SettingsFacade);
  protected sizeColumnsToFitContent = signal(
    this.settingsFacade.selectBenutzerSettingByKey<boolean>(
      this.columnSizeSettingKey,
    )()?.value ?? false,
  );
  protected pagingInfo = signal<{
    paging: PagingInfo;
    entities: T[];
  }>(undefined);
  selectedModelObjects = signal<T[]>([]);
  private selectedModelObjectsDebounced = debounceSignal(
    this.selectedModelObjects,
    1000,
  );
  protected noModelDataSelected = computed(
    () => this.selectedModelObjects().length === 0,
  );
  protected readyToLoad = signal(false);
  protected isEditing = signal(false);
  protected rowEditingStarted$ = new Subject<Partial<T>>();
  protected isValid = signal(true);
  protected toolbarVisible = signal(true);
  protected forcedQuerySortProperty: string = undefined;
  protected toolbarDefinitions = signal<ToolbarDefinition[]>([]);
  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);
  protected queryParams = toSignal(this.activatedRoute.queryParamMap);
  protected routeParams = toSignal(this.activatedRoute.paramMap);
  protected updateValid = signal(null);
  protected selectedRowOnFirstLoad = signal(-1);
  protected selectedRowOnSubsequentLoads = signal(-1);
  protected rowSelection = computed(
    () => this.listConfiguration()?.rowSelection ?? 'multiple',
  );
  protected checkboxSelection = computed(
    () => this.listConfiguration()?.checkboxSelection ?? false,
  );
  protected fullRowEditing = computed(
    () => this.listConfiguration()?.fullRowEditing ?? true,
  );
  protected singleClickEdit = computed(
    () => this.listConfiguration()?.singleClickEdit ?? false,
  );
  protected getRowId = computed(() => this.listConfiguration()?.getRowId);
  protected selectFirstRow = computed(() => {
    const config = this.listConfiguration()?.selectFirstRow;
    if (isSignal(config)) {
      return config();
    }
    return config ?? true;
  });
  protected suppressShowLoadingOverlay = signal(false);
  protected serverModePageSize = signal(50);
  protected listPerspectivesService = inject(ListPerspectivesService);
  protected routeBackService = inject(RouteBackService);
  protected breadcrumbService = inject(BreadcrumbService);
  protected dialogService = inject(DialogService);
  private dataExchangeService = inject(DataExchangeService);
  private listOutputService = inject(ListOutputService);
  private unsavedChangesService = inject(UnsavedChangesService);
  private cdRef = inject(ChangeDetectorRef);
  private listImportService = inject(ListImportDialogService);
  private processManagerService = inject(PROCESS_MANAGER_SERVICE_TOKEN);
  private validationEnabled = computed(() => {
    const papierkorbEnabled = this.papierkorbListViewMode();
    const validationSupport = this.listConfiguration()?.validationSupport?.();
    return untracked(() =>
      validationSupport == null
        ? undefined
        : validationSupport && !papierkorbEnabled,
    );
  });
  private deleteAndRestoreProcessIds$ = new Subject<string>();
  private validationResults = signal<Validation[]>(undefined);
  private validationLoading = signal<boolean>(false);
  private aktualisierenLoading = signal(false);
  private entfernenLoading = signal(false);
  private wiederherstellenLoading = signal(false);
  private titleService = inject(Title);
  private notificationService = inject(NOTIFICATION_SERVICE_TOKEN);
  private columnChooserService = inject(ColumnChooserService);
  private invisibleRowsSelected: boolean;
  private additionalColumns: ColumnDefinition<T>[];
  private _columnDefinitions: ColumnDefinition<T>[];
  private requestPage = new ReplaySubject<RequestPageParams<T>>(1);
  private overlayContainer = inject(OverlayContainer);
  set columnDefinitions(value: ColumnDefinition<T>[]) {
    this._columnDefinitions = value.map((colDef) => {
      return {
        ...colDef,
        visibility: colDef.visibility ?? true,
      };
    });
  }
  get columnDefinitions() {
    return this._columnDefinitions;
  }

  enabledRequestWaitForEndpointConfigurationReady(key = 'defaultKey') {
    this.endpointConfigurationReady$.nextValue(key, false);
  }

  setEndpointConfigurationReady(key = 'defaultKey') {
    this.endpointConfigurationReady$.nextValue(key, true);
  }

  private isEndpointConfigurationReady(): boolean {
    return this.endpointConfigurationReady$.value;
  }

  public refreshData(mode: GridRefreshMode) {
    this.refreshData$.next(mode);
    this.salaryList().refreshGrid(mode);
  }

  public readonly hinzufuegenVisible = signal(true);
  hinzufuegenToolbarDefinition: ToolbarDefinition = {
    title: 'Hinzufügen',
    buttonVisibility: this.hinzufuegenVisible,
    allowedByPermission: Permission.AllowAll,
    routerLink: 'hinzufuegen',
    actionHandler: () => {
      if (this.listConfiguration().newItemRow) {
        this.newItemRowVisibleByUser.set(true);
        return true;
      }
      return false;
    },
    visibleInEmptyState: true,
    visibleInViewMode: 'normal',
  };

  protected readonly aktualisierenToolbarDefinition: ToolbarDefinition = {
    title: 'Aktualisieren',
    actionHandler: () => {
      this.handleLoadingBasedOnHideOverlay(this.aktualisierenLoading);
      this.refresh();
      this.newItemRowVisibleByUser.set(false);
    },
    confirmation: this.requestConfirmation(),
    loading: this.aktualisierenLoading,
    visibleInEmptyState: true,
    componentWidth: 132,
    debounceTime: 250,
  };
  protected readonly filterVisible = signal(false);
  protected readonly filterButtonToolbarDefinition: ToolbarDefinition = {
    title: 'Filtern',
    actionType: 'filterButton',
    alwaysRoot: true,
    buttonVisibility: this.filterVisible,
    visibleInEmptyState: true,
  };

  protected readonly papierkorbVisible = signal(true);
  protected readonly papierkorbToolbarDefinition: ToolbarDefinition = {
    title: 'Papierkorb',
    actionHandler: (checked: boolean) => this.filterViewMode(checked),
    alignment: 'right',
    actionType: 'toggleButton',
    initialValue$: this.activatedRoute.queryParams.pipe(
      map((param) => !!param?.[this.getDeletedQueryParamName()]),
    ),
    confirmation: this.requestConfirmation(),
    buttonVisibility: this.papierkorbVisible,
    visibleInEmptyState: true,
  };

  protected readonly wiederherstellenVisible = signal(true);
  protected readonly wiederherstellenToolbarDefinition: ToolbarDefinition = {
    title: 'Wiederherstellen',
    actionHandler: () => {
      this.handleLoadingBasedOnHideOverlay(this.wiederherstellenLoading);
      this.selectRestoreButtonClicked();
    },
    buttonDisabled: this.noModelDataSelected,
    loading: this.wiederherstellenLoading,
    visibleInViewMode: 'deleted',
    allowedByPermission: Permission.AllowAll,
    buttonVisibility: this.wiederherstellenVisible,
    componentWidth: 201,
  };

  public readonly entfernenVisible = signal(true);
  protected readonly entfernenToolbarDefinition: ToolbarDefinition = {
    title: 'Entfernen',
    actionHandler: () => {
      this.handleLoadingBasedOnHideOverlay(this.entfernenLoading);
      this.selectDeleteButtonClicked();
    },
    buttonDisabled: computed(
      () =>
        this.noModelDataSelected() ||
        (this.facade.usageBeforeDeletionConfig != null &&
          this.selectedModelObjects().length > 1),
    ),
    tooltip: computed(() =>
      this.facade.usageBeforeDeletionConfig != null &&
      this.selectedModelObjects().length > 1
        ? `Entfernen von mehreren ${this.facade.pluralModelCaption} wegen Ausführung von Prüfungen ist nicht möglich.`
        : undefined,
    ),
    confirmation: () =>
      this.dialogService.confirmDeletion({
        fromPapierkorb: this.papierkorbListViewMode(),
        count: this.selectedModelObjects().length,
      }),
    loading: this.entfernenLoading,
    hotkey: signal(undefined),
    buttonVisibility: this.entfernenVisible,
    allowedByPermission: Permission.AllowAll,
  };

  protected readonly searchVisible = signal(true);
  protected readonly searchToolbarDefinition: ToolbarDefinition = {
    title: computed(() => `${this.shortTitle()} suchen...`),
    actionHandler: (text: string) => this.filterList(text),
    alignment: 'right',
    actionType: 'inputButton',
    confirmation: this.requestConfirmation(),
    buttonVisibility: this.searchVisible,
    initialValue$: this.activatedRoute.queryParams.pipe(
      map((params) => params?.[this.getSearchQueryParamName()]),
    ),
    alwaysRoot: true,
  };

  protected readonly copyToLizenznehmerVisible = signal(false);
  protected readonly copyToLizenznehmerToolbarDefinition: ToolbarDefinition = {
    title: 'Zu Lizenznehmer kopieren',
    actionHandler: () => this.onCopyToLizenznehmer(),
    visibleInViewMode: 'normal',
    confirmation: this.requestConfirmation(),
    buttonDisabled: this.noModelDataSelected,
    buttonVisibility: this.copyToLizenznehmerVisible,
    allowedByPermission: Permission.AllowAll,
  };

  protected readonly copyToAbrechnungskreisVisible$ = new BoolListSubject(
    false,
  );
  protected readonly copyToAbrechnungskreisToolbarDefinition: ToolbarDefinition =
    {
      title: 'Zu Abrechnungskreis kopieren',
      actionHandler: () => this.onCopyToAbrechnungskreis(),
      visibleInViewMode: 'normal',
      buttonDisabled: this.noModelDataSelected,
      confirmation: this.requestConfirmation(),
      buttonVisibility: toSignal(this.copyToAbrechnungskreisVisible$),
      allowedByPermission: Permission.AllowAll,
    };

  protected readonly columnChooserVisible = signal(true);
  protected readonly columnChooserToolbarDefinition: ToolbarDefinition = {
    title: 'Spaltenauswahl',
    actionHandler: () => this.customizeColumns(),
    visibleInViewMode: 'normal',
    confirmation: this.requestConfirmation(),
    buttonVisibility: this.columnChooserVisible,
  };

  protected readonly printVisible = signal(true);
  protected readonly printToolbarDefinition: ToolbarDefinition = {
    title: 'Drucken...',
    actionHandler: () => this.printButtonClicked(),
    buttonVisibility: this.printVisible,
  };

  protected readonly exportCSVVisible = signal(true);
  protected readonly exportCSVToolbarDefinition: ToolbarDefinition = {
    title: 'als CSV-Datei',
    actionHandler: () => this.exportCSVButtonClicked(),
    buttonVisibility: this.exportCSVVisible,
  };

  protected readonly exportVisible = signal(true);
  protected readonly exportToolbarDefinition: ToolbarDefinition = {
    title: 'Exportieren...',
    buttonVisibility: this.exportVisible,
    children: signal([this.exportCSVToolbarDefinition]),
  };

  protected readonly importVisible = signal(false);
  protected readonly importToolbarDefinition: ToolbarDefinition = {
    title: 'Importieren...',
    visibleInEmptyState: true,
    buttonVisibility: this.importVisible,
    children: signal([]),
  };

  protected readonly moreOptionsVisible = signal(true);
  protected readonly moreOptionsToolbarDefinition: ToolbarDefinition = {
    title: '...',
    visibleInEmptyState: true,
    alignment: 'left',
    actionType: 'moreOptionButton',
    children: signal([
      this.copyToLizenznehmerToolbarDefinition,
      this.copyToAbrechnungskreisToolbarDefinition,
      this.columnChooserToolbarDefinition,
      this.printToolbarDefinition,
      this.exportToolbarDefinition,
      this.importToolbarDefinition,
    ]),
    alwaysRoot: true,
    buttonVisibility: this.moreOptionsVisible,
  };

  protected readonly perspektivenVisible = signal(true);
  protected readonly perspektivenToolbarDefinition: ToolbarDefinition = {
    title: 'Perspektiven',
    actionType: 'customComponent',
    componentType: PerspectivesToolbarComponent,
    alignment: 'right',
    componentWidth: 217,
    buttonVisibility: this.perspektivenVisible,
  };

  protected onLoadingOverlayShown(shown: boolean) {
    this.loadingOverlayShown.set(shown);
  }

  protected validationSettings = {
    validationEnabled: this.validationEnabled,
    validationResults: this.validationResults,
    validationLoading: this.validationLoading,
  };

  constructor() {
    this.toolbarDefinitions.set([
      this.hinzufuegenToolbarDefinition,
      this.wiederherstellenToolbarDefinition,
      this.entfernenToolbarDefinition,
      this.aktualisierenToolbarDefinition,
      this.filterButtonToolbarDefinition,
      this.papierkorbToolbarDefinition,
      this.perspektivenToolbarDefinition,
      this.searchToolbarDefinition,
      this.moreOptionsToolbarDefinition,
    ]);
    let firstChange = true;
    effect(() => {
      const endpointConfiguration =
        this.listConfiguration()?.endpointConfiguration?.();
      untracked(() => {
        if (endpointConfiguration == null) {
          return;
        }
        mergeDeep(this.endpointConfiguration, endpointConfiguration);
        this.refresh();
      });
    });
    effect(() => {
      const size = this.sizeColumnsToFitContent();
      untracked(() => {
        if (firstChange) {
          firstChange = false;
          return;
        }
        this.settingsFacade.createOrUpdateUserSetting({
          key: this.columnSizeSettingKey,
          value: size,
        });
      });
    });
    effect(() => {
      if (this.salaryList()) {
        setTimeout(() => {
          this.entfernenToolbarDefinition.hotkey.set({
            keys: isMacLikeSystem() ? 'backspace' : 'delete',
            description: isMacLikeSystem()
              ? 'Entfernen (Backspace)'
              : 'Entfernen (Entf)',
            targetElement: this.salaryList().agGrid().nativeElement,
          });
        });
      }
    });
    effect(() => {
      const selectedModelObjects = this.selectedModelObjectsDebounced()?.length;
      untracked(() => {
        if (this.invisibleRowsSelected) {
          this.notificationService.show(
            `${selectedModelObjects} ${
              selectedModelObjects === 1 ? 'Satz' : 'Sätze'
            } ausgewählt`,
            { duration: 1000 },
          );
        }
      });
    });
    effect(() => {
      const isEditing = this.isEditing();
      const isValid = this.isValid();
      untracked(() => {
        this.lohnkontextFacade.setReadonly(isEditing || !isValid);
      });
    });
    effect(() => {
      const title = this.shortTitle();
      untracked(() => this.titleService.setTitle(`${title} - Baulohn`));
    });
  }

  ngOnInit(): void {
    this.setInitialFocusedRow();
    this.applyListConfiguration();
    this.registerPageChanged();

    this.activatedRoute?.queryParams
      ?.pipe(
        startWith({}),
        pairwise(),
        filter(([prevParams, currentParams]) => {
          const keysSet = new Set<string>([
            ...Object.keys(prevParams),
            ...Object.keys(currentParams),
          ]);
          const uniqueKeys = Array.from(keysSet);
          return uniqueKeys
            .filter((key) => key.endsWith(this.facade?.getIdentifier()))
            .some((key) => {
              return prevParams[key] !== currentParams[key];
            });
        }),
        map(([, currentParams]) => currentParams),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((currentParams) =>
        this.handleQueryParamsChange(currentParams),
      );
    this.initializeNewItemRowHandling();
    this.initializeValidationHandling();

    this.checkInitialSearchTerm();
    this.listPerspectivesService.registerRootListComponent(
      <ListContainerComponent<unknown>>this,
    );
    if (this.listConfiguration().perspectiveSupport !== false) {
      this.listPerspectivesService.loadInitialPerspective();
    }
    this.initializeDeleteRestoreSubscription();

    setTimeout(() => {
      if (
        this.disableBreadcrumbTitle == null &&
        this.overlayContainer
          .getContainerElement()
          .contains(this.elementRef.nativeElement)
      ) {
        this.disableBreadcrumbTitle = true;
      }
      if (!this.disableBreadcrumbTitle) {
        effect(
          () => {
            const title = this.listTitle();
            untracked(() => this.breadcrumbService.listTitle.set(title));
          },
          { injector: this.injector },
        );
      }
    });
  }

  private initializeNewItemRowHandling() {
    if (this.listConfiguration().newItemRow) {
      //It's a newItemRow view, now we have to decide it's visibility
      let prevValue = null;
      effect(
        () => {
          const newValue = this.newItemRow();
          untracked(() => {
            if (!newValue) {
              setTimeout(() => this.salaryList().updateValid());
            }
            if (prevValue && newValue) {
              setTimeout(() => this.focusFirstCellInNewItemRow());
            }
            prevValue = newValue;
          });
        },
        { injector: this.injector },
      );
    }
  }

  private initializeValidationHandling() {
    effect(
      () => {
        const validationEnabled = this.validationEnabled();
        untracked(() => {
          if (validationEnabled) {
            this.validateList();
          }
        });
      },
      { injector: this.injector },
    );
  }

  protected setEditorValue(
    fieldName: string | NameFunction<T>,
    value: undefined,
  ) {
    this.salaryList().setEditorValue(getFieldName(fieldName), value);
  }

  private applyListConfiguration() {
    this._listConfiguration.set(this.getListConfiguration());
    this.applyDefaultFilter(this.listConfiguration());
    if (this.listConfiguration().toolbarDefinitions) {
      this.toolbarDefinitions.set(this.listConfiguration().toolbarDefinitions);
    }
    this.initializeColumnDefinitions();
  }

  private applyDefaultFilter(listConfiguration: ListConfiguration) {
    if (listConfiguration.defaultFilter !== 'Lizenznehmer') {
      return;
    }
    this.lohnkontextFacade.select.selectedLohnkontext$
      .pipe(
        map((lk) => lk.lizenznehmer?.id),
        distinctUntilChanged(),
        map((lizenznehmerId) => ({
          queryParameters: { lizenznehmerId },
        })),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((queryparameters) => {
        mergeDeep(this.endpointConfiguration, queryparameters);
        this.refresh();
      });
  }

  private setInitialFocusedRow() {
    const focusedRow =
      this.activatedRoute.snapshot.queryParamMap.get('focusedRow');
    if (focusedRow) {
      this.selectedRowOnFirstLoad.set(Number(focusedRow));
      removeQueryParameter(this.router, 'focusedRow');
    }
  }

  private checkInitialSearchTerm() {
    if (this.dialogService.isAnyDialogOpened()) {
      return;
    }
    const initialSearchTerm =
      this.activatedRoute.snapshot.queryParams?.[
        this.getSearchQueryParamName()
      ];
    if (initialSearchTerm) {
      this.searchTerm.set(initialSearchTerm);
    }
  }

  private getSearchQueryParamName(): string {
    return 'search-' + this.facade?.getIdentifier();
  }

  private getDeletedQueryParamName(): string {
    return 'deleted-' + this.facade?.getIdentifier();
  }

  private handleQueryParamsChange(params: Params) {
    if (this.dialogService.isAnyDialogOpened()) {
      return;
    }

    this.searchTerm.set(params[this.getSearchQueryParamName()]);

    let columnDefinitionsChanged = false;
    if (
      params[this.getDeletedQueryParamName()] &&
      !this.papierkorbListViewMode()
    ) {
      this.papierkorbListViewMode.set(true);
      this.addDeletedColumnDefinition(this.columnDefinitions);
      columnDefinitionsChanged = true;
    }
    if (
      !params[this.getDeletedQueryParamName()] &&
      this.papierkorbListViewMode()
    ) {
      this.papierkorbListViewMode.set(false);
      if (this.columnDefinitions) {
        this.columnDefinitions = this.columnDefinitions.filter(
          (def) => def.columnTitle != 'Gelöscht am',
        );
        columnDefinitionsChanged = true;
      }
    }
    if (columnDefinitionsChanged) {
      this.salaryList().refreshColumnDefinitions(this.columnDefinitions);
    }
    this.refreshData(GridRefreshMode.hideContentLoadPageOne);
  }

  private addDeletedColumnDefinition(columnDefinitions: ColumnDefinition<T>[]) {
    const deletedOnColumnDefinition: ColumnDefinition<T> = {
      modelPropertyName: (d) => d.deletedOn,
      visibility: true,
      lockVisible: true,
    };
    this.applyPropertyDecoratorsToColumnDefinitions([
      deletedOnColumnDefinition,
    ]);
    columnDefinitions.push(deletedOnColumnDefinition);
  }

  validateList() {
    this.validationResults.set(undefined);
    this.endpointConfigurationReady$
      .pipe(
        filter((ready) => ready),
        take(1),
        tap(() => this.validationLoading.set(true)),
        switchMap(() =>
          this.facade.validateList({
            endpointConfiguration: this.endpointConfiguration,
          }),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (!isSalaryError(result)) {
          this.validationResults.set(result);
        }
        this.validationLoading.set(false);
      });
  }

  initializeColumnDefinitions() {
    this.columnDefinitions = this.getDefaultColumnDefinitions();
    this.additionalColumns = this.columnDefinitions.filter(
      (columnDefinition) =>
        columnDefinition.visibility != null &&
        columnDefinition.visibility !== true,
    );
  }

  getDefaultColumnDefinitions(): ColumnDefinition<T>[] {
    const columnDefinitions: ColumnDefinition<T>[] = [];
    const columnDefintionsCompressed = this.getColumnDefinitionsOfListConfig();
    columnDefintionsCompressed.forEach((colDef) =>
      columnDefinitions.push({ ...colDef }),
    );
    this.applyPropertyDecoratorsToColumnDefinitions(columnDefinitions);
    if (this.papierkorbListViewMode() === true) {
      this.addDeletedColumnDefinition(columnDefinitions);
    }
    return columnDefinitions;
  }

  private getColumnDefinitionsOfListConfig(): ColumnDefinition<T>[] {
    const result: ColumnDefinition<T>[] = [];
    for (const item of this.listConfiguration().columnDefinitions) {
      if (typeof item === 'string' || typeof item === 'function') {
        result.push({ modelPropertyName: item, visibility: false });
      } else {
        result.push(item);
      }
    }
    return result;
  }

  protected applyPropertyDecoratorsToColumnDefinitions(
    columnDefintions: ColumnDefinition<T>[],
  ) {
    new ColumnDefinitionDecoratorHandler(this.facade.modelClass).process(
      columnDefintions,
    );
  }

  protected onRequestPage(payload: RequestPageParams<T>) {
    this.requestPage.next(payload);
  }

  private registerPageChanged() {
    this.requestPage
      .pipe(
        mergeMap((queryPagePayload) => {
          const isChildrenQuery = queryPagePayload.parent != null;
          const loadingId = Guid.create();
          if (!isChildrenQuery) {
            this.loading$.nextValue(loadingId, true);
          }
          let endpointConfiguration = this.endpointConfiguration;
          if (this.forcedQuerySortProperty) {
            queryPagePayload.payload.endpointConfiguration.queryParameters.orderBy =
              this.forcedQuerySortProperty;
          }
          mergeDeep(
            endpointConfiguration,
            queryPagePayload.payload.endpointConfiguration,
            {
              deleted: this.papierkorbListViewMode() ? '/restorable' : '',
              queryParameters: { searchString: this.searchTerm() },
            },
          );
          if (isChildrenQuery) {
            endpointConfiguration = mergeDeep(
              {},
              endpointConfiguration,
              this.listConfiguration().tree.childrenEndpointConfiguration(
                queryPagePayload.parent,
              ),
              {
                queryParameters: { searchString: undefined },
              },
            );
          }
          const payload = {
            endpointConfiguration,
          };
          if (!isChildrenQuery) {
            this.routeBackService.updateLastQueryRequest(
              payload,
              this.facade.getIdentifier(),
            );
            this.lastRequestPayload = payload;
          }
          return this.queryPage(payload).pipe(
            map((result) => ({
              result,
              queryPagePayload,
              loadingId,
              isChildrenQuery,
            })),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(({ result, queryPagePayload, loadingId, isChildrenQuery }) => {
        if (isChildrenQuery) {
          if (isSalaryError(result)) {
            return;
          }
          queryPagePayload.callback(true, result.results, result.rowCount);
          return;
        }
        this.dataExchangeService.dataExchangeObject = result;
        this.loading$.removeKey(loadingId);
        if (isSalaryError(result)) {
          this.pageRequestError.set(result);
          queryPagePayload.callback(false);
          return;
        }
        this.pageRequestError.set(undefined);
        this.pagingInfo.set({
          entities: result.results,
          paging: result,
        });
        queryPagePayload.callback(true, result.results, result.rowCount);
        if (!this.listConfiguration().disableEmptyState) {
          const showListEmpty =
            result.rowCount === 0 &&
            result.currentPage === 1 &&
            this.searchTerm() == null;
          this.listEmpty.set(showListEmpty);
        }
      });
    this.endpointConfigurationReady$
      .pipe(
        filter((ready) => ready),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.readyToLoad.set(true));
  }

  protected queryPage(payload: QueryPagePayload) {
    return this.facade.queryPage(payload);
  }

  private initializeDeleteRestoreSubscription() {
    this.deleteAndRestoreProcessIds$
      .pipe(
        mergeMap((processId) =>
          this.processManagerService.processCompleted$.pipe(
            filter((process) => process.id === processId),
            take(1),
            filter((process) => !process.hasErrors()),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.deselectAll());
  }

  deselectAll() {
    this.salaryList().deselectAll();
  }

  ngOnDestroy() {
    if (!this.disableBreadcrumbTitle) {
      this.breadcrumbService.listTitle.set(undefined);
    }
  }

  protected abstract getListConfiguration(): ListConfiguration<T>;

  onSelectionChanged(selectedObjects: T[]) {
    this.selectedModelObjects.set(selectedObjects);
  }

  onInvisibleRowsSelected(invisibleRowSelected: boolean) {
    this.invisibleRowsSelected = invisibleRowSelected;
  }

  onFocusedRowChanged(focusedRow: number) {
    this.routeBackService.saveFocusedRow(focusedRow);
  }

  protected onSaveRowData(
    eventArgs: RowSaveEventArgs<T>,
    refreshAfterUpdate = eventArgs.new,
  ) {
    const data = eventArgs.data;
    this.customizeObjectBeforeSave(data);
    const payload = {
      item: data,
    };
    const notifier$ = eventArgs.new
      ? this.facade.create(payload).pipe(map((result) => result.succeeded))
      : this.facade.update(payload).pipe(map((result) => result.succeeded));
    notifier$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((succeeded) => {
        if (refreshAfterUpdate === succeeded) {
          this.refreshData(this.refreshModeForNextRefresh);
          this.refreshModeForNextRefresh = GridRefreshMode.hideContent;
        }
      });
  }

  protected customizeObjectBeforeSave(_objectToSave: T) {
    return;
  }

  selectDeleteButtonClicked() {
    this.refreshModeForNextRefresh = GridRefreshMode.keepContentVisible;
    this.onDeleteRequest(this.selectedModelObjects());
  }

  onDeleteRequest(modelObjectsToDelete: T[]) {
    this.showLoadingOverlay.set(true);
    if (!this.papierkorbListViewMode()) {
      this.softDeleteItems(modelObjectsToDelete);
    } else {
      this.permanentDeleteItems(modelObjectsToDelete);
    }
  }

  protected softDeleteItems(modelObjectsToDelete: T[]) {
    const processId = this.registerDeleteRestoreProcess({
      operationType: 'softDelete',
      itemCount: modelObjectsToDelete.length,
    });
    modelObjectsToDelete.forEach((modelObjectToDelete) =>
      this.facade.delete({
        item: modelObjectToDelete,
        processId,
      }),
    );
  }

  protected permanentDeleteItems(modelObjectsToDelete: T[]) {
    const processId = this.registerDeleteRestoreProcess({
      operationType: 'permanentDelete',
      itemCount: modelObjectsToDelete.length,
    });
    modelObjectsToDelete.forEach((modelObjectToDelete) =>
      this.facade.deleteRestorable({
        item: modelObjectToDelete,
        processId,
      }),
    );
  }

  private registerDeleteRestoreProcess(options: {
    operationType: 'softDelete' | 'permanentDelete' | 'restore';
    itemCount: number;
  }): string {
    const processId = Guid.create();
    let successSuffix = '';
    if (options.operationType === 'permanentDelete') {
      successSuffix = ' gelöscht.';
    } else if (options.operationType === 'softDelete') {
      successSuffix = ' in den Papierkorb verschoben.';
    } else {
      successSuffix = ' wiederhergestellt.';
    }
    const failMessage =
      options.operationType === 'restore'
        ? ProcessDefinition.RESTORE_FAILED_MESSAGE
        : ProcessDefinition.DELETE_FAILED_MESSAGE;
    const successMessage =
      (options.itemCount === 1
        ? 'Ein Element wurde'
        : `${options.itemCount} Elemente wurden`) + successSuffix;
    this.processManagerService.registerProcess(
      new ProcessDefinition(
        processId,
        options.itemCount,
        successMessage,
        failMessage,
        this.facade.notificationSource,
        undefined,
        true,
      ),
    );
    this.deleteAndRestoreProcessIds$.next(processId);
    this.processManagerService.processCompleted$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.refreshData(this.refreshModeForNextRefresh);
        this.refreshModeForNextRefresh = GridRefreshMode.hideContent;
      });
    return processId;
  }

  printButtonClicked() {
    this.listOutputService.print(this);
  }

  exportCSVButtonClicked() {
    this.listOutputService.export({
      componentToExport: this,
      format: 'CSV',
      fileName:
        this.listTitle() +
        (this.listPerspectivesService.activePerspectiveName()
          ? '-' + this.listPerspectivesService.activePerspectiveName()
          : ''),
    });
  }

  startImport(params: ImportConfig) {
    this.listImportService.startImport(params);
  }

  refresh() {
    if (this.isEndpointConfigurationReady()) {
      this.listEmpty.set(false);
      this.refreshData(GridRefreshMode.hideContentLoadPageOne);
      if (this.validationEnabled()) this.validateList();
    }
  }

  selectRestoreButtonClicked() {
    this.refreshModeForNextRefresh = GridRefreshMode.keepContentVisible;
    this.onRestoreRequest(this.selectedModelObjects());
  }

  onRestoreRequest(modelObjectsToRestore: T[]) {
    this.showLoadingOverlay.set(true);
    const processId = this.registerDeleteRestoreProcess({
      operationType: 'restore',
      itemCount: modelObjectsToRestore.length,
    });
    modelObjectsToRestore.forEach((modelObjectToRestore) =>
      this.facade.restore({ item: modelObjectToRestore, processId }),
    );
  }

  filterList(searchTerm: string) {
    if (this.dialogService.isAnyDialogOpened()) {
      this.searchTerm.set(searchTerm);
      this.refreshData(this.refreshModeForNextRefresh);
      return;
    }
    this.router.navigate([], {
      queryParams: {
        [this.getSearchQueryParamName()]: searchTerm === '' ? null : searchTerm,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  filterViewMode(papierkorbListViewMode: boolean) {
    this.router.navigate([], {
      queryParams: {
        [this.getDeletedQueryParamName()]: papierkorbListViewMode
          ? 'true'
          : null,
      },
      queryParamsHandling: 'merge',
    });
  }

  shouldConfirmUnsavedChanges(): ConfirmUnsaveChangesResult {
    this.salaryList()?.updateValid();
    if (!this.columnDefinitions?.some((column) => column.editable)) {
      return { shouldConfirm: false };
    }
    return { shouldConfirm: !this.salaryList()?.isValid() };
  }

  requestConfirmation() {
    return () => this.unsavedChangesService.confirmComponent(this);
  }

  customizeColumns() {
    const columnChooserData = this.getColumnChooserData();
    const subscription = this.columnChooserService
      .open<T>(columnChooserData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        subscription.unsubscribe();
        this.listPerspectivesService.updateActivePerspectivesColumns(
          this.columnDefinitions,
        );
      });
  }

  private getColumnChooserData(): ColumnChooserData<T> {
    const columns = this.columnDefinitions.map(
      (columnDefinition) =>
        ({
          column: columnDefinition,
          onVisibilityChange: (columnsData) => {
            this.columnDefinitions = columnsData.map((cd) => cd.column);
            this.cdRef.markForCheck();
          },
          fixed: !this.additionalColumns.some(
            (addCol) =>
              (addCol.modelPropertyName != null &&
                columnDefinition.modelPropertyName != null &&
                addCol.modelPropertyName ===
                  columnDefinition.modelPropertyName) ||
              addCol.columnTitle === columnDefinition.columnTitle,
          ),
        }) as ColumnDefitionChooserData<T>,
    );
    return {
      resetPerspective: () => {
        this.initializeColumnDefinitions();
        this.cdRef.markForCheck();
        return this.getColumnChooserData();
      },
      columns: columns,
      perspectiveName: this.listPerspectivesService.activePerspectiveName(),
    };
  }

  onColumnStateChanged(columnDefintions: ColumnDefinition<T>[]) {
    this.columnDefinitions = columnDefintions;
    if (this.listConfiguration().perspectiveSupport !== false) {
      this.listPerspectivesService.updateActivePerspectivesColumns(
        columnDefintions,
      );
    }
  }

  onCopyToLizenznehmer() {
    this.onCopy(ListContainerComponent.COPY_TARGET_LIZENZNEHMER);
  }

  onCopyToAbrechnungskreis() {
    this.onCopy(ListContainerComponent.COPY_TARGET_ABRECHNUNGSKREIS);
  }
  protected onCopy(target: string, targetId?: string) {
    if (!this.papierkorbListViewMode() && !this.noModelDataSelected()) {
      this.lohnkontextFacade.select.selectedLohnkontext$
        .pipe(
          take(1),
          tap(() => this.showLoadingOverlay.set(true)),
          map((lohnkontext) => {
            const targetIdToUse =
              targetId ||
              (target === ListContainerComponent.COPY_TARGET_ABRECHNUNGSKREIS
                ? lohnkontext.abrechnungskreis?.id
                : lohnkontext.lizenznehmer?.id);
            return this.facade.copy(
              this.selectedModelObjects(),
              targetIdToUse,
              target,
            );
          }),
          switchMap((processId) =>
            this.processManagerService.processCompleted$.pipe(
              filter((process) => process.id === processId),
              take(1),
            ),
          ),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => this.showLoadingOverlay.set(false));
    }
  }

  getEmptyStateLohnkontextProperty(): EmptyStateLohnkontextProperty {
    if (this.listConfiguration()?.defaultFilter === 'Lizenznehmer') {
      return 'lizenznehmer';
    }
    return 'abrechnungskreis';
  }

  public focusFirstCellInNewItemRow() {
    this.salaryList().focusFirstCellInNewItemRow();
  }
  private handleLoadingBasedOnHideOverlay(loading: WritableSignal<boolean>) {
    loading.set(true);
    const startInterval = () => {
      const interval = setInterval(() => {
        if (!this.loadingOverlayShown()) {
          loading.set(false);
          clearInterval(interval);
        }
      }, 300);
    };
    setTimeout(() => {
      if (!this.loadingOverlayShown()) {
        loading.set(false);
      } else {
        startInterval();
      }
    }, 200);
  }

  protected createColumnDefinitions(
    customColumns: (ColumnDefinition<T> | NameFunction<T>)[] = [],
    blackList: NameFunction<T>[] = [],
  ): ColumnDefinition<T>[] {
    return createColumnDefinitions(
      this.facade.modelClass,
      customColumns,
      blackList,
    );
  }
}
