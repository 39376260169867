import {
  computed,
  DestroyRef,
  effect,
  inject,
  Injectable,
  Injector,
  Signal,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  LohnkontextAbrechnungskreiseQueryService,
  LohnkontextMandantenQueryService,
} from '@salary/common/api/data-services';
import {
  Abrechnungskreis,
  BaseModel,
  capitalizeFirstLetter,
  Guid,
  Lizenznehmer,
  Mandant,
} from '@salary/common/dumb';
import {
  createCopy,
  createSubsetCopy,
  dateEquals,
  filterNil,
  idEquals,
} from '@salary/common/utils';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
} from 'rxjs';
import { LohnkontextFacade } from '../lohnkontext';
import { Lohnkontext } from '../lohnkontext/models/lohnkontext.model';
import { LohnkontextQueryParams } from './utils';

export interface LohnkontextPropertyMappingModel {
  abrechnungskreisIdProperty?: string;
  mandantIdProperty?: string;
  lizenznehmerIdProperty?: string;
}

@Injectable()
export class SubLohnkontextFacade {
  private readonly state = signal<Lohnkontext>(undefined);
  private readonly alternativeLizenznehmerSource =
    signal<Lizenznehmer>(undefined);
  private lohnkontextFacade = inject(LohnkontextFacade);
  private mandantenService = inject(LohnkontextMandantenQueryService);
  private abrechnungskreiseService = inject(
    LohnkontextAbrechnungskreiseQueryService,
  );
  private readonly destroyRef = inject(DestroyRef);
  private readonly injector = inject(Injector);

  //#region Selectors
  readonly lohnkontext = computed(() =>
    !this.state()?.lizenznehmer && this.alternativeLizenznehmerSource()
      ? { ...this.state(), lizenznehmer: this.alternativeLizenznehmerSource() }
      : this.state(),
  );
  readonly lizenznehmer = computed(() => this.lohnkontext()?.lizenznehmer, {
    equal: idEquals,
  });
  readonly abrechnungskreis = computed(
    () => this.lohnkontext()?.abrechnungskreis,
    { equal: idEquals },
  );
  readonly abrechnungszeitraum = computed(
    () => this.lohnkontext()?.abrechnungszeitraum,
    { equal: dateEquals },
  );
  readonly lohnkontext$ = toObservable(this.lohnkontext).pipe(filterNil());
  readonly isAbrechnungskreisInvalid = computed(
    () => !this.lohnkontext()?.abrechnungskreis?.id,
  );
  readonly isMandantInvalid = computed(() => !this.lohnkontext()?.mandant?.id);
  readonly isLizenznehmerInvalid = computed(
    () => !this.lohnkontext()?.lizenznehmer?.id,
  );
  /** returns a single queryparameter of the first available most discriminating one */
  getQueryParam<
    QUERYPARAM extends keyof Omit<LohnkontextQueryParams, 'mergeByLohnkontext'>,
  >(
    ...queryParams: QUERYPARAM[]
  ): Signal<Omit<Partial<LohnkontextQueryParams>, 'mergeByLohnkontext'>> {
    return computed(() => {
      const availableParams = this.getQueryParams(...queryParams)();
      if (availableParams['abrechnungskreisId']) {
        return {
          abrechnungskreisId: availableParams['abrechnungskreisId'] as string,
        };
      } else if (availableParams['beschaeftigungsbetriebId']) {
        return {
          beschaeftigungsbetriebId: availableParams[
            'beschaeftigungsbetriebId'
          ] as string,
        };
      } else if (availableParams['mandantId']) {
        return { mandantId: availableParams['mandantId'] as string };
      } else if (availableParams['lizenznehmerId']) {
        return { lizenznehmerId: availableParams['lizenznehmerId'] as string };
      }
      return {};
    });
  }
  getQueryParams<QUERYPARAM extends keyof LohnkontextQueryParams>(
    ...queryParams: QUERYPARAM[]
  ): Signal<Pick<LohnkontextQueryParams, QUERYPARAM>> {
    return computed(() => {
      const state = this.lohnkontext();
      return !state
        ? ({} as Pick<LohnkontextQueryParams, QUERYPARAM>)
        : untracked(() =>
            queryParams.reduce(
              (result, queryParam) => {
                switch (queryParam) {
                  case 'abrechnungskreisId':
                    if (state.abrechnungskreis?.id) {
                      result['abrechnungskreisId'] = state.abrechnungskreis.id;
                    }
                    break;
                  case 'lizenznehmerId':
                    if (state.lizenznehmer?.id) {
                      result['lizenznehmerId'] = state.lizenznehmer.id;
                    }
                    break;
                  case 'mandantId':
                    if (state.mandant?.id) {
                      result['mandantId'] = state.mandant.id;
                    }
                    break;
                  case 'beschaeftigungsbetriebId':
                    if (
                      state.abrechnungskreis?.arbeitsamt
                        ?.beschaeftigungsbetriebId
                    ) {
                      result['beschaeftigungsbetriebId'] =
                        state.abrechnungskreis.arbeitsamt.beschaeftigungsbetriebId;
                    }
                    break;
                  case 'mergeByLohnkontext':
                    result['mergeByLohnkontext'] = true;
                    break;
                }
                return result;
              },
              {} as Pick<LohnkontextQueryParams, QUERYPARAM>,
            ),
          );
    });
  }
  dsmQueryParams = this.getQueryParams(
    'lizenznehmerId',
    'abrechnungskreisId',
    'mergeByLohnkontext',
  );
  kostenstellenQueryParams = this.getQueryParam('mandantId', 'lizenznehmerId');

  personalQueryParams = this.getQueryParam(
    'abrechnungskreisId',
    'lizenznehmerId',
  );
  //#endregion

  //** alternativeLizenznehmerSource is used if lizenznehmer is empty in model */
  initialize(
    model: Signal<BaseModel>,
    customPropertyMappings?: LohnkontextPropertyMappingModel,
    alternativeLizenznehmerSource?: Signal<Lizenznehmer>,
  ) {
    const propertyMappings = Object.assign(
      ['abrechnungskreis', 'mandant', 'lizenznehmer'].reduce(
        (result, defaultMapping) => {
          result[defaultMapping + 'IdProperty'] = defaultMapping + 'Id';
          return result;
        },
        {},
      ),
      customPropertyMappings ?? {},
    );
    if (alternativeLizenznehmerSource) {
      effect(
        () => {
          const value = alternativeLizenznehmerSource();
          untracked(() => this.alternativeLizenznehmerSource.set(value));
        },
        { injector: this.injector, forceRoot: true },
      );
    }
    toObservable(model, { injector: this.injector })
      .pipe(
        map((model) => createCopy(model)),
        filter((model) => model && Object.keys(model).length > 0),
        SubLohnkontextFacade.distinctUntilPropertiesChanged(
          Object.values(propertyMappings) as (keyof BaseModel)[],
        ),
        map((model) => {
          if (propertyMappings.abrechnungskreisIdProperty) {
            const abrechnungskreis = SubLohnkontextFacade.constructObjectFrom(
              model,
              propertyMappings.abrechnungskreisIdProperty,
            );
            if (abrechnungskreis) {
              return { abrechnungskreis };
            }
          }
          if (propertyMappings.mandantIdProperty) {
            const mandant = SubLohnkontextFacade.constructObjectFrom(
              model,
              propertyMappings.mandantIdProperty,
            );
            if (mandant) {
              return { mandant };
            }
          }
          if (propertyMappings.lizenznehmerIdProperty) {
            const lizenznehmer = SubLohnkontextFacade.constructObjectFrom(
              model,
              propertyMappings.lizenznehmerIdProperty,
            );
            if (lizenznehmer) {
              return { lizenznehmer };
            }
          }
          return {};
        }),
        switchMap((lohnkontextToSet) =>
          this.lohnkontextFacade.select.selectedLohnkontext$.pipe(
            switchMap((rootLohnkontext) =>
              this.getAbrechnungskreis(
                lohnkontextToSet.abrechnungskreis,
                rootLohnkontext,
              ).pipe(
                switchMap((abrechnungskreis) =>
                  this.getMandant(
                    lohnkontextToSet.mandant ??
                      SubLohnkontextFacade.extractMandant(abrechnungskreis),
                    rootLohnkontext,
                  ).pipe(
                    map((mandant) => ({
                      abrechnungskreis,
                      mandant,
                      lizenznehmer:
                        lohnkontextToSet.lizenznehmer ??
                        SubLohnkontextFacade.extractLizenznehmer(mandant),
                      abrechnungszeitraum: rootLohnkontext.abrechnungszeitraum,
                    })),
                  ),
                ),
              ),
            ),
          ),
        ),
        catchError(() => of(false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (typeof result === 'boolean') {
          this.reset();
        } else {
          this.setLohnkontext(result);
        }
      });
  }

  private getAbrechnungskreis(
    abrechnungskreis: Abrechnungskreis,
    rootLohnkontext: Lohnkontext,
  ): Observable<Abrechnungskreis> {
    if (
      !abrechnungskreis ||
      (abrechnungskreis.id && !Guid.isGuid(abrechnungskreis.id))
    )
      return of(undefined);
    if (!SubLohnkontextFacade.needToFindAbrechnungskreis(abrechnungskreis)) {
      return of(abrechnungskreis);
    }
    if (abrechnungskreis?.id === rootLohnkontext.abrechnungskreis?.id) {
      return of(rootLohnkontext.abrechnungskreis);
    }
    return this.abrechnungskreiseService.getById({ id: abrechnungskreis.id });
  }

  private getMandant(
    mandant: Mandant,
    rootLohnkontext: Lohnkontext,
  ): Observable<Mandant> {
    if (!mandant || (mandant.id && !Guid.isGuid(mandant.id)))
      return of(undefined);
    if (!SubLohnkontextFacade.needToFindMandant(mandant)) {
      return of(mandant);
    }
    if (mandant?.id === rootLohnkontext.mandant?.id) {
      return of(rootLohnkontext.mandant);
    }
    return this.mandantenService.getById({ id: mandant.id });
  }

  private static extractMandant(abrechnungskreis: Abrechnungskreis): Mandant {
    return abrechnungskreis?.mandantId
      ? {
          id: abrechnungskreis.mandantId,
          nummer: abrechnungskreis.mandantNummer,
          bezeichnung: abrechnungskreis.mandantBezeichnung,
          deletedOn: abrechnungskreis.mandantDeletedOn,
        }
      : undefined;
  }

  private static extractLizenznehmer(mandant: Mandant): Lizenznehmer {
    return mandant?.lizenznehmerId
      ? {
          id: mandant.lizenznehmerId,
          nummer: mandant.lizenznehmerNummer,
          bezeichnung: mandant.lizenznehmerBezeichnung,
          deletedOn: mandant.lizenznehmerDeletedOn,
        }
      : undefined;
  }

  private static needToFindAbrechnungskreis(
    abrechnungskreis: Abrechnungskreis,
  ) {
    return abrechnungskreis.id && !abrechnungskreis.mandantId;
  }

  private static needToFindMandant(mandant: Mandant) {
    return mandant.id && !mandant.lizenznehmerId;
  }

  private reset() {
    this.state.set({
      abrechnungskreis: null,
      mandant: null,
      lizenznehmer: null,
    });
  }
  private setLohnkontext(lohnkontext: Lohnkontext) {
    this.state.set({
      abrechnungskreis: lohnkontext.abrechnungskreis,
      mandant: lohnkontext.mandant,
      lizenznehmer: lohnkontext.lizenznehmer,
      abrechnungszeitraum: lohnkontext.abrechnungszeitraum,
    });
  }

  private static constructObjectFrom(
    sourceObject: unknown,
    idPropertyName: string,
  ) {
    if (idPropertyName === 'id') return sourceObject;
    const idOrObject = sourceObject[idPropertyName];
    if (!idOrObject) return undefined;
    if (!Guid.isGuid(idOrObject))
      return typeof idOrObject === 'string' ? undefined : idOrObject;
    const result = { id: idOrObject };
    const propertyNameWithoutSuffix = idPropertyName.slice(
      0,
      idPropertyName.length - 2,
    );
    ['deletedOn', 'nummer', 'bezeichnung'].forEach((propertyName) => {
      const propertyValue =
        sourceObject?.[
          propertyNameWithoutSuffix + capitalizeFirstLetter(propertyName)
        ];
      if (propertyValue) {
        result[propertyName] = propertyValue;
      }
    });
    if (Object.hasOwn(result, 'deletedOn')) {
      return undefined;
    }
    return result;
  }

  private static distinctUntilPropertiesChanged<T, K extends keyof T>(
    properties: K[],
  ): OperatorFunction<T, T> {
    return (input$) =>
      input$.pipe(
        distinctUntilChanged(
          (prevValue, newValue) =>
            JSON.stringify(createSubsetCopy(prevValue, ...properties)) ===
            JSON.stringify(createSubsetCopy(newValue, ...properties)),
        ),
      );
  }
}
