import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { FieldType, assignFieldValue } from '@salary/common/formly';
import { SelectableChipListFieldConfig } from './selectable-chip-list-field-config';

@Component({
    selector: 'salary-form-field-chip-list-select',
    template: `
    <mat-chip-listbox
      #chipListBox
      [formControl]="formControl"
      [salaryFormlyAttributes]="field()"
      [required]="('required' | toSignal: field())()"
      [attr.data-testid]="field().testId | convertSpecialCharacter"
      selectable
      [multiple]="field().multiple"
    >
      @for (item of field().selectOptions; track item.label) {
        <mat-chip-option
          #matChip
          [value]="item.value"
          [attr.data-testid]="
            field().testId + '_chip(' + item.value + ')'
              | convertSpecialCharacter
          "
          [selectable]="canDeselectChip(matChip, chipListBox)"
        >
          {{ item.label }}
        </mat-chip-option>
      }
    </mat-chip-listbox>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelectableChipListTypeComponent extends FieldType<SelectableChipListFieldConfig> {
  static readonly defaultOptions: SelectableChipListFieldConfig = {
    selectOptions: [],
    multiple: true,
    allowNothingSelected: true,
  };

  ngAfterViewInit() {
    this.formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: unknown) => {
        if (this.field().modelValueConverter) {
          const convertedValue = this.field().modelValueConverter(value);
          assignFieldValue(this.field(), convertedValue);
        }
      });
  }

  protected canDeselectChip(option: MatChipOption, listBox: MatChipListbox) {
    if (!option.selected) {
      return true;
    }
    if (!this.field().multiple) {
      return this.field().allowNothingSelected;
    }
    return (
      listBox._chips.filter((option) => option.selected).length > 1 ||
      this.field().allowNothingSelected
    );
  }
}
