import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  computed,
  inject,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import {
  APP_LONG_RUNNING_PROCESS_SERVICE_TOKEN,
  LongRunningProcessDefinition,
  LongRunningProcessStatus,
} from '@salary/common/utils';
import { AppLongRunningProcessDialogComponent } from '../app-long-running-process-dialog';

@Component({
  selector: 'salary-app-long-running-process-toolbar-item',
  templateUrl: './app-long-running-process-toolbar-item.component.html',
  styleUrl: './app-long-running-process-toolbar-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppLongRunningProcessToolbarItemComponent {
  private dialog = inject(MatDialog);
  private longRunningProcessService = inject(
    APP_LONG_RUNNING_PROCESS_SERVICE_TOKEN,
  );
  protected badgeInfo = computed(() => {
    const longRunningProcesses =
      this.longRunningProcessService.longRunningProcesses();
    return untracked(() => ({
      color: this.getBadgeColor(longRunningProcesses),
      text: this.getBadgeText(longRunningProcesses),
    }));
  });
  private destroyRef = inject(DestroyRef);
  protected readonly BADGE_ICON = 'check';

  private getBadgeColor(
    longRunningProcesses: LongRunningProcessDefinition[],
  ): 'primary' | 'warn' {
    if (
      longRunningProcesses.some(
        (i) => i.status() === LongRunningProcessStatus.InProcess,
      )
    ) {
      return undefined;
    }
    if (
      longRunningProcesses.some(
        (i) => i.status() === LongRunningProcessStatus.Failed,
      )
    ) {
      return 'warn';
    }
    return 'primary';
  }

  private getBadgeText(longRunningProcesses: LongRunningProcessDefinition[]) {
    if (
      longRunningProcesses.some(
        (i) => i.status() !== LongRunningProcessStatus.Succeeded,
      )
    ) {
      return longRunningProcesses.length.toString();
    }
    return this.BADGE_ICON;
  }

  protected openAppLongRunningProcessDialog() {
    this.dialog
      .open(AppLongRunningProcessDialogComponent, {
        data: this.longRunningProcessService.longRunningProcesses(),
        autoFocus: false,
      })
      .updatePosition({ top: '60px', right: '10px' })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.longRunningProcessService.longRunningProcesses.update((list) =>
          list.filter(
            (item) => item.status() !== LongRunningProcessStatus.Succeeded,
          ),
        );
      });
  }
}
