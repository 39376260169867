import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCheckbox } from '@angular/material/checkbox';
import { FieldType } from '@salary/common/formly';
import { CheckboxFieldConfig } from './checkbox-field-config';

/** template copied from https://github.com/ngx-formly/ngx-formly/blob/main/src/ui/material/checkbox/src/checkbox.type.ts
 * because of https://github.com/ngx-formly/ngx-formly/issues/3558
 * - form field with accent color, we only want checkbox in accent color
 * - focus lost not triggered, so form field is always outlined as if field hast focus
 * - keybord tab key should highlight form field too not only focus by mouse click
 */
@Component({
    selector: 'salary-checkbox-type',
    template: ` <mat-checkbox
    [attr.disabled]="('disabled' | toSignal: field())() ? '' : null"
    [formControl]="formControl"
    [salaryFormlyAttributes]="field()"
    [indeterminate]="formControl.value == null"
    [attr.data-testid]="field()?.testId"
  >
    {{ ('label' | toSignal: field())() }}
    @if (('required' | toSignal: field())()) {
      <span aria-hidden="true" class="mat-form-field-required-marker">*</span>
    }
  </mat-checkbox>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CheckboxTypeComponent extends FieldType<CheckboxFieldConfig> {
  private _required!: boolean;
  renderer = inject(Renderer2);
  focusMonitor = inject(FocusMonitor);
  @ViewChild(MatCheckbox, { static: true }) checkbox!: MatCheckbox;
  public static readonly defaultOptions: CheckboxFieldConfig = {
    defaultValue: false,
    floatLabel: 'always' as const,
    hideLabel: true,
  };

  override onContainerClick(event: MouseEvent): void {
    this.checkbox.focus();
    super.onContainerClick(event);
  }

  ngAfterViewInit() {
    if (this.checkbox) {
      this.focusMonitor
        .monitor(this.checkbox._inputElement, true)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((focusOrigin) => {
          this.field().focus = !!focusOrigin;
          this.stateChanges.next();
          // if (focusOrigin) {
          //   this.props.focus && this.props.focus(this.field);
          // } else {
          //   this.props.blur && this.props.blur(this.field);
          // }
          // this.field.focus = !!focusOrigin;
          this.stateChanges.next();
        });
    }
  }

  ngAfterViewChecked() {
    if (
      this.required !== this._required &&
      this.checkbox &&
      this.checkbox._inputElement
    ) {
      this._required = this.required;
      const inputElement = this.checkbox._inputElement.nativeElement;
      if (this.required) {
        this.renderer.setAttribute(inputElement, 'required', 'required');
      } else {
        this.renderer.removeAttribute(inputElement, 'required');
      }
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.checkbox) {
      this.focusMonitor.stopMonitoring(this.checkbox._inputElement);
    }
  }
}
