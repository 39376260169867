import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  LongRunningProcessDefinition,
  LongRunningProcessStatus,
} from '@salary/common/utils';
@Component({
    selector: 'salary-long-running-process-dialog',
    templateUrl: 'long-running-process.dialog.html',
    styleUrl: 'long-running-process.dialog.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppLongRunningProcessDialogComponent {
  protected longRunningProcessStatus = LongRunningProcessStatus;
  public longRunningProcessItems =
    inject<LongRunningProcessDefinition[]>(MAT_DIALOG_DATA);
}
