import { signal, Signal, WritableSignal } from '@angular/core';

export interface IAppLongRunningProcessService {
  longRunningProcesses: WritableSignal<LongRunningProcessDefinition[]>;
  buttonVisible: Signal<boolean>;
  addLongRunningProcess(resultItem: LongRunningProcessDefinition): void;
  longRunningResultChanged(): void;
}

export class LongRunningProcessDefinition {
  status = signal(LongRunningProcessStatus.InProcess);
  errorMessage: string;
  processDate?: string;
  file?: File;
  processName: string;
}

export enum LongRunningProcessStatus {
  Succeeded = 0,
  InProcess = 1,
  Failed = 2,
}
