import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { AuthenticationService } from '@salary/common/authentication';

@Component({
    selector: 'salary-long-loading-information',
    template: `<div [class.dark-mode]="darkMode()">
      Das Starten der Anwendung dauert länger als gewöhnlich...
    </div>
    <button
      mat-raised-button
      (click)="relogin()"
      [style.background-color]="accentColor()"
    >
      Neu anmelden
    </button>`,
    styles: `
    .dark-mode {
      color: white;
    }
    :host {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      margin-top: 125px;
      text-align: center;
      animation: animatetop 1s;
    }
    @keyframes animatetop {
      from {
        margin-top: 90px;
        opacity: 0;
      }
      to {
        margin-top: 125px;
        opacity: 1;
      }
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LongLoadingInformationComponent {
  darkMode = input(false);
  accentColor = input.required<string>();
  private readonly authenticationService = inject(AuthenticationService);
  protected relogin() {
    this.authenticationService.logout();
  }
}
