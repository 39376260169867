import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  dateField,
  DateTimeFormats,
  enumField,
  euroField,
  fieldId,
  textField,
} from '../../../decorators';
import {
  AbweichendeErstattungEfzAngestellteNach,
  BeitragsgruppeAv,
  BeitragsgruppeKv,
  BeitragsgruppePv,
  BeitragsgruppeRv,
  DeuevStatuskennzeichen,
  HoechsterAllgemeinbildenderSchulabschluss,
  HoechsterberuflicherAusbildungsabschluss,
  InsolvenzKennzeichnung,
  RentnerKennzeichen,
  RvKennzeichen,
  Seemannskasse,
  VersicherungsartKv,
  Vertragsform,
} from '../personal.enum';

export class PersonalSozialversicherung {
  @booleanField()
  @caption('Umlage 1')
  @fieldId('758a6b')
  umlage1?: boolean;
  @booleanField()
  @caption('Umlage 2')
  @fieldId('9447a8')
  umlage2?: boolean;
  @booleanField()
  @fieldId('b44088')
  insolvenzgeldumlage?: boolean;
  @enumField(BeitragsgruppeKv)
  @caption('Beitragsgruppe KV')
  @fieldId('bb2aeb')
  beitragsgruppeKv?: BeitragsgruppeKv;
  @enumField(BeitragsgruppeRv)
  @caption('Beitragsgruppe RV')
  @fieldId('3ade58')
  beitragsgruppeRv?: BeitragsgruppeRv;
  @enumField(BeitragsgruppeAv)
  @caption('Beitragsgruppe AV')
  @fieldId('2d1775')
  beitragsgruppeAv?: BeitragsgruppeAv;
  @enumField(BeitragsgruppePv)
  @caption('Beitragsgruppe PV')
  @fieldId('cd2485')
  beitragsgruppePv?: BeitragsgruppePv;
  @enumField(RvKennzeichen)
  @caption('RV Kennzeichen')
  @fieldId('c6c984')
  rvKennzeichen?: RvKennzeichen;
  @enumField(VersicherungsartKv)
  @caption('Versicherungsart KV')
  @fieldId('42ba1c')
  versicherungsartKv?: VersicherungsartKv;
  @euroField({ min: 0, max: 9999.99 })
  @caption('Fremdverdienst Minijob (Mindestbeitrag RV)')
  @fieldId('e60578')
  fremdverdienstBeiMinijob?: number;
  @enumField(DeuevStatuskennzeichen)
  @caption('DEÜV Statuskennzeichen')
  @fieldId('7c78c1')
  deuevStatuskennzeichen?: DeuevStatuskennzeichen;
  @enumField(HoechsterAllgemeinbildenderSchulabschluss)
  @caption('Höchster allgemeinbildender Schulabschluss')
  @fieldId('8a183c')
  hoechsterAllgemeinbildenderSchulabschluss?: HoechsterAllgemeinbildenderSchulabschluss;
  @enumField(HoechsterberuflicherAusbildungsabschluss)
  @caption('Höchster beruflicher Ausbildungsabschluss')
  @fieldId('7c31c9')
  hoechsterberuflicherAusbildungsabschluss?: HoechsterberuflicherAusbildungsabschluss;
  @booleanField()
  @caption('Arbeitnehmerüberlassung')
  @fieldId('0a76c5')
  arbeitnehmerueberlassung?: boolean;
  @enumField(Vertragsform)
  @fieldId('249c98')
  vertragsform?: Vertragsform;
  @enumField(RentnerKennzeichen)
  @caption('Rentner - Kennzeichen')
  @fieldId('b7e8f5')
  rentnerKennzeichen?: RentnerKennzeichen;
  @enumField(Seemannskasse)
  @fieldId('f6eb2a')
  seemannskasse?: Seemannskasse;
  @booleanField()
  @caption('Lohnfortzahlung ab Eintritt')
  @fieldId('0cfb3a')
  lohnfortzahlungAbEintritt?: boolean;
  @booleanField()
  @caption('Bundesfreiwilligendienst erhöhte AV')
  @fieldId('0c47d7')
  bundesfreiwilligendienstErhoehteAv?: boolean;
  @booleanField()
  @fieldId('18aaa2')
  bundesfreiwilligendienst?: boolean;
  @booleanField()
  @caption('Durchschnittlichen KV Zusatzbeitrag berechnen')
  @fieldId('b588b5')
  durchschnittlichenKvZusatzbeitragBerechnen?: boolean;
  @booleanField()
  @caption('Bundesknappschaft erhöhter RV Beitrag')
  @fieldId('308021')
  bundesknappschaftErhoehterRvBeitrag?: boolean;
  @booleanField()
  @caption('Beschäftigungsförderung')
  @fieldId('db4404')
  beschaeftigungsfoerderung?: boolean;
  @booleanField()
  @caption('Versicherungspflicht als Nichtversicherter')
  @fieldId('b14836')
  versicherungspflichtAlsNichtversicherter?: boolean;
  @textField({
    format:
      '{personengruppenschluessel} - {personengruppenschluesselPersonenkreis}',
  })
  @caption('Personengruppe')
  @fieldId('bf1bfd')
  personengruppenschluessel?: number;
  personengruppenschluesselPersonenkreis?: string;
  @caption('Ausgeübte Tätigkeit')
  @fieldId('88edb7')
  kldb2010?: string;
  @fieldId('049e50')
  kldb2010Nummer?: string;
  @textField({
    format: '{krankenkasseBN} - {krankenkasseBNBezeichnung}',
    routerLink: '/masterdata/krankenkassen/{krankenkasseBNId}',
  })
  @caption('Krankenkasse')
  @fieldId('05b17d')
  krankenkasseBNId?: string;
  @fieldId('cb5287')
  krankenkasseBN?: string;
  krankenkasseBNBezeichnung?: string;
  @fieldId('3cd04e')
  krankenkasseAbrechnungskreisNummer?: string;
  @fieldId('71d075')
  krankenkasseAbrechnungskreisBezeichnung?: string;
  @fieldId('4b34a0')
  krankenkasseLizenznehmerNummer?: string;
  @fieldId('ac2227')
  krankenkasseLizenznehmerBezeichnung?: string;
  @fieldId('MECLZa')
  krankenkasseBeschaeftigungsbetriebNummer?: string;
  @booleanField()
  @caption(
    'Erstellung Arbeitsbescheinigung aufgrund anstehender Aussteuerung ohne Beschäftigungsende',
  )
  @fieldId('b8d5c2')
  erstellungArbeitsbescheinigungAufgrundAnstehenderAussteuerungOhneBeschaeftigungsende?: boolean;
  @booleanField()
  @fieldId('97513f')
  berechnungMidijob?: boolean;
  @dateField(DateTimeFormats.DATE)
  @caption('Beginn der Schwangerschaft')
  @fieldId('9c5159')
  beginnDerSchwangerschaft?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @caption('Mutmaßlicher / Tatsächlicher Entbindungstermin')
  @fieldId('bed602')
  mutmasslicherEntbindungstermin?: DateTime;
  @euroField({ min: 0, max: 99999.99 })
  @caption('Zuschuss MUT – monatl. Bruttoarbeitsentgelt')
  @fieldId('0a32b4')
  zuschussMUTMonatlBruttoarbeitsentgelt?: number;
  @euroField({ min: 0, max: 99999.99 })
  @caption('Zuschuss MUT – monatl. Nettoarbeitsentgelt')
  @fieldId('ff6c00')
  zuschussMUTMonatlNettoarbeitsentgelt?: number;
  @euroField({ min: 0, max: 99999.99 })
  @caption('Zuschuss MUT – kalendertägl. Nettoarbeitsentgelt')
  @fieldId('6f1849')
  zuschussMUTKalendertaeglNettoarbeitsentgelt?: number;
  @euroField({ min: 0, max: 99999.99 })
  @caption('Zuschuss kalendertägl. Mutterschaftszuschuss')
  @fieldId('460c03')
  zuschussKalendertaeglMutterschaftszuschuss?: number;
  @fieldId('nPXRvr')
  @dateField(DateTimeFormats.DATE)
  @caption('Beginn der Schutzfrist')
  beginnDerSchutzfrist?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @caption('Ende der Schutzfrist')
  @fieldId('235901')
  endeDerSchutzfrist?: DateTime;
  @enumField(AbweichendeErstattungEfzAngestellteNach)
  @caption('Abweichende Erstattung EFZ Angestellte nach')
  @fieldId('3c1ed7')
  abweichendeErstattungEfzAngestellteNach?: AbweichendeErstattungEfzAngestellteNach;
  @booleanField()
  @caption('Praktikant ohne Entgelt')
  @fieldId('e90f0c')
  praktikantOhneEntgelt?: boolean;
  @textField({
    format:
      '{abweichendeUmlageKrankenkasseBN} - {abweichendeUmlageKrankenkasseBNBezeichnung}',
    routerLink: '/masterdata/krankenkassen/{abweichendeUmlageKrankenkasseBNId}',
  })
  @caption('Abweichende Umlage-Krankenkasse')
  @fieldId('MyT2Mt')
  abweichendeUmlageKrankenkasseBNId?: string;
  abweichendeUmlageKrankenkasseBN?: string;
  abweichendeUmlageKrankenkasseBNBezeichnung?: string;
  @fieldId('uoQhOn')
  abweichendeUmlageKrankenkasseAbrechnungskreisNummer?: string;
  @fieldId('88YqBI')
  abweichendeUmlageKrankenkasseAbrechnungskreisBezeichnung?: string;
  @fieldId('jBAXWp')
  abweichendeUmlageKrankenkasseLizenznehmerNummer?: string;
  @fieldId('asbS50')
  abweichendeUmlageKrankenkasseLizenznehmerBezeichnung?: string;
  @fieldId('2ZFApM')
  abweichendeUmlageKrankenkasseBeschaeftigungsbetriebNummer?: string;
  @booleanField()
  @fieldId('575f73')
  saisonarbeitnehmer?: boolean;
  @booleanField()
  @fieldId('ZozKNX')
  @caption('eAU Nachweispflicht ab 1.Tag krank')
  eauNachweispflichtAb1TagKrank?: boolean;
  @fieldId('4S6pD9')
  @textField({
    format:
      '{gesetzlicheKrankenkasseBeiMinijobBN} - {gesetzlicheKrankenkasseBeiMinijobBNBezeichnung}',
    routerLink:
      '/masterdata/krankenkassen/{gesetzlicheKrankenkasseBeiMinijobBNId}',
  })
  @caption('Gesetzliche Krankenkasse bei Minijob')
  gesetzlicheKrankenkasseBeiMinijobBNId?: string;
  gesetzlicheKrankenkasseBeiMinijobBN?: string;
  gesetzlicheKrankenkasseBeiMinijobBNBezeichnung?: string;
  gesetzlicheKrankenkasseBeiMinijobBNAbrechnungskreisNummer?: string;
  gesetzlicheKrankenkasseBeiMinijobBNAbrechnungskreisBezeichnung?: string;
  gesetzlicheKrankenkasseBeiMinijobBNLizenznehmerNummer?: string;
  gesetzlicheKrankenkasseBeiMinijobBNLizenznehmerBezeichnung?: string;
  gesetzlicheKrankenkasseBeiMinijobBNBeschaeftigungsbetriebNummer?: string;
  @fieldId('wTVolQ')
  @booleanField()
  @caption('Freistellungsphase bei PKV- / freiwillig Vers. GKV')
  freistellungsphaseBeiPkvFreiwilligVersGkv?: boolean;
  @enumField(InsolvenzKennzeichnung)
  @caption('Insolvenz - Kennzeichnung')
  @fieldId('1b1e7d')
  insolvenzKennzeichnung?: InsolvenzKennzeichnung;
}
