import {
  AfterViewInit,
  DestroyRef,
  Directive,
  HostBinding,
  HostListener,
  OnDestroy,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';

// from https://github.com/angular/components/blob/master/src/material/autocomplete/autocomplete-trigger.ts
@Directive({
    selector: `[salaryMaskedAutocomplete]`,
    providers: [MatAutocompleteTrigger],
    standalone: false
})
export class MaskedAutocompleteDirective implements OnDestroy, AfterViewInit {
  private destroyRef = inject(DestroyRef);
  private ngControl = inject(NgControl);
  private autocompleteDirective = inject(MatAutocompleteTrigger);

  @HostBinding('class.salary-masked-autocomplete') _compClass = true;
  @HostBinding('class.mat-autocomplete-trigger') _autocompleteClass = true;
  @HostBinding('attr.autocomplete') _autocompleteAttr =
    this.autocompleteDirective.autocompleteAttribute;
  @HostBinding('attr.role') _role = this.autocompleteDirective
    .autocompleteDisabled
    ? null
    : 'combobox';
  autocomplete = input.required<MatAutocomplete>({
    alias: 'salaryMaskedAutocomplete',
  });

  constructor() {
    effect(() => {
      if (this.autocomplete()) {
        untracked(() => {
          this.autocompleteDirective.autocomplete = this.autocomplete();
          this.subscribeToAutoCompleteEvents();
        });
      }
    });
  }
  @HostListener('focusin')
  public onFocusin(): void {
    this.autocompleteDirective._handleFocus();
  }
  @HostListener('blur')
  public onBlur(): void {
    this.autocompleteDirective._onTouched();
  }
  @HostListener('input', ['$event'])
  public onInput(e: KeyboardEvent): void {
    this.autocompleteDirective._handleInput(e);
  }
  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
    this.autocompleteDirective._handleKeydown(e);
  }

  ngAfterViewInit() {
    this.autocompleteDirective.ngAfterViewInit();
  }
  ngOnDestroy() {
    this.autocompleteDirective.ngOnDestroy();
  }

  private subscribeToAutoCompleteEvents() {
    this.autocomplete()
      .optionSelected.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        this.ngControl.valueAccessor.writeValue(event.option.value);
        this.ngControl.valueAccessor['onChange'](event.option.value);
      });
  }
}
