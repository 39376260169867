<div
  data-testid="app-long-running-process-dialog"
  mat-dialog-title
  class="colorAccent"
>
  Prozesse
</div>
<mat-divider />
<mat-dialog-content>
  <mat-list data-testid="app-long-running-process-dialog-list">
    @for (processItem of longRunningProcessItems; track processItem.processName
    + processItem.processDate) {
    <mat-list-item data-testid="current-user-dialog-app-settings">
      @if (processItem.status() === longRunningProcessStatus.InProcess) {
      <mat-spinner
        style="padding-top: 0px; padding-bottom: 0px"
        matListItemIcon
        diameter="24"
      />
      } @if (processItem.status() === longRunningProcessStatus.Succeeded) {
      <mat-icon matListItemIcon>check_circle</mat-icon>
      } @if (processItem.status() === longRunningProcessStatus.Failed) {
      <mat-icon
        matListItemIcon
        [matTooltip]="processItem.errorMessage"
        matTooltipPosition="above"
      >
        error_outline
      </mat-icon>
      }
      <div matListItemTitle>{{processItem.processName}}</div>
      @if (processItem.status() !== longRunningProcessStatus.InProcess) {
      <div matListItemLine>{{ processItem.processDate }}</div>
      }
    </mat-list-item>
    }
  </mat-list>
</mat-dialog-content>
