import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  Renderer2,
  Signal,
  TemplateRef,
  computed,
  effect,
  inject,
  isSignal,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatCalendarCellClassFunction,
  MatCalendarView,
  MatDatepicker,
} from '@angular/material/datepicker';
import { DateTimeFormats } from '@salary/common/dumb';
import { LohnkontextFacade } from '@salary/common/facade';
import { FieldConfig, FieldType } from '@salary/common/formly';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import {
  LohnkontextAwareDateTimeDateAdapter,
  LohnkontextAwareDateTimeDateAdapterOptions,
} from '../../../utils/lohnkontext-aware-luxon-date-adapter';
import { DatepickerFieldConfig } from './datepicker-field-config';

const datePickerFormatFactory = (self: SalaryDatepickerTypeComponent) => {
  return {
    parse: {
      get dateInput(): string {
        return self.field()?.dateFormat ?? DateTimeFormats.DATE;
      },
    },
    display: {
      get dateInput(): string {
        return self.field()?.dateFormat ?? DateTimeFormats.DATE;
      },
      monthYearLabel: 'MMM yyyy',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: DateTimeFormats.MONTH_YEAR_LONG,
    },
  };
};
const datePickerOptionsFactory = (
  self: SalaryDatepickerTypeComponent,
): LohnkontextAwareDateTimeDateAdapterOptions => {
  return {
    useUtc: false,
    firstDayOfWeek: 1,
    defaultOutputCalendar: 'gregory',
    get startAt() {
      return self.datepicker()?.startAt;
    },
  };
};
@Component({
  selector: 'salary-datepicker',
  template: `
    <input
      matInput
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [matDatepicker]="picker"
      [matDatepickerFilter]="dateFilterFn()"
      [max]="field()?.max ?? (dateMax$ | async)"
      [min]="field()?.min ?? (dateMin$ | async)"
      [salaryFormlyAttributes]="field()"
      [attr.data-testid]="field()?.testId"
      [required]="('required' | toSignal: field())()"
      [placeholder]="('placeholder' | toSignal: field())()"
      autocomplete="off"
      (dateInput)="field()?.dateInput?.(field(), $event)"
      (dateChange)="onDateChange($event)"
    />
    <ng-template #datepickerToggle>
      @if (
        (!('readonly' | toSignal: field())() &&
          !('disabled' | toSignal: field())()) ||
        field()?.alwaysShowToggle
      ) {
        <mat-datepicker-toggle
          [for]="picker"
          [attr.data-testid]="
            (field()?.testId ?? 'form_' + field().key?.toString()
              | convertSpecialCharacter) + '-datepickertoggle'
          "
          tabindex="-1"
        >
          <mat-icon matDatepickerToggleIcon>today</mat-icon>
        </mat-datepicker-toggle>
      }
    </ng-template>
    <mat-datepicker
      #picker
      [panelClass]="field()?.datepickerOverlayClass"
      [startAt]="
        value
          ? value
          : ('startAt' | toSignal: field())()
            ? ('startAt' | toSignal: field())()
            : lohnkontext.select.abrechnungszeitraum()
      "
      [startView]="startView()"
      [calendarHeaderComponent]="field()?.calendarHeaderComponent"
      [dateClass]="dateClass()"
      (opened)="opened()"
      (monthSelected)="chosenMonthHandler($event, picker)"
      (yearSelected)="chosenYearHandler($event, picker)"
    />
  `,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: DateAdapter,
      useClass: LohnkontextAwareDateTimeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: datePickerFormatFactory,
      deps: [SalaryDatepickerTypeComponent],
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useFactory: datePickerOptionsFactory,
      deps: [SalaryDatepickerTypeComponent],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SalaryDatepickerTypeComponent
  extends FieldType<DatepickerFieldConfig>
  implements AfterViewInit, OnChanges
{
  private datepickerToggle = viewChild('datepickerToggle', {
    read: TemplateRef,
  });
  protected startView = signal<MatCalendarView>('month');
  datepicker = viewChild<MatDatepicker<DateTime>>('picker');
  protected dateClass = computed(() => {
    const value = this.field()?.dateClass;
    if (isSignal(value)) {
      return (
        value as Signal<MatCalendarCellClassFunction<DateTime<boolean>>>
      )();
    }
    return value;
  });
  private minDateRelatedFields$ = new BehaviorSubject<FieldConfig[]>(undefined);
  private maxDateRelatedFields$ = new BehaviorSubject<FieldConfig[]>(undefined);
  protected dateMin$: Observable<DateTime>;
  protected dateMax$: Observable<DateTime>;

  protected lohnkontext = inject(LohnkontextFacade);
  private changeDetector = inject(ChangeDetectorRef);
  private renderer = inject(Renderer2);
  protected dateFilterFn = computed(() => this.field()?.filter?.(this.field()));

  constructor() {
    super();
    effect(() => {
      const disabled = this.field().disabled;
      if (isSignal(disabled)) {
        disabled();
        untracked(() => this.changeDetector.markForCheck());
      }
    });
    effect(() => this.field()?.suffix.set(this.datepickerToggle()));
  }

  ngAfterViewInit() {
    if (!this.shouldShowYearView()) {
      this.startView.set('multi-year');
    } else if (!this.shouldShowMonthView()) {
      this.startView.set('year');
    }

    this.initializeMinMaxRestriction();
  }

  ngOnChanges() {
    this.updateRangeValidation();
  }

  protected opened() {
    //disable period-button that enables you to switch to other views
    if (!this.shouldShowYearView() || !this.shouldShowMonthView()) {
      setTimeout(() => {
        const result = document.querySelector(
          '.mat-calendar-period-button',
        ) as HTMLButtonElement;
        if (result == null) {
          return;
        }
        result.style.pointerEvents = 'none';
        result.disabled = true;
      });
    }
  }

  private shouldShowYearView() {
    return (
      !this.field()?.dateFormat || this.field()?.dateFormat.indexOf('M') !== -1
    );
  }

  private shouldShowMonthView() {
    return (
      !this.field()?.dateFormat || this.field()?.dateFormat.indexOf('d') !== -1
    );
  }

  protected onDateChange(event) {
    this.change(event);
  }

  protected chosenYearHandler(
    normalizedYear: DateTime,
    datepicker: MatDatepicker<DateTime>,
  ) {
    if (this.shouldShowMonthView()) return;
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.ngControl.setValue(normalizedYear);
    this.change();
    if (!this.shouldShowYearView()) {
      this.hidePopupImmediately();
      setTimeout(() => datepicker.close());
    }
  }
  protected chosenMonthHandler(
    normalizedMonth: DateTime,
    datepicker: MatDatepicker<DateTime>,
  ) {
    if (this.shouldShowMonthView()) return;
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.ngControl.setValue(normalizedMonth);
    this.change();
    this.hidePopupImmediately();
    setTimeout(() => datepicker.close());
  }

  private change(event: unknown = null) {
    this.updateRangeValidationFields();
    this.field().dateChange?.(this.field(), event);
  }

  /**Hides Popup before it is closed. Prevents flickerings of next calendar-view */
  private hidePopupImmediately() {
    const result = this.renderer.selectRootElement(
      '.mat-datepicker-popup',
      true,
    );
    if (result == null) {
      return;
    }
    this.renderer.setStyle(result, 'visibility', 'hidden');
  }

  private initializeMinMaxRestriction() {
    this.dateMin$ = this.createMinMaxRestrictionObservable(
      this.minDateRelatedFields$,
      'max',
    );
    this.dateMax$ = this.createMinMaxRestrictionObservable(
      this.maxDateRelatedFields$,
      'min',
    );
    this.updateRangeValidation();
  }

  private createMinMaxRestrictionObservable(
    fieldNameSource: Observable<FieldConfig[]>,
    compareFn: 'min' | 'max',
  ) {
    return fieldNameSource.pipe(
      distinctUntilChanged((previous, next) => {
        if (previous === next) {
          return true;
        }
        if (
          (previous == null && next != null) ||
          (previous != null && next == null)
        ) {
          return false;
        }
        return (
          JSON.stringify(previous.map((f) => f.key)) ===
          JSON.stringify(next.map((f) => f.key))
        );
      }),
      switchMap((fields) =>
        fields
          ? combineLatest(
              fields.map((f) =>
                f.formControl.valueChanges.pipe(startWith(f.formControl.value)),
              ),
            )
          : of([]),
      ),
      map((values) => values.filter((value) => value != null)),
      map((values) => {
        if (values.length === 0) {
          return undefined;
        }
        return compareFn === 'min'
          ? DateTime.min(...values)
          : DateTime.max(...values);
      }),
    );
  }

  private updateRangeValidation() {
    this.maxDateRelatedFields$.next(
      this.getFields(this.field()?.rangeEndDateProperty),
    );
    this.minDateRelatedFields$.next(
      this.getFields(this.field()?.rangeStartDateProperty),
    );
    this.updateRangeValidationFields();
    this.updateRangeValidationMessage();
  }

  private updateRangeValidationFields() {
    const propertyToUpdate =
      this.field()?.rangeStartDateProperty ??
      this.field()?.rangeEndDateProperty;
    if (propertyToUpdate) {
      this.getFields(propertyToUpdate)?.forEach((f) =>
        f.formControl?.updateValueAndValidity(),
      );
    }
  }

  private getFields(fieldNames: string | string[]) {
    if (!fieldNames) return undefined;
    const names = typeof fieldNames === 'string' ? [fieldNames] : fieldNames;
    return names.map((name) => this.form.get(name)['_fields']?.[0]);
  }

  private updateRangeValidationMessage() {
    if (
      this.field()?.rangeStartDateProperty ||
      this.field()?.rangeEndDateProperty
    ) {
      if (!this.field().validation) {
        this.field().validation = {};
      }
      if (!this.field().validation.messages) {
        this.field().validation.messages = {};
      }
      const message = this.getRangeValidationMessage();
      this.field().validation.messages['matDatepickerMin'] = message;
      this.field().validation.messages['matDatepickerMax'] = message;
    }
  }

  private getRangeValidationMessage() {
    const formatLabels = (labels: FieldConfig[]) =>
      labels.map((l) => `'${l.label}'`).join(' und ');
    if (this.field()?.rangeValidationMessage) {
      return this.field().rangeValidationMessage;
    }
    const startProperties = this.field()?.rangeEndDateProperty
      ? [this.field()]
      : this.getFields(this.field()?.rangeStartDateProperty);
    const endProperties = this.field()?.rangeStartDateProperty
      ? [this.field()]
      : this.getFields(this.field()?.rangeEndDateProperty);
    return `${formatLabels(startProperties)} muss vor ${formatLabels(
      endProperties,
    )} liegen`;
  }
}
