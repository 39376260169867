import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Directive,
  OnInit,
  Provider,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import {
  NavigationExtras,
  NavigationStart,
  PRIMARY_OUTLET,
} from '@angular/router';
import { isSalaryError } from '@salary/common/api/base-http-service';
import { Permission } from '@salary/common/authorization';
import {
  ConfirmUnsaveChangesResult,
  ConfirmUnsavedChanges,
  DialogService,
  UnsavedChangesService,
} from '@salary/common/dialog';
import {
  BaseModel,
  DateTimeFormats,
  Guid,
  getFieldName,
} from '@salary/common/dumb';
import { FieldConfig, convertToSignal } from '@salary/common/formly';
import { CommandResult, StandardFacade } from '@salary/common/standard-facade';
import { BoolListSubject } from '@salary/common/utils';
import { DateTime } from 'luxon';
import { Observable, Subject, filter, map, startWith, take } from 'rxjs';
import { ActiveTabPageService } from '../active-tab-page-tracking';
import { DetailSearchComponent } from '../detail-search/detail-search.component';
import { DetailSearchService } from '../detail-search/detail-search.service';
import { StripHtmlPipe } from '../pipes';
import { SubNavigationValidationService } from '../sub-navigation';
import { AnimationService, ToolbarDefinition } from '../utils';
import { PrintableComponent } from '../utils/printable-component.interface';
import { ComponentSublinksInteractionService } from './component-sublinks-interaction.service';
import { DetailBaseContainerComponent } from './detail-base-container.component';
import { RecordNavigationComponent } from './utils';
import { ComponentOperation } from './utils/component-operation';
import { EntityType } from './utils/detail-base-configuration';
import { DetailSingleBaseComponent } from './utils/detail-single-base-component';
import { DetailSingleConfiguration } from './utils/detail-single-configuration';
import { FocusFieldService } from './utils/focus-field.service';
import { ProgressIndicationService } from './utils/progress-indication.service';
import { RecordNavigationService } from './utils/record-navigation-service';
import { ValidationService } from './utils/validation-service';

export const rootDetailProviders: Provider[] = [
  ComponentSublinksInteractionService,
  RecordNavigationService,
  DetailSearchService,
  FocusFieldService,
  ValidationService,
  ProgressIndicationService,
  ActiveTabPageService,
  SubNavigationValidationService,
];

@Directive()
export abstract class DetailSingleContainerComponent<
    T extends BaseModel = unknown,
    FACADETYPE extends StandardFacade<T> = StandardFacade<T>,
  >
  extends DetailBaseContainerComponent<T, FACADETYPE>
  implements
    DetailSingleBaseComponent<T>,
    ConfirmUnsavedChanges,
    RecordNavigationComponent,
    PrintableComponent,
    OnInit
{
  declare componentConfiguration: DetailSingleConfiguration<T>;
  private aktualisierenVisible = signal(true);
  protected entfernenVisible$ = new BoolListSubject(true);
  private wiederherstellenVisible = signal(true);
  private unsavedChangesService = inject(UnsavedChangesService);
  protected progressIndicationService = inject(ProgressIndicationService);
  protected isProgressActive = this.progressIndicationService.isProgressActive;
  protected isLoadingProgressActive =
    this.progressIndicationService.isProgressActiveByOperationType('Loading');
  protected validationService = inject(ValidationService);
  public needToUpdateTitle = signal<void>(undefined, { equal: () => false });
  protected navigationService = inject(RecordNavigationService, {
    optional: true,
  });
  protected formTitle = computed(() => {
    this.needToUpdateTitle();
    const model = this.model();
    return untracked(() => this.getFormTitle(model));
  });
  protected subTitle = computed(() => {
    this.needToUpdateTitle();
    return untracked(() => this.getSubTitle());
  });
  public printTitle = computed(() => {
    const formTitle = this.formTitle();
    return untracked(() =>
      this.facade.singularModelCaption != null
        ? `${this.facade.singularModelCaption} - ${formTitle}`
        : formTitle,
    );
  });
  private titleService = inject(Title);
  private dialogService = inject(DialogService);
  protected animationService = inject(AnimationService);
  protected focusMonitor = inject(FocusMonitor);
  private loadAfterSaveOperation = false;
  private saveButtonDisabled = signal(false);
  readonly speichernToolbarDefinition: ToolbarDefinition = {
    title: 'Speichern',
    actionHandler: () => {
      this.onSave();
    },
    buttonDisabled: this.saveButtonDisabled,
    buttonVisibility: this.speichernVisible,
    actionType: 'splitButton',
    hotkey: signal({
      keys: 'control.s',
      description: 'Speichern (Strg + S)',
    }),
    alignment: 'right',
    alwaysRoot: true,
    badgeText: this.validationService.clientErrorCount,
    badgeClickHandler: () => this.validationService.toggleValidationPanel(),
    children: signal([
      {
        title: 'Speichern & Neu',
        actionHandler: () => this.onSaveAndNew(),
        buttonDisabled: this.saveButtonDisabled,
        buttonVisibility: this.speichernVisible,
        allowedByPermission: Permission.AllowAll,
      },
      {
        title: 'Speichern & Schließen',
        actionHandler: () => this.onSaveAndClose(),
        buttonDisabled: this.saveButtonDisabled,
        buttonVisibility: this.speichernVisible,
      },
    ]),
    tooltip: computed(() =>
      +this.validationService.clientErrorCount() > 0
        ? `Es existieren Validierungsfehler die das Speichern verhindern.`
        : undefined,
    ),
  };
  protected readonly searchToolbarDefinition: ToolbarDefinition = {
    title: 'Search',
    componentWidth: 202,
    alignment: 'right',
    actionType: 'customComponent',
    removable: true,
    componentType: DetailSearchComponent,
  };

  protected druckenVisible = signal(true);
  protected readonly moreOptionsToolbarDefinition: ToolbarDefinition = {
    title: '...',
    actionType: 'moreOptionButton',
    children: signal([
      {
        title: 'Drucken',
        actionHandler: () =>
          this.detailOutputService.execute(this, this.injector),
        buttonVisibility: this.druckenVisible,
      },
    ]),
    alignment: 'right',
  };

  private readonly firstRecordToolbarDefinition: ToolbarDefinition = {
    title: 'Erster Datensatz',
    actionHandler: () => this.navigationService?.navigateFirst(),
    buttonCSSClass: 'no-margin',
    iconName: 'skip_previous',
    alignment: 'right',
  };

  private readonly previousRecordToolbarDefinition: ToolbarDefinition = {
    title: 'Vorheriger Datensatz',
    actionHandler: () => this.navigationService?.navigatePrevious(),
    buttonCSSClass: 'no-margin',
    iconName: 'navigate_before',
    alignment: 'right',
  };

  private readonly nextRecordToolbarDefinition: ToolbarDefinition = {
    title: 'Nächster Datensatz',
    actionHandler: () => this.navigationService?.navigateNext(),
    buttonCSSClass: 'no-margin',
    iconName: 'navigate_next',
    alignment: 'right',
  };

  private readonly lastRecordToolbarDefinition: ToolbarDefinition = {
    title: 'Letzter Datensatz',
    actionHandler: () => this.navigationService?.navigateLast(),
    buttonCSSClass: 'no-margin',
    iconName: 'skip_next',
    alignment: 'right',
  };

  protected readonly closeToolbarDefinition: ToolbarDefinition = {
    title: 'Schließen',
    iconName: 'close',
    actionHandler: () => this.onCloseButtonClick(),
    alwaysRoot: true,
    alignment: 'right',
  };

  override ngOnInit(): void {
    super.ngOnInit();
    if (!this.facade) {
      return;
    }
    if (this.entityType === EntityType.AggregateRoot) {
      this.validationService.registerComponent(this);
      this.navigationService?.setComponent(this);
      effect(
        () => {
          const disabled =
            this.componentInteractionService.getSaveButtonDisabled(this)();
          untracked(() => this.saveButtonDisabled.set(disabled));
        },
        { injector: this.injector },
      );
      this.initializeRecordToolbarDefinitionObservables();
      this.actionToolbarDefinitions.push(
        this.speichernToolbarDefinition,
        this.firstRecordToolbarDefinition,
        this.previousRecordToolbarDefinition,
        this.nextRecordToolbarDefinition,
        this.lastRecordToolbarDefinition,
        this.searchToolbarDefinition,
        this.moreOptionsToolbarDefinition,
        this.closeToolbarDefinition,
      );
    }

    if (this.entityType === EntityType.AggregateRoot) {
      this.initializationsForAggregateRoot();
    }
    effect(
      () => {
        this.model();
        this.formStateChanged();
        this.isProgressActive();
        this.requestErrorsToHandle();
        this.isHinzufuegenRoute();
        untracked(() => {
          this.needToUpdateTitle.set();
        });
      },
      { injector: this.injector },
    );

    if (this.componentConfiguration.dsmRootComponent && this.subLohnkontext) {
      effect(
        () => {
          const lohnkontext = this.subLohnkontext.lohnkontext();
          this.speichernVisible.set(
            !!lohnkontext?.lizenznehmer || !!lohnkontext?.abrechnungskreis,
          );
        },
        { injector: this.injector },
      );
    }
    if (this.isDsmComponent) {
      this.options.formState = {
        ...this.options.formState,
        isDsmComponent: true,
        dsmAdditionalLohkontextProperty: this.dsmAdditionalLohkontextProperty,
      };
      if (this.entityType === EntityType.AggregateRoot) {
        effect(
          () => {
            const model = this.model();
            untracked(() => {
              const disableBecauseStandardView =
                !model?.['lizenznehmerId'] &&
                !model?.['abrechnungskreisId'] &&
                (this.dsmAdditionalLohkontextProperty == null ||
                  !model?.[this.dsmAdditionalLohkontextProperty]);
              this.options.formState.disabled$.nextValue(
                'dsmStandardContext',
                disableBecauseStandardView,
              );
            });
          },
          { injector: this.injector },
        );
      }
    }
  }

  private initializationsForAggregateRoot() {
    this.isHinzufuegenRoute$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isHinzufuegen) => {
        this.aktualisierenVisible.set(!isHinzufuegen);
        this.entfernenVisible$.nextValue('!isHinzufuegenRoute', !isHinzufuegen);
      });
    effect(
      () => {
        const speichernVisible = this.speichernVisible();
        untracked(() =>
          this.entfernenVisible$.nextValue(
            'visibleLikeSpeichern',
            speichernVisible,
          ),
        );
      },
      { injector: this.injector },
    );
    this.model$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((model) =>
        this.wiederherstellenVisible.set(model?.deletedOn != null),
      );
    this.speichernToolbarDefinition.loading =
      this.progressIndicationService.isProgressActiveByOperationType('Saving');
    this.moreOptionsToolbarDefinition.children().unshift(
      {
        title: 'Wiederherstellen',
        actionHandler: () => this.onWiederherstellenButtonClick(),
        allowedByPermission: Permission.AllowAll,
        buttonVisibility: this.wiederherstellenVisible,
      },
      {
        title: 'Entfernen',
        actionHandler: () => this.onDeleteButtonClick(),
        confirmation: () =>
          this.dialogService.confirmDeletion({
            fromPapierkorb: this.model()?.deletedOn != null,
          }),
        buttonVisibility: toSignal(this.entfernenVisible$, {
          injector: this.injector,
        }),
        allowedByPermission: Permission.AllowAll,
      },
      {
        title: 'Validieren',
        actionHandler: () => this.validationService.toggleValidationPanel(),
        buttonVisibility: computed(
          () => this.validationService.validationState() !== 'deactivated',
        ),
      },
      {
        title: 'Aktualisieren',
        actionHandler: () => {
          this.componentInteractionService
            .getRootRequestManager(this)
            .reloadComponents();
        },
        buttonVisibility: this.aktualisierenVisible,
        confirmation: () =>
          this.unsavedChangesService.confirmComponent(this, 'discard'),
      },
    );
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart &&
            event.navigationTrigger === 'popstate' &&
            this.isUrlLeavingCurrentDetailViewToListView(event.url),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.onCloseButtonClick({
          replaceUrl: true,
        });
      });

    this.activatedRoute.fragment
      .pipe(
        map((fragment) =>
          StripHtmlPipe.transform(
            this.componentSublinksInteractionService.sublinks?.find(
              (i) => i.config.name === fragment,
            )?.config.caption,
          ),
        ),
        startWith(undefined),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((fragmentCaption) => {
        const titleParts = [
          this.facade.singularModelCaption,
          fragmentCaption,
          'Baulohn',
        ];
        this.titleService.setTitle(
          titleParts.filter((part) => !!part).join(' - '),
        );
      });
    if (
      this.componentConfiguration.contextObjectPropertyConfigurations &&
      this.lohnkontext.select.selectedLohnkontext() == null
    ) {
      const waitForLonkontextLoaded = effect(
        () => {
          if (this.lohnkontext.select.selectedLohnkontext() != null) {
            untracked(() => {
              waitForLonkontextLoaded.destroy();
              this.initializeFocusFirstFieldOnHinzufuegen();
            });
          }
        },
        { injector: this.injector },
      );
    } else {
      this.initializeFocusFirstFieldOnHinzufuegen();
    }
    this.subLohnkontext?.initialize(
      this.model,
      this.componentConfiguration?.customSubLohnkontextMappings,
      computed(() =>
        this.isHinzufuegenRoute() &&
        this.lohnkontextQueryParam() === 'lizenznehmer'
          ? this.lohnkontext.lizenznehmer()
          : undefined,
      ),
    );
  }

  private initializeFocusFirstFieldOnHinzufuegen() {
    effect(
      () => {
        if (this.isHinzufuegenRoute()) {
          const fields = this.currentFields();
          untracked(() => {
            const firstField = fields.find(
              (f) => f.disabled == null || !convertToSignal('disabled', f)(),
            );
            this.focusField(firstField);
          });
        }
      },
      { injector: this.injector },
    );
  }

  private focusField(field: FieldConfig) {
    if (field == null) {
      return;
    }
    setTimeout(() => {
      const element = field._elementRef?.()?.nativeElement;
      if (element) {
        this.focusMonitor.focusVia(element, 'program');
      }
    }, 100);
  }

  private initializeRecordToolbarDefinitionObservables() {
    this.navigationService?.initializeRecordToolbarDefinitionObservables(
      this.firstRecordToolbarDefinition,
      this.previousRecordToolbarDefinition,
      this.nextRecordToolbarDefinition,
      this.lastRecordToolbarDefinition,
    );
  }

  get isDsmComponent(): boolean {
    const rootComponent =
      this.getRootComponent() as DetailSingleContainerComponent;
    return rootComponent?.componentConfiguration?.dsmRootComponent;
  }

  get dsmAdditionalLohkontextProperty(): string {
    const rootComponent =
      this.getRootComponent() as DetailSingleContainerComponent;
    return getFieldName(
      rootComponent?.componentConfiguration?.dsmAdditionalLohkontextProperty,
    );
  }

  private isUrlLeavingCurrentDetailViewToListView(url: string): boolean {
    const urlTree = this.router.parseUrl(url);
    const pathToNavigate = urlTree?.root?.children?.[
      PRIMARY_OUTLET
    ]?.segments.map((s) => s.path);
    const currentPath = this.activatedRoute.pathFromRoot
      .map((p) => p.snapshot?.url?.[0]?.path)
      .filter((p) => p != null);
    const numberOfMatchingSegments = currentPath.findIndex(
      (pathValue, index) => pathToNavigate[index] !== pathValue,
    );
    // mindestens zwei gleiche Segmente und es darf nicht innerhalb des DetailViews navigiert werden
    // von einer id zur anderen oder zu hinzufuegen
    return (
      numberOfMatchingSegments == 2 &&
      (pathToNavigate.length < 2 ||
        (pathToNavigate[2] != 'hinzufuegen' && !Guid.isGuid(pathToNavigate[2])))
    );
  }

  override setModelObject(value: T) {
    super.setModelObject(value);
    if (this.isModelObjectNew()) {
      this.onPreselect$.next(this.model());
    }
    setTimeout(() => {
      if (this.componentConfiguration.entityType === EntityType.AggregateRoot) {
        this.componentInteractionService.resetFormState(this, false);
      }
    });
  }

  public loadModelObject() {
    if (
      this.entityType !== EntityType.ValueObject &&
      this.isHinzufuegenRoute()
    ) {
      this.setModelObject({} as T);
    }

    if (
      this.componentConfiguration.entityType === EntityType.ValueObject ||
      !this.idFromRouteParameters()
    ) {
      return;
    }
    this.getSingleInitialModelObject();
  }

  private getSingleInitialModelObject() {
    this.onByIdRequest(this.idFromRouteParameters());
  }

  private onByIdRequest(id: string) {
    const completed$ = new Subject<void>();
    this.progressIndicationService.registerProgressWithSubject(
      { key: Guid.create(), operationType: 'Loading' },
      completed$,
    );
    this.facade
      .queryById({ endpointConfiguration: { id } })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        completed$.next();
        const error = isSalaryError(result) ? result : undefined;
        this.loadModelRequestError.set(error);
        if (!error) {
          this.setModelObject(<T>result);
        }
        if (this.loadAfterSaveOperation) {
          this.loadAfterSaveOperation = false;
          return; //skip because of query that will be executed after 4000ms
        }
        this.validationService.updateValidationState(0, this);
      });
  }

  public getRequests(): ComponentOperation<T>[] {
    return this.getCreateOrUpdateRequest(this.model());
  }

  private onSaveCompletedOperation() {
    this.componentInteractionService.resetFormState(this, false);
    if (this.isHinzufuegenRoute()) {
      this.loadAfterSaveOperation = true;
      const id = this.model().id;
      this.router
        .navigate([`../${id}`], {
          relativeTo: this.activatedRoute,
          preserveFragment: true,
        })
        .then((success) => {
          if (success) {
            this.validationService.updateValidationState(4000, this, id);
          }
        });
    } else {
      this.validationService.updateValidationState(4000, this);
    }
  }

  public resetFormState(resetForm: boolean) {
    this.form.markAsPristine();
    if (resetForm) {
      if (this.entityType !== EntityType.ValueObject) {
        this.setModelObject({} as T);
      }
      this.options.resetModel();
    }
  }

  public getFormTitle(modelObject: BaseModel, skipLoading = false): string {
    const componentName =
      this.componentConfiguration.modelObjectName ??
      this.facade.singularModelCaption ??
      '';
    if (!skipLoading && this.isLoadingProgressActive()) {
      return componentName;
    }
    if (this.isHinzufuegenRoute()) {
      return componentName + ' hinzufügen';
    }
    if (!modelObject || Object.keys(modelObject).length === 0) {
      return componentName;
    }
    const dynamicTitlePart = this.getDynamicTitlePart(modelObject).map(
      (i) => i ?? '',
    );
    let title =
      dynamicTitlePart.length === 1
        ? dynamicTitlePart[0]
        : dynamicTitlePart[0] + ' - ' + dynamicTitlePart.slice(1).join(' ');
    if (modelObject?.deletedOn) {
      title += ' (im Papierkorb)';
    }
    return title;
  }

  protected getSubTitle(): string {
    return undefined;
  }

  private getDynamicTitlePart(modelObject: BaseModel): string[] {
    const result = new Array<string>();
    if (!modelObject) {
      return result;
    }

    this.getIdentificationProperties().forEach((element) => {
      let value = modelObject[element];
      if (DateTime.isDateTime(value)) {
        value = value.toFormat(DateTimeFormats.DATE);
      }
      result.push(value);
    });
    return result;
  }

  private getIdentificationProperties(): string[] {
    if (this.componentConfiguration.identificationProperties) {
      return this.componentConfiguration.identificationProperties.map((prop) =>
        getFieldName(prop),
      );
    }
    return ['nummer', 'bezeichnung'];
  }

  private onDeleteButtonClick() {
    const payload = {
      item: this.model(),
    };
    this.handleDeleteRestoreResult(
      this.model()?.deletedOn != null
        ? this.facade.deleteRestorable(payload)
        : this.facade.delete(payload),
    );
  }

  private onWiederherstellenButtonClick() {
    this.handleDeleteRestoreResult(
      this.facade.restore({
        item: this.model(),
      }),
    );
  }

  private handleDeleteRestoreResult(notifier$: Observable<CommandResult<T>>) {
    this.progressIndicationService.registerProgressWithSubject(
      { key: Guid.create(), operationType: 'Other' },
      notifier$,
    );
    this.options.formState.disabled$.nextValue('deleteRestoreInProgress', true);
    notifier$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        this.options.formState.disabled$.removeKey('deleteRestoreInProgress');
        if (result.succeeded) {
          this.onCloseButtonClick();
        }
      });
  }

  private onSaveAndClose() {
    const completedOperation = () => {
      this.componentInteractionService.resetFormState(this, false);
      this.onCloseButtonClick();
    };
    this.submitOperation(completedOperation);
  }

  protected submitOperation(completedOperation: () => void): string {
    return this.componentInteractionService.submit(this, completedOperation);
  }

  private onCloseButtonClick(extras?: NavigationExtras) {
    this.routeBackService.navigateBack({
      relativeTo: this.activatedRoute,
      extras,
    });
  }

  private onSaveAndNew() {
    const lohnkontextParameter =
      this.getLohnkontextQueryParameterFromModelObjectOrUseExisting();
    const completedOperation = () => {
      this.componentInteractionService.resetFormState(this, true);
      this.router
        .navigate(['../hinzufuegen'], {
          relativeTo: this.activatedRoute,
          queryParams: lohnkontextParameter
            ? { lohnkontext: lohnkontextParameter }
            : undefined,
          preserveFragment: true,
        })
        .then((success) => {
          if (success) {
            this.onPreselect$.next(this.model());
          }
        });
      this.focusField(this.currentFields()[0]);
    };
    this.submitOperation(completedOperation);
  }

  private getLohnkontextQueryParameterFromModelObjectOrUseExisting() {
    if (this.lohnkontextQueryParam()) {
      return this.lohnkontextQueryParam();
    }
    if (this.model()['abrechnungskreisId']) {
      return 'abrechnungskreis';
    }
    if (this.model()['mandantId']) {
      return 'mandant';
    }
    if (this.model()['lizenznehmerId']) {
      return 'lizenznehmer';
    }
    return undefined;
  }

  private onSave(): Observable<void> {
    const saveCompleted$ = new Subject<void>();
    const completedOperation = () => {
      saveCompleted$.next();
      this.onSaveCompletedOperation();
    };
    this.submitOperation(completedOperation);
    return saveCompleted$;
  }

  public shouldConfirmUnsavedChanges(): ConfirmUnsaveChangesResult {
    if (this.entityType === EntityType.AggregateRoot) {
      return {
        shouldConfirm:
          this.componentInteractionService.atLeastOneFormDirty(this) &&
          !this.progressIndicationService.isProgressActiveByOperationType(
            'Saving',
          )(),
        saveCallback: this.saveButtonDisabled()
          ? undefined
          : () => this.onSave(),
      };
    }
    return { shouldConfirm: false };
  }
}

export type ValidationState = 'deactivated' | 'loading' | 'failed' | 'success';
