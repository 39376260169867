import { animate, style, transition, trigger } from '@angular/animations';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { NavigationMenuSearchService } from '../../api';
import { NavigationMenuSearchInputComponent } from './navigation-menu-search-input';

@Component({
    selector: 'salary-navigation-menu-search',
    templateUrl: './navigation-menu-search.component.html',
    styleUrl: './navigation-menu-search.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('slideInOut', [
            transition(':leave', [
                animate('750ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ width: 0, opacity: 0 })),
            ]),
            transition(':enter', [
                style({ width: 0, opacity: 0 }),
                animate('750ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ width: '*', opacity: '*' })),
            ]),
        ]),
    ],
    providers: [NavigationMenuSearchService],
    standalone: false
})
export class NavigationMenuSearchComponent {
  private inputComponent = viewChild(NavigationMenuSearchInputComponent);
  protected readonly showInputOverlay = signal(false);
  protected readonly positions = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'start', overlayY: 'top' },
    ),
  ];

  isExpanded = input(true);

  protected focusInput() {
    if (!this.isExpanded() && !this.showInputOverlay()) {
      this.showInputOverlay.set(true);
    }
    setTimeout(() => this.inputComponent()?.focusInput());
  }

  protected hideInputOverlay() {
    this.showInputOverlay.set(false);
  }
}
