import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MultiselectDefinition } from './multiselect-component.definition';

@Component({
    selector: 'salary-multiselect',
    templateUrl: './multiselect.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MultiselectComponent {
  numberOfSelectedObjects = input<number>(0);
  modelCaptionPlural = input.required<string>();
  multiselectDefinitions = input.required<MultiselectDefinition[]>();
}
