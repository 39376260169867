/**
 * flattens an array of arrays
 */
export function flattenArray<T>(array: T[][]): T[] {
  return [].concat(...array);
}

/**
 * removes elements from the given {@link array} in place which satify the specified {@link predicate} condition
 * @returns removed elements
 */
export function spliceWithCondition<T>(
  array: T[],
  predicate: (item: T) => boolean,
) {
  const elementsRemoved = new Array<T>();
  for (let i = 0; i < array.length; i++) {
    if (predicate(array[i])) {
      elementsRemoved.push(...array.splice(i, 1));
    }
  }
  return elementsRemoved;
}

/**
 * Returns true only for the first occurence of an item (compared by ===).
 * Used with the array filter operator it can be used to create an array of distinct items
 * @example
 * ['foo', 'bar', 'foo'].filter(distinct) => ['foo', 'bar']
 */
export function distinct<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}
