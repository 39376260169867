import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { isDescendantNode } from '../../../../utils';
import { NavigationMenuSearchService } from '../../../api';
import { NavMenuSearchItem } from '../../../model';

@Component({
    selector: 'salary-navigation-menu-search-input',
    template: `
    <div class="input-container">
      <input
        matInput
        [matAutocomplete]="autoGroup"
        salaryEllipsis
        (input)="userInput.set($any($event.target).value)"
        placeholder="In Navigation suchen..."
        (blur)="onInputLostFocus($any($event.relatedTarget))"
        data-testid="navigation_menu_search_input"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #autoGroup="matAutocomplete"
        class="navigation-menu-search-panel search-animation"
        (optionSelected)="onNavigationClick($event.option.value)"
        panelWidth="auto"
      >
        @for (item of matchingNavMenuItems(); track item.name + item.url) {
          <mat-option
            tabIndex="0"
            salaryEllipsis
            [value]="item"
            data-testid="navigation_menu_search_option_item"
          >
            <a
              class="navigation-menu-link"
              [routerLink]="[item.url]"
              (click)="onLinkClick($event)"
            >
              <mat-icon matListIcon>{{ item.icon || 'info' }}</mat-icon>
              {{ item.name }}
            </a>
          </mat-option>
        }
      </mat-autocomplete>
      @if (matInput()?.value) {
        <button
          class="small-button"
          type="button"
          mat-icon-button
          (click)="onClearButtonClick()"
          matTooltip="Eingabe leeren"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </div>
  `,
    styles: `
    :host {
      height: 100%;
    }
    .input-container {
      height: 100%;
      display: flex;
      align-items: center;
    }
    input {
      width: 200px;
      align-self: stretch;
    }
    .navigation-menu-link {
      display: flex;
      align-self: center;
      color: unset;
      text-decoration: unset;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NavigationMenuSearchInputComponent {
  private autocompleteTrigger = viewChild(MatAutocompleteTrigger);
  private autoCompleteTrigger = viewChild(MatAutocompleteTrigger);
  private matInputElement = viewChild(MatInput, { read: ElementRef });
  protected matInput = viewChild(MatInput);
  protected userInput = signal<string>(undefined);
  protected matchingNavMenuItems = inject(
    NavigationMenuSearchService,
  ).getItemsByTerm(this.userInput);

  inputLostFocus = output();
  navigated = output();

  private elementRef = inject(ElementRef);
  private router = inject(Router);
  private focusMonitor = inject(FocusMonitor);

  public focusInput() {
    this.focusMonitor.focusVia(this.matInputElement().nativeElement, 'program');
  }

  protected onInputLostFocus(target: Node) {
    if (
      !this.isAutoCompletePanelElement(target) &&
      !this.isWithinComponent(target)
    ) {
      this.clearValue();
      this.inputLostFocus.emit();
    }
  }

  private isAutoCompletePanelElement(target: Node) {
    return isDescendantNode(
      this.autoCompleteTrigger()?.autocomplete?.panel?.nativeElement,
      target,
    );
  }

  private isWithinComponent(target: Node) {
    return isDescendantNode(this.elementRef?.nativeElement, target);
  }

  protected onClearButtonClick() {
    this.clearValue();
    this.focusInput();
  }

  private clearValue() {
    this.matInput().value = '';
    this.userInput.set('');
  }

  protected onLinkClick(event: Event): void {
    event.stopPropagation();
    this.autocompleteTrigger().closePanel();
    this.clearValue();
    this.navigated.emit();
  }

  protected onNavigationClick(selectedItem: NavMenuSearchItem) {
    this.router.navigate([selectedItem.url]); // we need to navigate by code if user select option item by enter key
    this.clearValue();
    this.navigated.emit();
  }
}
