import { Type } from '@angular/core';
import { FieldConfig, getFullKey, getModelClass } from '@salary/common/formly';
import {
  CaptionHelper,
  createNumberFieldDisplayformat,
  createNumberMaskConfig,
  createPlaceholderFromDateFormat,
  getDateFieldFormat,
  getEnumFieldOptions,
  getNumberFieldOptions,
  getTextFieldOptions,
  isBooleanField,
  mergeDeep,
  NumberMaskFormatter,
  OptionsHelper,
} from '@salary/common/utils';
import {
  FactoryArg,
  MaskedDynamic,
  MaskedDynamicOptions,
  MaskedNumberOptions,
  MaskedOptions,
} from 'imask/esm/index';
import {
  ChipListFieldConfig,
  CrudSearchFieldConfig,
  isCrudSearchField,
  isDatepickerField,
  isInputField,
  isLabelField,
  isSearchField,
  isSelectField,
  LabelFieldConfig,
  SearchFieldConfig,
} from '../types';

export function addFieldType(field: FieldConfig) {
  if (field.key) {
    const modelClass = getModelClass(field);
    const fieldName = getFullKey(field);
    if (handleTextField(field, modelClass, fieldName)) {
      return;
    }
    if (handleNumberField(field, modelClass, fieldName)) {
      return;
    }
    if (handleDateField(field, modelClass, fieldName)) {
      return;
    }
    if (handleEnumField(field, modelClass, fieldName)) {
      return;
    }
    if (handleLabelField(field, modelClass, fieldName)) {
      return;
    }
  }
}

function handleTextField(
  field: SearchFieldConfig | CrudSearchFieldConfig | ChipListFieldConfig,
  modelClass: Type<unknown>,
  fieldName: string,
) {
  if (field.displayFormat) return false;
  const options = getTextFieldOptions(modelClass, fieldName);
  if (!options?.format) return false;
  let displayFormat = options.format;
  if (
    (isSearchField(field) || isCrudSearchField(field)) &&
    field.modelMapping
  ) {
    Array.from<string[]>(field.modelMapping).forEach(
      (mapping) =>
        (displayFormat = displayFormat
          .replace(`{${mapping[1]}}`, `{${mapping[0]}}`)
          .replace(`{${mapping[1]}:`, `{${mapping[0]}:`)),
    );
  }
  field.displayFormat = displayFormat;
  if (isSearchField(field) && field.referenceRouterLink == null) {
    field.referenceRouterLink = options.routerLink;
  }
  return true;
}

function handleNumberField(
  field: FieldConfig,
  modelClass: Type<unknown>,
  fieldName: string,
) {
  if (!isInputField(field) || field.maskConfig) return false;
  const options = getNumberFieldOptions(modelClass, fieldName);
  if (!options) return false;
  const numericMaskConfig = createNumberMaskConfig(options);
  let resultMaskConfig: MaskedOptions;
  if (options.suffix) {
    resultMaskConfig = {
      mask: [
        {
          lazy: false,
          ...createNumberMaskConfigCore(numericMaskConfig, options.suffix),
          expose: true,
        },
        {
          mask: Number,
          format: NumberMaskFormatter,
        },
      ],
      dispatch: (appended, dynamicMasked: MaskedDynamic) =>
        dynamicMasked.compiledMasks[
          appended === '' && dynamicMasked.rawInputValue === '' ? 1 : 0
        ],
    } as MaskedDynamicOptions;
  } else {
    resultMaskConfig = createNumberMaskConfigCore(numericMaskConfig);
  }
  field.maskConfig = resultMaskConfig as FactoryArg;
  return true;
}

function createNumberMaskConfigCore(
  numberMaskConfig: MaskedNumberOptions,
  suffix?: string,
) {
  return {
    mask: suffix ? 'num ' + suffix : 'num',
    blocks: {
      num: { ...numberMaskConfig, expose: true },
    },
    parse: (value: string) => {
      if (
        value === '-' ||
        value === ',' ||
        (suffix && (value === '- ' + suffix || value === ', ' + suffix))
      ) {
        return 0;
      }
      if (value === '' || value == null) {
        return null;
      }
      let result = value.replace(/\./g, '').replace(',', '.');
      if (suffix) {
        result = result.replace(suffix, '');
      }
      return Number(result.trim());
    },
    format: NumberMaskFormatter,
  };
}

function handleDateField(
  field: FieldConfig,
  modelClass: Type<unknown>,
  fieldName: string,
) {
  if (!isDatepickerField(field)) return false;
  if (field.dateFormat) return false;
  field.dateFormat = getDateFieldFormat(modelClass, fieldName);
  if (field.dateFormat)
    field.placeholder = createPlaceholderFromDateFormat(field.dateFormat);
  return !!field.dateFormat;
}

function handleEnumField(
  field: FieldConfig,
  modelClass: Type<unknown>,
  fieldName: string,
) {
  if (!isSelectField(field)) return false;

  if (field.selectOptions) {
    return false;
  }
  const enumOptions = getEnumFieldOptions(modelClass, fieldName);
  if (!enumOptions) return false;
  field.selectOptions = OptionsHelper.getOptions(enumOptions.enumType);
  return true;
}

const defaultLabelFieldWithDecoratorTemplateOption: LabelFieldConfig = {
  showValue: false,
};

function handleLabelField(
  field: FieldConfig,
  modelClass: Type<unknown>,
  fieldName: string,
) {
  if (!isLabelField(field) || !field.key || field.label) {
    return false;
  }
  const numberOptions = getNumberFieldOptions(modelClass, fieldName);
  if (numberOptions && field.showValue == null) {
    mergeDeep(field, {
      ...defaultLabelFieldWithDecoratorTemplateOption,
      label: `${CaptionHelper.getCaption(
        modelClass,
        fieldName,
      )}: ${createNumberFieldDisplayformat(
        field.key.toString(),
        numberOptions,
        true,
      )}`,
    });
    return true;
  }
  if (isBooleanField(modelClass, fieldName) && field.showValue == null) {
    mergeDeep(field, {
      ...defaultLabelFieldWithDecoratorTemplateOption,
      label: `${CaptionHelper.getCaption(modelClass, fieldName)}: {${
        field.key
      }}`,
    });
    return true;
  }
  const enumOptions = getEnumFieldOptions(modelClass, fieldName);
  if (enumOptions) {
    mergeDeep(field, {
      ...defaultLabelFieldWithDecoratorTemplateOption,
      label: `${CaptionHelper.getCaption(modelClass, fieldName)}: `,
      selectOptions: OptionsHelper.getOptions(enumOptions.enumType),
    });
  }
  return false;
}
