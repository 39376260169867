import {
  ChangeDetectionStrategy,
  Component,
  computed,
  untracked,
} from '@angular/core';
import { FieldType, convertToSignal } from '@salary/common/formly';
import { getFullCaption } from '../../../utils/salary-print-utils';
import { PrintFieldConfig } from './print-type-config';

@Component({
    selector: 'salary-print',
    template: `
    <div>
      @if (title() != null) {
        <salary-header-container [bigSubTitle]="title()" />
      }
      @for (field of field().fieldGroup; track field.id) {
        @if (tabTitle(field)() != null && !('hide' | toSignal: field)()) {
          <salary-header-container [bigSubTitle]="tabTitle(field)()" />
        }
        <salary-form-field [field]="field" />
      }
    </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SalaryPrintTypeComponent extends FieldType<PrintFieldConfig> {
  protected title = computed(() => {
    const headerTitle = convertToSignal('headerTitle', this.field());
    return untracked(() => {
      return getFullCaption(headerTitle, this.field())();
    });
  });
  protected tabTitle(field: PrintFieldConfig) {
    return computed(() => {
      return getFullCaption(convertToSignal('tabTitle', field), this.field())();
    });
  }
}
