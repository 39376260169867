import { Directive } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { createCopy } from '@salary/common/utils';
import {
  assignFieldValue,
  findControl,
  getFieldValue,
  hasKey,
  registerControl,
  unregisterControl,
} from '../utils/helpers';
import { FieldType } from './field-type';
import { FieldConfig } from './field.config';

function findFirstLeafControl(control: AbstractControl) {
  const controls = control?.['controls'];
  if (!controls) {
    return control;
  }
  const children: AbstractControl[] = Array.isArray(controls)
    ? controls
    : Object.values(controls);
  if (children.length === 0) {
    return control;
  }
  return children.find((c) => findFirstLeafControl(c));
}

@Directive()
export abstract class FieldArrayType<
  F extends FieldConfig,
> extends FieldType<F> {
  static onPopulate(field: FieldConfig) {
    if (hasKey(field)) {
      const control = findControl(field);
      registerControl(
        field,
        control ?? new FormArray([], { updateOn: 'change' }),
      );
    }

    field.fieldGroup = field.fieldGroup || [];

    const length = Array.isArray(field.model()) ? field.model().length : 0;
    if (field.fieldGroup.length > length) {
      for (let i = field.fieldGroup.length - 1; i >= length; --i) {
        unregisterControl(field.fieldGroup[i], true);
        field.fieldGroup.splice(i, 1);
      }
    }
    for (let i = field.fieldGroup.length; i < length; i++) {
      const f = {
        ...createCopy(field.fieldArray),
      };
      if (f.key == null) {
        f.key = `${i}`;
      }
      field.fieldGroup.push(f);
    }
  }

  add(i?: number, initialModel?, { markAsDirty } = { markAsDirty: true }) {
    i = i ?? this.field()?.fieldGroup?.length;
    if (!this.model()) {
      assignFieldValue(this.field(), []);
    }
    this.model().splice(
      i,
      0,
      initialModel ? createCopy(initialModel) : undefined,
    );
    assignFieldValue(this.field(), this.model());

    this._build();
    if (markAsDirty) {
      this.formControl.markAsDirty();
      const firstLeafControl = findFirstLeafControl(
        this.formControl?.['controls']?.[i],
      );
      firstLeafControl?.markAsDirty();
    }
  }

  remove(i: number, { markAsDirty } = { markAsDirty: true }) {
    this.model().splice(i, 1);
    assignFieldValue(this.field(), this.model());
    const field = this.field().fieldGroup?.[i];
    this.field().fieldGroup?.splice(i, 1);
    this.field().fieldGroup?.forEach((f, key) =>
      this.updateArrayElementKey(f, `${key}`),
    );
    if (field) {
      unregisterControl(field, true);
    }
    this._build();
    if (markAsDirty) {
      this.formControl.markAsDirty();
    }
  }

  private _build() {
    const fields = this.field().formControl['_fields'] ?? [this.field()];
    fields.forEach((f) => this.options?.build?.(f));
    this.options?.fieldChanges?.next({
      field: this.field(),
      value: getFieldValue(this.field()),
    });
  }

  private updateArrayElementKey(f: FieldConfig, newKey: string) {
    if (hasKey(f)) {
      f.key = newKey;
      return;
    }
    if (!f.fieldGroup?.length) {
      return;
    }
    for (const element of f.fieldGroup) {
      this.updateArrayElementKey(element, newKey);
    }
  }
}
