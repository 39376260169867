import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  OnInit,
  Renderer2,
  ViewContainerRef,
  computed,
  effect,
  inject,
  input,
  isSignal,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { MatMenuItem } from '@angular/material/menu';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  of,
  pairwise,
  startWith,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { HotkeysService } from '../../hotkeys-dialog/service/hotkeys.service';
import { ToolbarDefinition } from '../../utils/toolbar-definition';

declare type ToolbarItemTypes =
  | HTMLElement
  | MatButton
  | MatSlideToggle
  | MatMenuItem;
@Component({
  selector: 'salary-toolbar-item',
  templateUrl: './toolbar-item.component.html',
  styleUrl: './toolbar-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ToolbarItemComponent implements OnInit {
  toolbarDefinition = input<ToolbarDefinition>();
  protected toolbarItemTitle = computed(() => {
    const title = this.toolbarDefinition().title;
    return isSignal(title) ? title() : title;
  });
  isMenuItem = input(false);
  private textInput = viewChild<ElementRef>('textInput');
  private toogleButton = viewChild<MatSlideToggle>('toggleToolbarButton');
  private customComponentViewContainerRef = viewChild('customComponent', {
    read: ViewContainerRef,
  });
  private actionExecuting$ = new Subject<[ToolbarItemTypes, unknown]>();
  private inputs$ = new Subject<[ToolbarItemTypes, unknown]>();
  private inputHistory = signal<
    [ToolbarItemTypes, { oldValue: string; newValue: string }]
  >([null, { oldValue: '', newValue: '' }]);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private hotkeyService = inject(HotkeysService);
  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);
  private focusMonitor = inject(FocusMonitor);
  private destroyRef = inject(DestroyRef);
  private injector = inject(Injector);

  constructor() {
    effect(() => {
      if (this.textInput() || this.toogleButton()) {
        untracked(() => this.initializeValue());
      }
    });
    effect(() => {
      const isEmpty = this.isEmptyMoreOptionsButton();
      untracked(() => {
        const emptyClass = 'empty-more-options';
        if (isEmpty) {
          this.renderer.addClass(this.elementRef.nativeElement, emptyClass);
        } else {
          this.renderer.removeClass(this.elementRef.nativeElement, emptyClass);
        }
      });
    });

    effect(() => {
      const hotkey = this.toolbarDefinition().hotkey?.();
      untracked(() => {
        if (!hotkey) {
          return;
        }
        this.hotkeyService
          .addShortcut(hotkey)
          .pipe(
            withLatestFrom(
              this.toolbarDefinition().buttonDisabled
                ? toObservable(this.toolbarDefinition().buttonDisabled, {
                    injector: this.injector,
                  })
                : of(false),
              this.toolbarDefinition().buttonVisibility
                ? toObservable(this.toolbarDefinition().buttonVisibility, {
                    injector: this.injector,
                  })
                : of(true),
            ),
            filter(([, disabled, visible]) => !disabled && visible),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe(() => {
            (document.activeElement as HTMLElement)?.blur();
            this.executingAction(undefined, undefined);
          });
      });
    });
  }

  ngOnInit() {
    if (this.isInputButton()) {
      this.initInputHistory();
    }
    if (this.isCustomComponent()) {
      this.initCustomComponent();
    }
    this.actionExecuting$
      .pipe(
        map(([element, value]) => ({
          element: element,
          value: value,
        })),
        (input) =>
          this.isInputButton()
            ? input.pipe(debounceTime(500), distinctUntilChanged())
            : input,
        exhaustMap((args) =>
          this.toolbarDefinition().confirmation
            ? this.toolbarDefinition()
                .confirmation()
                .pipe(
                  map((confirmed) => ({
                    ...args,
                    confirmed: confirmed,
                  })),
                )
            : of({ ...args, confirmed: true }),
        ),
        tap((args) => {
          if (!args.confirmed && args.element) {
            this.rollbackAction(args.element, args.value);
          }
        }),
        filter((args) => args.confirmed),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((args) => this.executeAction(args.value));
  }

  private initInputHistory() {
    this.inputs$
      .pipe(
        startWith([null, ''] as [HTMLElement, string]),
        pairwise(),
        map(
          ([[, oldValue], [newRef, newValue]]) =>
            [newRef, { oldValue: oldValue, newValue: newValue }] as [
              ToolbarItemTypes,
              { oldValue: string; newValue: string },
            ],
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((args) => this.inputHistory.set(args));
  }

  private initCustomComponent() {
    this.customComponentViewContainerRef().clear();
    const component = this.customComponentViewContainerRef().createComponent(
      this.toolbarDefinition().componentType,
    );
    if (this.toolbarDefinition().customComponentInput) {
      this.toolbarDefinition().customComponentInput.forEach(
        (value: unknown, key: string) => {
          component.setInput(key, value);
        },
      );
    }
    if (this.toolbarDefinition().customComponentOuptutHandler) {
      this.toolbarDefinition().customComponentOuptutHandler.forEach(
        (value: (event: unknown) => void, key: string) => {
          component.instance[key]?.subscribe(value);
        },
      );
    }
  }

  private rollbackAction(element: ToolbarItemTypes, value: unknown) {
    if (this.isToggleButton()) {
      element['checked'] = !value;
    } else if (this.isInputButton()) {
      const oldValue = this.inputHistory()[1].oldValue;
      element['value'] = oldValue;
      this.inputs$.next([element, oldValue]);
    }
  }

  protected executingAction(element: ToolbarItemTypes, value: unknown) {
    if (this.isInputButton()) {
      this.focusMonitor.focusVia(element as HTMLElement, 'program');
      this.inputs$.next([element, value]);
    }
    this.actionExecuting$.next([element, value]);
  }

  private executeAction(value: unknown) {
    ToolbarItemComponent.executeAction(
      value,
      this.toolbarDefinition(),
      this.activatedRoute,
      this.router,
    );
  }

  static executeAction(
    value: unknown,
    toolbarDefinition: ToolbarDefinition,
    activatedRoute: ActivatedRoute,
    router: Router,
  ) {
    const actionHandlerResult = toolbarDefinition.actionHandler?.(value);
    if (!actionHandlerResult && toolbarDefinition.routerLink) {
      router.navigate([toolbarDefinition.routerLink], {
        relativeTo: activatedRoute,
        queryParams: toolbarDefinition.queryParams,
      });
    }
  }

  protected isButton = computed(
    () =>
      !this.toolbarDefinition().actionType ||
      this.toolbarDefinition().actionType === 'button',
  );

  protected isToggleButton = computed(
    () => this.toolbarDefinition().actionType === 'toggleButton',
  );

  protected isInputButton = computed(
    () => this.toolbarDefinition().actionType === 'inputButton',
  );

  protected hasChildren = computed(
    () => this.toolbarDefinition()?.children?.()?.length > 0,
  );

  protected isMoreOptionsButton = computed(
    () => this.toolbarDefinition()?.actionType === 'moreOptionButton',
  );

  protected isMenuButton = computed(
    () => this.isMoreOptionsButton() || this.hasChildren(),
  );

  protected isFilterButton = computed(
    () => this.toolbarDefinition()?.actionType === 'filterButton',
  );

  protected isFilterButtonDate = computed(
    () => this.toolbarDefinition()?.actionType === 'filterButtonDate',
  );

  protected isCustomComponent = computed(
    () => this.toolbarDefinition().actionType === 'customComponent',
  );

  protected isIconButton = computed(
    () => this.toolbarDefinition().iconName != null,
  );

  private isEmptyMoreOptionsButton = computed(
    () => !this.hasChildren() && this.isMoreOptionsButton(),
  );

  protected buttonClass = computed(() => {
    if (this.toolbarDefinition().buttonCSSClass) {
      return this.toolbarDefinition().buttonCSSClass;
    }
    if (this.isMenuItem()) {
      return 'mat-menu-item';
    }
    if (this.isIconButton()) {
      return 'mdc-icon-button mat-mdc-icon-button icon-button';
    }
    return 'mat-mdc-outlined-button mdc-button--outlined';
  });

  protected tooltip = computed(() => {
    const hotkey = this.toolbarDefinition()?.hotkey?.();
    const tooltip = this.toolbarDefinition()?.tooltip?.();
    const title = this.toolbarItemTitle();
    return untracked(() => {
      const result = [hotkey?.description, tooltip]
        .filter((s) => !!s)
        .join('\n');
      return result ?? (this.isIconButton ? title : undefined);
    });
  });

  private initializeValue() {
    if (this.toolbarDefinition().initialValue$ != null) {
      this.toolbarDefinition()
        .initialValue$.pipe(take(1), takeUntilDestroyed(this.destroyRef))
        .subscribe((initialValue) => {
          if (this.isInputButton()) {
            this.textInput().nativeElement.value = initialValue ?? '';
          } else if (this.isToggleButton()) {
            this.toogleButton().checked = initialValue === true;
          }
        });
    }
  }
}
