import { FocusMonitor } from '@angular/cdk/a11y';
import { HttpStatusCode } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Signal,
  computed,
  effect,
  inject,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { isSalaryError } from '@salary/common/api/base-http-service';
import { Abrechnungskreis, BaseModel, Lizenznehmer } from '@salary/common/dumb';
import {
  Lohnkontext,
  LohnkontextAbrechnungskreiseFacade,
  LohnkontextLizenznehmerFacade,
  SettingsFacade,
} from '@salary/common/facade';
import { SortExpression } from '@salary/common/standard-facade';
import { createInitials, stringifyEquals } from '@salary/common/utils';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import {
  SalarySearchTypeComponent,
  SearchFieldConfig,
} from '../../salary-formly';
import { SearchInputComponent } from '../../search';

export interface SalaryLohnkontextSearchFormlyFieldConfig
  extends SearchFieldConfig {
  initialized: Signal<boolean>;
}

enum SearchContext {
  Lizenznehmer = 'Lizenznehmer',
  Abrechnungskreise = 'Abrechnungskreise',
}
const SINGULARCAPTIONS = {
  [SearchContext.Lizenznehmer]: 'Lizenznehmer',
  [SearchContext.Abrechnungskreise]: 'Abrechnungskreis',
};

@Component({
    selector: 'salary-lohnkontext-search-type',
    templateUrl: './lohnkontext-search.type.html',
    styleUrl: './lohnkontext-search.type.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LohnkontextSearchTypeComponent
  extends SalarySearchTypeComponent<SalaryLohnkontextSearchFormlyFieldConfig>
  implements AfterViewInit
{
  public static readonly SETTINGS_ABRECHNUNGSKREIS_FAVORITES_KEY =
    'LohnkontextAbrechnungskreiseFavorites';
  public static readonly SETTINGS_LIZENZNEHMER_FAVORITES_KEY =
    'LohnkontextLizenznehmerFavorites';
  protected readonly SEARCHCONTEXTS = [
    SearchContext.Lizenznehmer,
    SearchContext.Abrechnungskreise,
  ];
  private static readonly MAX_FAVORITES = 5;
  private readonly abrechnungskreiseFacade = inject(
    LohnkontextAbrechnungskreiseFacade,
  );
  private readonly lizenznehmerFacade = inject(LohnkontextLizenznehmerFacade);
  private readonly changeDetector = inject(ChangeDetectorRef);
  private readonly settings = inject(SettingsFacade);
  private readonly sanitizer = inject(DomSanitizer);
  private readonly focusMonitor = inject(FocusMonitor);
  private searchInputComponent = viewChild(SearchInputComponent);
  protected readonly sortExpression: SortExpression = {
    columnName: 'nummer',
    direction: 'asc',
  };
  protected readonly proxyFormControl = new FormControl<
    Lizenznehmer | Abrechnungskreis
  >(undefined);
  protected readonly searchContext = signal<SearchContext>(
    SearchContext.Abrechnungskreise,
  );
  protected isFocusWithin = signal(false);
  private elementRef: ElementRef;
  protected readonly referenceRouterLink$ =
    this.proxyFormControl.valueChanges.pipe(
      map((value) => {
        if (typeof value === 'string' || value == null) {
          return undefined;
        }
        if (this.isAbrechnungskreisSet()) {
          return '/administration/abrechnungskreise/{lohnkontext.abrechnungskreis.id}';
        }
        return '/administration/lizenznehmer/{lohnkontext.lizenznehmer.id}';
      }),
    );

  protected readonly contextPlaceholder = computed(
    () => SINGULARCAPTIONS[this.searchContext()] + ' auswählen...',
  );
  private readonly abrechnungskreiseFavoriteIdsFromSettings =
    this.settings.selectBenutzerSettingByKey<string[]>(
      LohnkontextSearchTypeComponent.SETTINGS_ABRECHNUNGSKREIS_FAVORITES_KEY,
    );
  private readonly lizenznehmerFavoriteIdsFromSettings =
    this.settings.selectBenutzerSettingByKey<string[]>(
      LohnkontextSearchTypeComponent.SETTINGS_LIZENZNEHMER_FAVORITES_KEY,
    );

  protected readonly favoriteIds = computed<string[]>(
    () => {
      const context = this.searchContext();
      const idsFromSettings =
        context === SearchContext.Abrechnungskreise
          ? this.abrechnungskreiseFavoriteIdsFromSettings()
          : this.lizenznehmerFavoriteIdsFromSettings();
      return idsFromSettings?.value ?? [];
    },
    { equal: stringifyEquals },
  );
  private favoritesEmpty = computed(() => this.favoriteIds().length === 0);

  private readonly favoriteAbrechnungskreise = signal<BaseModel[]>([]);
  private readonly favoriteAbrechnungskreise$ = toObservable(
    this.favoriteAbrechnungskreise,
  );
  private readonly favoriteLizenznehmer = signal<BaseModel[]>([]);
  private readonly favoriteLizenznehmer$ = toObservable(
    this.favoriteLizenznehmer,
  );

  private readonly favorites = computed(() =>
    this.searchContext() === SearchContext.Abrechnungskreise
      ? this.favoriteAbrechnungskreise()
      : this.favoriteLizenznehmer(),
  );

  private ensureSubscriptionsAlreadyCalled = false;
  private ensureSubscriptions() {
    if (this.ensureSubscriptionsAlreadyCalled) {
      return;
    }
    this.ensureSubscriptionsAlreadyCalled = true;
    toObservable(this.favoriteIds, { injector: this.injector })
      .pipe(
        switchMap((requestedIds) => {
          const itemsFromCache = this.favorites();
          const context = this.searchContext();
          const idsToQuery = requestedIds.filter(
            (id) => !itemsFromCache.some((a) => a.id === id),
          );
          return (
            idsToQuery.length > 0
              ? forkJoin(
                  idsToQuery.map((id) =>
                    this.getCurrentLookupFacade()
                      .queryById({
                        endpointConfiguration: { id },
                        skipQueryRestorable: true,
                      })
                      .pipe(
                        map((foundItem) => ({
                          id,
                          result: isSalaryError(foundItem)
                            ? foundItem.statusCode
                            : foundItem,
                        })),
                      ),
                  ),
                )
              : of(undefined)
          ).pipe(
            map((itemsFromQuery) => ({
              requestedIds,
              itemsFromQuery,
              itemsFromCache,
              context,
            })),
          );
        }),
        map(({ requestedIds, itemsFromQuery, itemsFromCache, context }) => ({
          items: requestedIds.map((id) => {
            const itemFromCache = itemsFromCache.find((i) => i.id === id);
            return itemFromCache
              ? { id, result: itemFromCache }
              : itemsFromQuery.find((i) => i.id === id);
          }),
          context,
        })),
        map((result) => ({
          ...result,
          items: this.cleanUpFavorites(result.items),
        })),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(({ items, context }) =>
        (context === SearchContext.Abrechnungskreise
          ? this.favoriteAbrechnungskreise
          : this.favoriteLizenznehmer
        ).set(items),
      );
  }

  override ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.updateProxyControlWithLohnkontextValue(value);
        this.changeDetector.markForCheck();
      });
    this.proxyFormControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => this.updateLohnkontextWithProxyControlValue(value));
    let firstRun = true;
    effect(
      () => {
        if (this.field().initialized()) {
          untracked(() => {
            this.formFieldControl().lookupFacade.set(
              this.getCurrentLookupFacade(),
            );
          });
          this.searchContext();
          untracked(() => {
            if (firstRun) {
              firstRun = false;
              return;
            }
            const searchString =
              typeof this.proxyFormControl?.value === 'string'
                ? this.proxyFormControl.value
                : '';

            setTimeout(() => {
              this.formFieldControl()?.switchLookupFacadeAndQueryAgain(
                this.getCurrentLookupFacade(),
                searchString,
              );
            });
          });
        }
      },
      { injector: this.injector },
    );
    this.formControl.statusChanges
      .pipe(
        startWith(this.formControl.status),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.formControl.disabled && this.proxyFormControl.enabled) {
          this.proxyFormControl.disable();
          return;
        }
        if (this.formControl.enabled && this.proxyFormControl.disabled) {
          this.proxyFormControl.enable();
        }
      });
  }

  private getCurrentLookupFacade() {
    return this.searchContext() === SearchContext.Lizenznehmer
      ? this.lizenznehmerFacade
      : this.abrechnungskreiseFacade;
  }

  ngAfterViewInit() {
    this.elementRef = this.searchInputComponent().input();
    combineLatest([
      this.focusMonitor.monitor(this.elementRef, true).pipe(
        startWith(false),
        map((origin) => !!origin),
      ),
      this.form.valueChanges,
    ])
      .pipe(
        map(([focused]) => focused || !this.getLizenznehmerFromLohnkontext()),
        distinctUntilChanged(),
        debounceTime(150),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((v) => this.isFocusWithin.set(v));
  }

  private updateProxyControlWithLohnkontextValue(lohnkontext: Lohnkontext) {
    if (
      lohnkontext?.abrechnungskreis?.id &&
      this.proxyFormControl.value?.id !== lohnkontext.abrechnungskreis.id
    ) {
      this.searchContext.set(SearchContext.Abrechnungskreise);
      this.proxyFormControl.setValue(lohnkontext.abrechnungskreis);
    } else if (
      !lohnkontext?.abrechnungskreis?.id &&
      lohnkontext?.lizenznehmer?.id &&
      this.proxyFormControl.value?.id !== lohnkontext.lizenznehmer.id
    ) {
      this.searchContext.set(SearchContext.Lizenznehmer);
      this.proxyFormControl.setValue(lohnkontext.lizenznehmer);
    } else if (
      lohnkontext?.abrechnungskreis == null &&
      lohnkontext?.lizenznehmer == null &&
      typeof this.proxyFormControl.value != 'string'
    ) {
      this.proxyFormControl.setValue(undefined);
    }
  }

  private updateLohnkontextWithProxyControlValue(value: BaseModel) {
    if (value != null && value?.id == null && this.formControl.value != null) {
      this.formControl.setValue(undefined);
    }
    if (
      this.searchContext() === SearchContext.Lizenznehmer &&
      (this.formControl.value?.lizenznehmer?.id !== value?.id ||
        this.formControl.value?.abrechnungskreis != null)
    ) {
      this.formControl.setValue({ lizenznehmer: value });
    } else if (
      this.searchContext() === SearchContext.Abrechnungskreise &&
      this.formControl.value?.abrechnungskreis?.id !== value?.id
    ) {
      this.formControl.setValue({ abrechnungskreis: value });
    }
  }

  protected getAvatarInitials(sozialkassenverfahrenBezeichnung: string) {
    return this.getSozialkassenverfahrenInitialsCore(
      sozialkassenverfahrenBezeichnung,
    );
  }

  private getSozialkassenverfahrenInitialsCore(
    sozialkassenverfahrenBezeichnung: string,
  ) {
    if (sozialkassenverfahrenBezeichnung?.includes('ohne Sozialkassenpflicht'))
      return '';
    if (sozialkassenverfahrenBezeichnung?.includes('Dachdecker')) return 'DA';
    if (sozialkassenverfahrenBezeichnung?.includes('Sozialkasse Berlin'))
      return 'SB';
    if (sozialkassenverfahrenBezeichnung?.includes('Steinmetz')) return 'ST';
    if (sozialkassenverfahrenBezeichnung?.includes('Betonsteinbau'))
      return 'BS';
    if (sozialkassenverfahrenBezeichnung?.includes('Steine-Erden')) return 'SE';
    if (sozialkassenverfahrenBezeichnung?.includes('GaLa')) return 'GA';
    return createInitials(sozialkassenverfahrenBezeichnung);
  }

  protected includesItem(items: string[], itemToFind: string) {
    return items?.includes(itemToFind);
  }

  protected onFavoriteButtonClick(
    event: Event,
    item: BaseModel,
    favoriteIds: string[] = [],
  ) {
    event.preventDefault();
    event.stopPropagation();
    let newFavoriteIds: string[] = [];
    if (favoriteIds.includes(item.id)) {
      newFavoriteIds = favoriteIds.filter((i) => i !== item.id);
    } else if (
      favoriteIds.length === LohnkontextSearchTypeComponent.MAX_FAVORITES
    ) {
      newFavoriteIds = [
        item.id,
        ...favoriteIds.slice(
          0,
          LohnkontextSearchTypeComponent.MAX_FAVORITES - 1,
        ),
      ];
    } else {
      newFavoriteIds = [item.id, ...favoriteIds];
    }
    this.updateFavorites(newFavoriteIds);
  }

  private updateFavorites(ids: string[]) {
    this.settings.createOrUpdateUserSetting({
      key:
        this.searchContext() === SearchContext.Abrechnungskreise
          ? LohnkontextSearchTypeComponent.SETTINGS_ABRECHNUNGSKREIS_FAVORITES_KEY
          : LohnkontextSearchTypeComponent.SETTINGS_LIZENZNEHMER_FAVORITES_KEY,
      value: ids,
    });
  }

  protected readonly getCustomOptionItemsMethod =
    this.getCustomOptionItems.bind(this);

  protected getCustomOptionItems(endpointConfig: {
    queryParameters: { searchString: string };
  }) {
    if (endpointConfig.queryParameters.searchString || this.favoritesEmpty()) {
      return undefined;
    }
    this.ensureSubscriptions();
    return (
      this.searchContext() === SearchContext.Abrechnungskreise
        ? this.favoriteAbrechnungskreise$
        : this.favoriteLizenznehmer$
    ).pipe(
      tap((items) => {
        if (items?.length === 0) {
          this.searchInputComponent().refreshRequired = true;
          this.searchInputComponent().showFilterValues();
        }
      }),
      map((items) => (items.length === 0 ? undefined : items)),
    );
  }

  protected onFocusLost(event: unknown) {
    if (!this.favoritesEmpty()) {
      this.searchInputComponent().refreshRequired = true;
    }
    this.field().focusLost?.(this.field(), event);
  }

  private cleanUpFavorites(
    items: { id: string; result: BaseModel | HttpStatusCode | string }[],
  ): BaseModel[] {
    const result: BaseModel[] = [];
    const idsToRemove: string[] = [];
    for (const item of items) {
      if (typeof item.result === 'number' || typeof item.result === 'string') {
        if (item.result === HttpStatusCode.NotFound) {
          idsToRemove.push(item.id);
        }
      } else {
        result.push(item.result);
      }
    }
    if (idsToRemove.length > 0) {
      this.updateFavorites(
        result.map((r) => r.id).filter((id) => !idsToRemove.includes(id)),
      );
    }
    return result;
  }

  protected get style() {
    return this.sanitizer.bypassSecurityTrustStyle(
      // avatar width (30) + gap (8)
      `--sozialkasseverfahrenWidth:${
        this.getSelectedSozialkassenverfahrenInitials() ? 0 : 38
      }px`,
    );
  }
  protected getSelectedSozialkassenverfahrenInitials() {
    return this.getSozialkassenverfahrenInitialsCore(
      this.getSelectedSozialkasseverfahrenBezeichnung(),
    );
  }

  protected getSelectedSozialkasseverfahrenBezeichnung() {
    return this.model()?.lohnkontext?.abrechnungskreis
      ?.abrechnungskreisSozialkasse
      ?.betriebSozialkasseSozialkassenverfahrenBezeichnung;
  }

  protected getLizenznehmerFromLohnkontext() {
    return this.model()?.lohnkontext?.abrechnungskreis &&
      this.model()?.lohnkontext?.lizenznehmer?.nummer != null &&
      this.model()?.lohnkontext?.lizenznehmer?.bezeichnung != null
      ? this.model()?.lohnkontext?.lizenznehmer?.nummer +
          ' - ' +
          this.model()?.lohnkontext?.lizenznehmer?.bezeichnung
      : undefined;
  }

  protected clickLizenznehmerText() {
    this.elementRef.nativeElement.focus();
    this.elementRef.nativeElement.dispatchEvent(new MouseEvent('click'));
  }

  protected isAbrechnungskreisSet() {
    const value = this.proxyFormControl.value;
    return (
      value != null &&
      typeof value === 'object' &&
      (Object.hasOwn(value, 'mandantId') ||
        Object.hasOwn(value, 'lizenznehmerId'))
    );
  }
}
