import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseInputRendererComponent } from './base-input.renderer.component';
import { BaseCellRenderer } from './utils';

@Component({
    selector: 'salary-checkbox-renderer',
    template: `<mat-checkbox
    [attr.data-testid]="getTestId() | convertSpecialCharacter"
    [checked]="params.value"
    [disabled]="!editable"
  />`,
    styles: `
    mat-checkbox,
    :host ::ng-deep .mdc-form-field {
      height: 100%;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CheckboxRendererComponent
  extends BaseInputRendererComponent
  implements BaseCellRenderer, ICellRendererAngularComp
{
  protected editable = false;
  override agInit(params: ICellRendererParams): void {
    super.agInit(params);
    if (typeof params.colDef.editable === 'boolean') {
      this.editable = params.colDef.editable;
    }
  }
}
