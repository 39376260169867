import { Directive, ElementRef, effect, inject, input } from '@angular/core';
@Directive({
    selector: '[salaryGap]',
    standalone: false
})
export class GapDirective {
  gap = input.required<string>({ alias: 'salaryGap' });
  private el = inject(ElementRef);

  constructor() {
    effect(() => (this.el.nativeElement.style.gap = this.gap()));
  }
}
