import { ChangeDetectionStrategy, Component } from '@angular/core';
@Component({
    selector: 'salary-not-ready-page',
    templateUrl: './not-ready-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NotReadyPageComponent {
  protected login() {
    window.location.reload();
  }
}
