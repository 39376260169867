import { Directive, input } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'salary-layout-left',
    standalone: false
})
export class SalaryLayoutLeftDirective {
  isExpanded = input(true);
}
