import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  model,
  untracked,
  viewChild,
} from '@angular/core';
import { MAT_SELECT_CONFIG, MatSelect } from '@angular/material/select';
import { ListPerspectivesService } from './list-perspectives-service';
import { Perspective } from './perspective.model';

@Component({
    selector: 'salary-perspectives-toolbar-component',
    template: ` <mat-select
    #select
    class="select"
    [disableOptionCentering]="true"
    [hideSingleSelectionIndicator]="true"
    (selectionChange)="change($event)"
    [(ngModel)]="selectedValue"
    [attr.data-testid]="'perspective_select'"
    (click)="$event.stopPropagation()"
  >
    <mat-select-trigger>
      {{ selectedValue() }}
    </mat-select-trigger>
    @for (perspective of perspectives(); track perspective.name) {
      <mat-option
        style="padding-right:8px"
        [value]="perspective.name"
        [attr.data-testid]="
          perspective.name + '_perspective' | convertSpecialCharacter
        "
      >
        <div style="display: flex; align-items: center">
          <div salaryEllipsis style="flex: auto">
            {{ perspective.name }}
          </div>
          @if (perspective.key.includes('#')) {
            <button
              class="small-button"
              style="margin-left: 5%"
              mat-icon-button
              matTooltip="Perspektive umbenennen"
              (click)="renamePerspective($event, perspective)"
              [attr.data-testid]="
                perspective.name + '_rename_button' | convertSpecialCharacter
              "
            >
              <mat-icon>edit</mat-icon>
            </button>
          }
          @if (perspective.key.includes('#')) {
            <button
              class="small-button"
              mat-icon-button
              matTooltip="Perspektive löschen"
              (click)="deletePerspective($event, perspective)"
              [attr.data-testid]="
                perspective.name + '_delete_button' | convertSpecialCharacter
              "
            >
              <mat-icon>close</mat-icon>
            </button>
          }
        </div></mat-option
      >
    }
    <mat-divider style="margin-top:1px; margin-bottom:1px" />
    <button
      class="perspective-add-button"
      mat-button
      (click)="select.toggle(); addPerspective()"
      data-testid="add_perspective_button"
    >
      Perspektive hinzufügen
    </button>
  </mat-select>`,
    styles: `
    ::ng-deep .perspective-panel {
      margin-top: 1px;
      min-width: 300px;
      .mdc-list-item__primary-text {
        width: 100%;
      }
    }
    .select {
      border: 1px solid;
      border-radius: 4px;
      height: 40px;
      line-height: 40px;
      width: 200px;
      font-size: 14px;
      font-weight: 500;
    }

    :host ::ng-deep .mat-mdc-select-trigger {
      padding-left: 15px;
      padding-right: 15px;
    }
    ::ng-deep .perspective-add-button {
      --mdc-text-button-container-shape: 0;
      width: 100%;
      .mat-mdc-button-touch-target {
        height: 0 !important;
      }
    }

    .mat-icon {
      margin-right: unset !important;
    }

    ::ng-deep .mat-mdc-menu-panel .select {
      border: 0;
      margin-right: 0;
      min-width: 100%;
      .mat-mdc-select-arrow {
        transform: rotate(-90deg);
        color: unset;
      }
    }
  `,
    providers: [
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'perspective-panel' },
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PerspectivesToolbarComponent implements AfterViewInit {
  protected selectedValue = model<string>();
  private matSelect = viewChild(MatSelect);
  private listPerspectivesService = inject(ListPerspectivesService);
  protected perspectives = this.listPerspectivesService.perspectives;

  constructor() {
    effect(() => {
      const activeName = this.listPerspectivesService.activePerspectiveName();
      untracked(() => this.selectedValue.set(activeName));
    });
  }

  ngAfterViewInit(): void {
    this.matSelect()?._positions?.push(
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        panelClass: 'mat-mdc-select-panel-above',
      },
    );
  }

  change(event) {
    this.listPerspectivesService.activatePerspective(
      this.perspectives().find((p) => p.name === event.source.value),
    );
  }

  protected addPerspective() {
    this.listPerspectivesService.addPerspective();
  }

  protected deletePerspective(event, perspective: Perspective) {
    event.preventDefault();
    event.stopPropagation();
    this.listPerspectivesService.deletePerspective(perspective);
  }

  protected renamePerspective(event, perspective: Perspective) {
    event.preventDefault();
    event.stopPropagation();
    this.listPerspectivesService.renamePerspective(perspective);
  }
}
