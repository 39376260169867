import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConfigService } from '@salary/common/facade';
import { FieldType } from '@salary/common/formly';
import { isCardField } from '../card/card-field-config';
import { isExpandablePanelField } from '../expandable-panel';
import { isRepeatPanelField } from '../repeat-expandable-panel';
import {
  FxLayoutFieldChildConfig,
  FxLayoutFormlyFieldConfig,
} from './fx-layout-field-config';

@Component({
    selector: 'salary-form-flex',
    template: `
    @if (field().fieldGroup?.length > 0) {
      <div
        [id]="id"
        class="fx-layout"
        [class]="getFxLayoutClass()"
        [salaryGap]="getGap()"
      >
        @for (f of field().fieldGroup; track f.id) {
          <salary-form-field
            [style]="{
              flex: getFlex(f),
              'min-width': field().minWidth,
              'max-width':
                field().fieldGroup?.length === 1
                  ? 'max(400px, 50%)'
                  : field().maxWidth,
              width: getWidth(f),
            }"
            [field]="f"
          />
        }
      </div>
    }
  `,
    styles: `
    .fx-layout {
      display: flex;
      box-sizing: border-box;
    }
    .row-wrap {
      flex-flow: row wrap;
    }
    .column-wrap {
      flex-flow: column wrap;
    }
    .row {
      flex-direction: row;
    }
    .column {
      flex-direction: column;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SalaryFlexLayoutTypeComponent extends FieldType<FxLayoutFormlyFieldConfig> {
  public static readonly defaultOptions: FxLayoutFormlyFieldConfig = {
    fxLayout: 'row wrap',
  };
  printMode = false;
  constructor(configService: ConfigService) {
    super();
    this.printMode = configService.printing.printMode;
  }

  getGap() {
    if (this.field().gap) {
      return this.field().gap;
    }
    if (this.field().twoSideBySide) {
      return '0 16px';
    }
    return undefined;
  }

  protected getFxLayoutClass() {
    if (this.field().fxLayout === 'row wrap') {
      return 'row-wrap';
    }
    if (this.field().fxLayout === 'row') {
      return 'row';
    }
    if (this.field().fxLayout === 'column') {
      return 'column';
    }
    if (this.field().fxLayout === 'column wrap') {
      return 'column-wrap';
    }
    return 'row-wrap';
  }

  protected getFlex(f: FxLayoutFieldChildConfig) {
    if (this.printMode) {
      return '1 1 0';
    }
    if (f.fxFlex) {
      return f.fxFlex;
    }
    return '1 1 0';
  }

  protected getWidth(f: FxLayoutFieldChildConfig) {
    if (f.width) {
      return f.width;
    }
    if (this.field().twoSideBySide) {
      if (
        isExpandablePanelField(f) ||
        isCardField(f) ||
        isRepeatPanelField(f)
      ) {
        return '200px';
      }
      return '150px';
    }
    return null;
  }
}
