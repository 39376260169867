import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { getFadeInAnimation } from '../animations';

export type EmptyStateSize = 'small' | 'medium' | 'large';
@Component({
    selector: 'salary-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrl: './empty-state.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [getFadeInAnimation()],
    standalone: false
})
export class EmptyStateComponent {
  iconName = input<string>();
  testId = input<string>();
  label = input<string>();
  description = input<string>();
  contentShift = input<number>(0);
  size = input<EmptyStateSize>('large');
  protected imageHeight = computed<number>(() => {
    return this.size() === 'large' ? 160 : 120;
  });
  protected isSmallScreen = toSignal(
    inject(BreakpointObserver)
      .observe(Breakpoints.XSmall)
      .pipe(map((result) => !result.matches)),
    { requireSync: true },
  );
}
