import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpStatusCode,
  HttpUrlEncodingCodec,
} from '@angular/common/http';
import { EnumClass } from '@salary/common/dumb';
import { getUrlDateFormat } from '@salary/common/utils';
import { DateTime } from 'luxon';
import {
  catchError,
  iif,
  mergeMap,
  race,
  retry,
  throwError,
  timer,
} from 'rxjs';
import { SalaryHttpError } from '.';
import { EnvironmentConfigService } from '../services/environment-config.service';
import { SalaryError, SalaryUnkownError, isSalaryError } from './salary-error';

export class BaseHttpUtils {
  static throwHandledError(error: HttpErrorResponse) {
    return throwError(() => BaseHttpUtils.handleError(error));
  }
  static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      return {
        message: error.error.message,
        error: error.error,
      } as SalaryUnkownError;
    }
    if (error.error && isSalaryError(error.error)) {
      const salaryError = BaseHttpUtils.handleSalaryError(error.error);
      if (salaryError) return salaryError;
    }
    return {
      name: error.statusText,
      statusCode: error.status,
      error,
      message: error.message,
    } as SalaryHttpError;
  }

  private static handleSalaryError(error: SalaryError): SalaryError {
    error.message = error.messageKey;
    switch (error.messageKey) {
      case 'ValidationError':
        error.message +=
          ' ' + error.validationErrors?.map((e) => e.errorMessage).join('\n');
        break;
      case 'ConversionError':
        error.message +=
          ' ' + error.conversionErrors?.map((e) => e.errorMessage).join('\n');
        break;
      case 'UniqueFieldViolation':
        error.message += ' ' + error.fieldNames?.join(', ');
        break;
      case 'RelationshipViolation':
        error.message +=
          ' ' +
          error.violatedRelations
            ?.map((e) => e.source + '->' + e.target)
            .join(', ');
        break;
      default:
        return undefined;
    }
    return error;
  }

  static formatQueryParameterValue(
    value: EndpointConfigurationQueryParameterType,
  ): string | number | boolean {
    if (DateTime.isDateTime(value)) {
      return getUrlDateFormat(value);
    }
    if (value instanceof EnumClass) {
      return value as number;
    }
    return value;
  }

  static getHttpParams(
    queryParameters: Record<string, EndpointConfigurationQueryParameterType>,
    addDefaultPagingParams = false,
  ): HttpParams {
    let result = new HttpParams({ encoder: new UrlParameterEncodingCodec() });
    if (addDefaultPagingParams) {
      if (queryParameters?.pageSize == null) {
        result = result.append('pageSize', 50);
      }
      if (queryParameters?.page == null) {
        result = result.append('page', 1);
      }
    }

    if (!queryParameters) return result;

    Object.getOwnPropertyNames(queryParameters)
      .filter((propertyName) => queryParameters[propertyName] != null)
      .forEach((propertyName) => {
        result = result.append(
          propertyName,
          BaseHttpUtils.formatQueryParameterValue(
            queryParameters[propertyName],
          ),
        );
      });
    return result;
  }

  static getWithFallback<T>(
    path: string,
    httpParams: HttpParams,
    httpClient: HttpClient,
    configurationService: EnvironmentConfigService,
  ) {
    const fallbackDomains = [
      configurationService.apiMasterdataUrl,
      configurationService.apiTransactionsUrl,
      configurationService.apiReportingUrl,
      configurationService.apiConsolidationUrl,
    ];
    return this.getHttpClientQuery<T>(
      httpClient,
      this.createUrl(
        configurationService.apiAdministrationUrl,
        path,
      ).toString(),
      httpParams,
    ).pipe(
      catchError((error: SalaryError) =>
        iif(
          () =>
            error?.statusCode === HttpStatusCode.NotFound ||
            error?.statusCode === HttpStatusCode.Unauthorized,
          throwError(() => error),
          race(
            fallbackDomains.map((fallbackDomain) =>
              this.getHttpClientQuery<T>(
                httpClient,
                this.createUrl(fallbackDomain, path).toString(),
                httpParams,
              ).pipe(
                catchError((innerError) =>
                  timer(5000).pipe(
                    mergeMap(() => throwError(() => innerError)),
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
    );
  }
  static getHttpClientQuery<T>(
    httpClient: HttpClient,
    url: string,
    httpParams: HttpParams,
    retryCount = 1,
  ) {
    return httpClient
      .get<T>(url, { params: httpParams })
      .pipe(retry(retryCount ?? 0), catchError(this.throwHandledError));
  }
  private static createUrl(domain: string, path: string) {
    return domain + (!path?.startsWith('/') ? '/' : '') + path;
  }
}

export class UrlParameterEncodingCodec extends HttpUrlEncodingCodec {
  override encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}

export type EndpointConfigurationQueryParameterType =
  | string
  | number
  | boolean
  | DateTime
  | EnumClass;

export interface EndpointConfiguration {
  [prop: string]: unknown;
  suffix?: string;
  queryParameters?: Record<string, EndpointConfigurationQueryParameterType>;
}
