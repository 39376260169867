import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { EmptyStateSize } from '@salary/common/dumb-components';

@Component({
    selector: 'salary-list-search-empty-state',
    template: `
    <salary-empty-state
      testId="empty_search_state"
      iconName="biotech"
      [contentShift]="-12"
      label="Hoppla!"
      [size]="size()"
      description="Wir konnten nichts Passendes finden.<br>Versuchen Sie es mit anderen Sucheingaben."
    >
    </salary-empty-state>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListSearchEmptyStateComponent {
  size = input<EmptyStateSize>('large');
}
