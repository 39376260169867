import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  computed,
  contentChild,
  contentChildren,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  MatAutocompleteOrigin,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  EndpointConfigurationQuery,
  EndpointConfigurationQueryParameterType,
  filterSalaryError,
} from '@salary/common/api/base-http-service';
import { Permission } from '@salary/common/authorization';
import {
  BaseModel,
  BasePageModel,
  Guid,
  getFieldName,
} from '@salary/common/dumb';
import { ConfigService, SubLohnkontextFacade } from '@salary/common/facade';
import { FieldConfig } from '@salary/common/formly';
import { SortExpression, StandardFacade } from '@salary/common/standard-facade';
import {
  debounceSignal,
  filterNil,
  formatObject,
  integerFormatter,
  wrapFunction,
} from '@salary/common/utils';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  Unsubscribable,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  of,
  pairwise,
  race,
  skip,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { SalaryLink, isDescendantNode } from '../utils';
import { isReferenceDeleted } from '../utils/reference-deleted';
import { PopupEnabledSupport } from './popup-enable.directive';
import { SearchInputOptionItemDirective } from './search-input-option-item.directive';
import { SearchInputPanelHeaderDirective } from './search-input-panel-header.directive';
import { SearchInputSuffixDirective } from './search-input-suffix.directive';

export type OptionItemMethod = (
  endpointConfiguration: EndpointConfigurationQuery,
) => Observable<BaseModel[]>;

const SHOW_ITEMS_DELAY = 500;

@Component({
    selector: 'salary-search-input',
    templateUrl: './search.input.component.html',
    styleUrl: './search.input.component.scss',
    providers: [
        { provide: MatFormFieldControl, useExisting: SearchInputComponent },
    ],
    animations: [
        trigger('slideInOut', [
            transition('* => *', [
                query(':enter', style({ height: 0, opacity: 0 }), {
                    optional: true,
                }),
                query(':enter', stagger(10, [
                    animate('50ms ease-out', style({ height: '*', opacity: 1 })),
                ]), { optional: true }),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchInputComponent
  implements OnInit, OnDestroy, AfterViewInit, PopupEnabledSupport
{
  protected readonly REQUIRED_PERMISSION_FOR_LINK = Permission.AllowRead;
  private formFieldControl = viewChild(MatInput);
  private matFormField = inject(MatFormField, { optional: true });
  input = viewChild<ElementRef>('input');
  autoCompleteTrigger = viewChild(MatAutocompleteTrigger);
  protected optionItemTemplate = contentChild(SearchInputOptionItemDirective, {
    read: TemplateRef,
  });
  protected customInputSuffixTemplates = contentChildren(
    SearchInputSuffixDirective,
    { read: TemplateRef },
  );
  inputSuffixContainerTemplate = viewChild('searchInputSuffixContainer', {
    read: TemplateRef,
  });
  protected customPanelHeader = contentChild(SearchInputPanelHeaderDirective, {
    read: TemplateRef,
  });
  lookupFacade = model<StandardFacade<BaseModel>>();
  sortExpression = input<SortExpression>();
  inputRequiresOptionMatch = input(true);
  modelMapping = input<string[][]>();
  displayFormat = input<string>();
  displayText = input<(modelObject: unknown) => string>();
  displayFormatLine2 = input<string>();
  displayTextLine2 = input<(model: unknown) => string>();
  customFilter = input<(item: BaseModel) => boolean>();
  useDisplayTextOrFormatForInput = input(true);
  searchOverlayClass = input<string>();
  isInplaceEditor = input(false);
  queryParameters =
    input<Record<string, EndpointConfigurationQueryParameterType>>();
  endpointConfiguration = input<EndpointConfigurationQuery>();
  propertyNameForEqualComparison = input<string>();
  private propertyNameForEqualComparisonWithFallback = computed(
    () =>
      this.propertyNameForEqualComparison() ?? this.modelMapping()?.[0]?.[0],
  );
  formlyField = input<FieldConfig>({});
  private formlyFieldModelChanged = signal(Guid.create());
  mappingParentInput = input<unknown>(undefined, { alias: 'mappingParent' });
  mappingParent = computed(() => {
    this.formlyFieldModelChanged();
    return this.isInplaceEditor()
      ? this.mappingParentInput()
      : this.formlyField()?.model();
  });
  placeholder = input<string>();
  requiredInput = input(false);
  errorStateMatcher = input<ErrorStateMatcher>();
  formControl = input<NonNullable<FormControl>>();
  bindWholeObject = input(false);
  boundFieldname = input<string>();
  inputDisabled = input(false);
  updatedDisplayTextOnLoad = input<string>();
  customOptionItems = input<OptionItemMethod>();
  referenceRouterLink = input<string>();
  hinzufuegenRouterLink = input<string>();
  emptyStateExtendedDescription = input<string>();
  autocompleteConnectedTo = input<MatAutocompleteOrigin>();
  enablePopupWithF7 = input(false);
  hideSearchIcon = input(false);

  isTryingToSelectOption = output<boolean>();
  optionSelected = output<unknown>();
  focusLost = output<unknown>();
  testIdInput = input<string>(undefined, { alias: 'testId' });
  testId = computed(() => this.testIdInput() || this.formlyField().testId);
  embedInputSuffixButtons = input(false);
  private readonly EMPTY_OPTION_BY_VALUE = {
    term: undefined,
    options: undefined,
  };
  private canceledByUser$ = new BehaviorSubject<boolean>(false);
  private optionsByValue$ = new BehaviorSubject<{
    term: string;
    options: unknown[];
  }>(this.EMPTY_OPTION_BY_VALUE);
  public popupModeEnabled$ = new BehaviorSubject<boolean>(true);
  protected optionItemsToShow$ = this.popupModeEnabled$.pipe(
    distinctUntilChanged(),
    switchMap((enabled) => {
      if (!enabled) {
        return of([]);
      } else {
        return this.optionsByValue$.pipe(map((r) => r.options));
      }
    }),
  );
  private stopFocusTimer$ = new Subject<'show' | 'stop'>();
  protected hideItems = signal(false);
  private showItemsOnNextFocusWithoutDelay = false;

  private trySelectOptionByInput$ = new Subject<string>();
  private lookupFilterSubscription: Subscription;
  protected formControlValue = signal<unknown>(undefined);
  private _isLoading = signal(false);
  protected isLoading = debounceSignal(this._isLoading, (isLoading) =>
    isLoading ? 300 : 0,
  );
  protected isLoadingMoreItems = signal(false);
  protected isInvalidReference$: Observable<boolean>;
  protected invalidReferenceMessage: string;
  public isOptionSelected = signal(true);
  protected printMode = inject(ConfigService).printing.printMode;
  private destroyRef = inject(DestroyRef);
  private destroyed = false;
  private _refreshRequired: boolean;
  public set refreshRequired(value: boolean) {
    if (value) {
      // we have to delay resetting options, while user is selecting an item, otherwise selection gets lost
      setTimeout(() => {
        this.optionsByValue$.next(this.EMPTY_OPTION_BY_VALUE);
        this.currentPageModel.set(undefined);
      }, 100);
    }
    this._refreshRequired = value;
  }
  public get refreshRequired() {
    return this._refreshRequired;
  }
  private queryOptionsByTerm$ = new BehaviorSubject<string>(undefined);
  private currentPageModel = signal<BasePageModel<BaseModel>>(undefined);
  protected shimmerLines$ = this.optionItemsToShow$.pipe(
    map((f) => (!f || f.length === 0 ? 5 : f.length)),
  );
  protected moreItemsExists = computed(
    () =>
      this.currentPageModel()?.pageCount > this.currentPageModel()?.currentPage,
  );
  protected currentPageModelText = computed(
    () =>
      `${integerFormatter.format(
        this.currentPageModel()?.lastRowOnPage,
      )} von ${integerFormatter.format(this.currentPageModel()?.rowCount)} geladen`,
  );
  private changeDetector = inject(ChangeDetectorRef);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private elementRef = inject(ElementRef);
  private subLohnkontextFacade = inject(SubLohnkontextFacade, {
    optional: true,
  });

  constructor() {
    effect(() => {
      this.lookupFacade();
      this.queryParameters();
      this.endpointConfiguration();
      this.customOptionItems();
      this.refreshRequired = true;
    });
    effect(() => {
      this.mappingParent();
      untracked(() => this.setValueObjectFromModel());
    });
    effect(() => {
      const formControl = this.formControl();
      untracked(() => {
        this.setValueObjectFromModel();
        this.initRequireOptionMatchValidator();
        if (formControl) {
          formControl.markAsPristine = wrapFunction(
            formControl.markAsPristine,
            () => (this.refreshRequired = true),
          );
        }
      });
    });
    effect(() => {
      if (this.updatedDisplayTextOnLoad()) {
        untracked(() => this.updateDisplayTextOnLoad());
      }
    });
    let formlyFieldModelChangesSubscription: Unsubscribable;
    effect(() => {
      if (this.formlyField()) {
        const changes: Observable<unknown> =
          this.formlyField()?.options.fieldChanges;
        formlyFieldModelChangesSubscription?.unsubscribe();
        formlyFieldModelChangesSubscription = changes
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => this.formlyFieldModelChanged.set(Guid.create()));
      }
    });
    this.optionSelected.subscribe(() => {
      this.isTryingToSelectOption.emit(false);
    });
  }

  ngOnInit() {
    if (this.enablePopupWithF7()) {
      this.popupModeEnabled$.next(false);
    }
    this.trySelectOptionByInput$
      .pipe(
        switchMap((term) =>
          this.optionsByValue$.pipe(
            filter((o) => o.term === term),
            take(1),
            map((o) =>
              o.options?.find((i) =>
                this.matchByPropertyEqualComparison(i, term),
              ),
            ),
            map((valueToSet) => ({ term, valueToSet })),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(({ term, valueToSet }) => {
        if (valueToSet) {
          this.formControl().setValue(this.getBoundModelValue(valueToSet));
          this.selectItem(valueToSet);
        } else if (this.inputRequiresOptionMatch()) {
          this.clearValue();
        } else if (!this.isInplaceEditor()) {
          if (this.modelMapping()) {
            this.setMappingParentPropertyValue(
              this.mappingParent(),
              this.modelMapping()[0][1],
              term,
            );
          }
        } else {
          this.formControl().setValue(term);
          this.formControl().updateValueAndValidity();
        }
        this.isTryingToSelectOption.emit(false); //no value was selcted by user
      });
  }

  ngAfterViewInit() {
    this.updateDisplayTextOnLoad();
    this.formControl()
      .valueChanges.pipe(
        startWith(this.formControl().value),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.formControlValue.set(this.formControl().value));
    this.formControl()
      .valueChanges.pipe(
        startWith(this.formControl().value),
        distinctUntilChanged(),
        pairwise(),
        map(
          ([oldValue, newValue]) =>
            !!oldValue &&
            (Guid.isGuid(oldValue) || oldValue.id) &&
            (!newValue || (!Guid.isGuid(newValue) && !newValue.id)),
        ),
        filter((value) => value),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.clearMappingValues();
        this.optionSelected.emit(undefined);
      });
    this.popupModeEnabled$
      .pipe(skip(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((enabled) => {
        if (enabled) {
          this.showFilterValues();
        }
      });
    // copied from material autocomplete-trigger.ts
    if (typeof window !== 'undefined') {
      window.addEventListener('blur', this._windowBlurHandler);
    }
    this.matFormField?._elementRef?.nativeElement?.addEventListener(
      'click',
      this.formFieldClickHandler,
    );
  }

  private formFieldClickHandler = (event) => {
    if (this.isWithinInputSuffix(event.target)) {
      return;
    }
    this.onInputClick();
  };

  private matchByPropertyEqualComparison(item: unknown, term: string) {
    const propertyValue =
      item[this.propertyNameForEqualComparisonWithFallback()];
    if (typeof propertyValue === 'number') {
      return propertyValue === Number(term);
    }
    if (typeof propertyValue === 'string') {
      return (
        propertyValue.localeCompare(term, undefined, {
          sensitivity: 'accent',
        }) === 0
      );
    }
    return propertyValue === term;
  }

  private initRequireOptionMatchValidator() {
    const validatorsFromBase = this.formControl().validator;
    this.formControl().setValidators(
      validatorsFromBase
        ? [this.formControl().validator, this.requireOptionMatchValidator()]
        : this.requireOptionMatchValidator(),
    );
  }
  private requireOptionMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.isOptionSelected() || !this.inputRequiresOptionMatch()
        ? null
        : { requireOptionMatch: { value: control.value } };
    };
  }

  switchLookupFacadeAndQueryAgain(
    lookupFacade: StandardFacade<BaseModel>,
    searchString: string,
  ) {
    this.lookupFacade.set(lookupFacade);
    this.currentPageModel.set(undefined);
    this.queryOptionsByTerm$.next(searchString);
  }

  showFilterValues() {
    if (this.disabled) {
      return;
    }
    this.ensureLookupFilter();
    if (this.refreshRequired) {
      this.queryOptionsByTerm$.next('');
    } else {
      this.autoCompleteTrigger().openPanel();
    }
  }

  private ensureLookupFilter() {
    if (
      this.lookupFilterSubscription ||
      (!this.lookupFacade() && !this.customOptionItems())
    ) {
      return;
    }
    this.queryOptionsByTerm$
      .pipe(
        filterNil(),
        tap(() => (this.refreshRequired = false)),
        debounceTime(200),
        tap(() => {
          // no normal loading indicator if loading more items
          if (!this.currentPageModel()) {
            this._isLoading.set(true);
          }
          this.changeDetector.markForCheck();
          this.canceledByUser$.next(false);
        }),
        switchMap((term) => {
          const pageInfo = this.currentPageModel();
          return this.queryForItems(term, pageInfo).pipe(
            catchError(() => {
              this.refreshRequired = true;
              return of([]);
            }),
            tap(() => {
              this._isLoading.set(false);
              this.isLoadingMoreItems.set(false);
              this.changeDetector.markForCheck();
            }),
            tap((options) =>
              this.optionsByValue$.next({
                term,
                options:
                  pageInfo == null
                    ? options
                    : (this.optionsByValue$.value.options?.concat(options) ??
                      options),
              }),
            ),
            takeUntil(this.canceledByUser$.pipe(filter((cancel) => cancel))),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.focused && !this.disabled) {
          this.autoCompleteTrigger().openPanel();
        }
      });
    this.subscribeToUserInput();
  }

  private queryForItems(term: string, pageInfo: BasePageModel<unknown>) {
    const endpointConfig = this.createEndpointConfigForSearchQuery(
      term,
      pageInfo,
    );
    return (
      this.customOptionItems()?.(endpointConfig) ??
      this.lookupFacade()
        .queryPage({ endpointConfiguration: endpointConfig })
        .pipe(
          filterSalaryError(),
          tap((result) => this.currentPageModel.set(result)),
          map((result) => result.results),
          map((results) =>
            this.customFilter() ? results.filter(this.customFilter()) : results,
          ),
        )
    );
  }

  private createEndpointConfigForSearchQuery(
    term: string,
    pageInfo: BasePageModel<unknown>,
  ): EndpointConfigurationQuery {
    const sortColumnName = getFieldName(this.sortExpression()?.columnName);
    return {
      ...(this.endpointConfiguration() ?? {}),
      queryParameters: {
        ...this.queryParameters(),
        page: pageInfo == null ? 1 : pageInfo.currentPage + 1,
        searchString: term,
        orderBy: sortColumnName,
        direction: sortColumnName
          ? (this.sortExpression()?.direction ?? 'asc')
          : undefined,
        restrictExpansions: true,
      },
    };
  }

  private subscribeToUserInput() {
    this.lookupFilterSubscription = fromEvent<InputEvent>(
      this.input().nativeElement,
      'input',
    )
      .pipe(
        map((event) => event.target['value']),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((term) => {
        this.isOptionSelected.set(!term);
        this.clearMappingValues();
        this.currentPageModel.set(undefined);
        this.queryOptionsByTerm$.next(term);
      });
  }

  private checkReferenceObjectInLookup() {
    if (this.inputRequiresOptionMatch() && this.formControl()) {
      this.isInvalidReference$ = merge(
        this.setErrorMessageAndState(),
        this.formControl().valueChanges.pipe(
          take(1),
          map(() => false),
        ),
      );
    }
  }

  private setErrorMessageAndState(): Observable<boolean> {
    if (isReferenceDeleted(this.formlyField().key, this.mappingParent())) {
      this.invalidReferenceMessage =
        this.createDisplayTextLine1(this.formControl().value) +
        ' wurde in den Papierkorb verschoben.';
      return of(true);
    }
    return of(false);
  }

  private setValueObjectFromModel() {
    if (
      !this.formControl()?.value ||
      !this.isPrimitive(this.formControl().value) ||
      !this.mappingParent() ||
      !this.modelMapping()
    )
      return;
    this.checkReferenceObjectInLookup();
  }
  private isPrimitive(val: unknown) {
    return val !== Object(val);
  }

  createDisplayTextLine1(lookupModel: unknown): string {
    const modelToDisplay = this.convertLookupModelToDisplayModel(lookupModel);
    if (typeof lookupModel === 'string' && !Guid.isGuid(lookupModel)) {
      if (
        this.isInplaceEditor() &&
        modelToDisplay?.[this.getBoundLookupModelFieldname()] !== lookupModel
      ) {
        return lookupModel;
      }
      if (
        !this.isInplaceEditor() &&
        !this.displayFormat() &&
        !this.displayText()
      ) {
        return lookupModel;
      }
    }
    return SalaryLink.getWithoutLink(
      this.formatModel(
        modelToDisplay,
        this.displayText(),
        this.displayFormat(),
      ),
    );
  }

  createDisplayTextLine2(lookupModel: unknown): string {
    const modelToDisplay = this.convertLookupModelToDisplayModel(lookupModel);
    return this.formatModel(
      modelToDisplay,
      this.displayTextLine2(),
      this.displayFormatLine2(),
    );
  }

  protected convertLookupModelToDisplayModel(lookupModel: unknown) {
    let modelToDisplay = lookupModel;
    if (
      (modelToDisplay != null || !this.isInplaceEditor()) &&
      this.isPrimitive(modelToDisplay)
    ) {
      modelToDisplay =
        this.getSelectedOptionData() ??
        this.createLookupModelFromParentProperties() ??
        lookupModel;
    }
    return modelToDisplay;
  }

  private formatModel(
    model: unknown,
    displayTextFunc: (model: unknown) => string,
    displayFormat: string,
  ) {
    if (displayTextFunc) {
      return model != null ? displayTextFunc(model) : '';
    } else if (model != null) {
      return formatObject(model, displayFormat);
    }
    return '';
  }

  private getSelectedOptionData() {
    return this.autoCompleteTrigger()?.autocomplete?.options?.find(
      (option) => option.selected,
    )?.['data'];
  }
  private createLookupModelFromParentProperties(): unknown {
    const result = {};
    if (this.mappingParent()) {
      if (!this.modelMapping()) {
        return this.mappingParent();
      }
      this.modelMapping()?.forEach((mapping) =>
        this.setMappingParentPropertyValue(
          result,
          mapping[0],
          this.getMappingParentPropertyValue(this.mappingParent(), mapping[1]),
        ),
      );
    }
    return Object.values(result).some((v) => v != null) ? result : undefined;
  }

  private getMappingParentPropertyValue(
    mappingParent: unknown,
    propertyPath: string,
  ) {
    const propertyNameParts = propertyPath.split('.');
    const propertyValue = mappingParent?.[propertyNameParts.shift()];
    if (propertyNameParts.length === 0 || !propertyValue) {
      return propertyValue;
    }
    return this.getMappingParentPropertyValue(
      propertyValue,
      propertyNameParts.join('.'),
    );
  }

  private setMappingParentPropertyValue(
    mappingParent: unknown,
    propertyPath: string,
    value: unknown,
  ) {
    const propertyNameParts = propertyPath.split('.');
    const propertyNameToSet = propertyNameParts.pop();
    let objectOfPropertyNameToSet = mappingParent;
    if (propertyNameParts.length >= 1) {
      objectOfPropertyNameToSet = this.getMappingParentPropertyValue(
        mappingParent,
        propertyNameParts.join('.'),
      );
    }
    if (!objectOfPropertyNameToSet) {
      objectOfPropertyNameToSet = this.ensureMappingParentPropertyPath(
        mappingParent,
        propertyNameParts,
      );
    }
    if (objectOfPropertyNameToSet) {
      objectOfPropertyNameToSet[propertyNameToSet] = value;
    }
  }

  private ensureMappingParentPropertyPath(
    mappingParent: unknown,
    propertyPath: string[],
  ) {
    if (!propertyPath) return undefined;
    let currentObject = mappingParent;
    propertyPath.forEach((property) => {
      if (currentObject[property] == null) {
        currentObject[property] = {};
      }
      currentObject = currentObject[property];
    });
    return currentObject;
  }

  onClearButtonClick() {
    this.clearValue();
    this.onInputClick();
  }

  clearValue() {
    this.refreshRequired = true;
    this.formControl().markAsTouched();
    this.formControl().markAsDirty();
    this.formControl().setValue(null);
    this.clearMappingValues();
    this.isOptionSelected.set(true);
    this.currentPageModel.set(undefined);
    this.formControl().updateValueAndValidity();
    this.optionSelected.emit(undefined);
  }
  onOptionSelected(event) {
    this.selectItem(event.option.data);
  }
  selectItem(item: unknown) {
    this.isOptionSelected.set(true);
    if (!this.isInplaceEditor()) {
      this.modelMapping()?.forEach((mapping) =>
        this.setMappingParentPropertyValue(
          this.mappingParent(),
          mapping[1],
          this.getMappingParentPropertyValue(item, mapping[0]),
        ),
      );
    }
    this.formControl().updateValueAndValidity();
    this.optionSelected.emit(item);
    this.triggerFieldChanges();
    if (!this.inputDisabled()) {
      this.refreshRequired = true;
    }
    this.autoCompleteTrigger().closePanel();
    this.stopFocusTimer$.next('stop');
  }

  private triggerFieldChanges() {
    this.formlyField()?.options.fieldChanges.next({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      field: this.formlyField() as any,
      value: this.formControl().value,
      searchOptionSelected: true,
    });
  }

  private clearMappingValues(): void {
    if (this.isInplaceEditor()) return;
    this.modelMapping()?.forEach((mapping) =>
      this.setMappingParentPropertyValue(
        this.mappingParent(),
        mapping[1],
        undefined,
      ),
    );
  }
  getBoundModelValue(modelObject: unknown) {
    if (this.bindWholeObject() || this.isInplaceEditor()) return modelObject;
    return modelObject[this.getBoundLookupModelFieldname()];
  }
  private getBoundLookupModelFieldname() {
    if (!this.modelMapping()) {
      return this.formlyField()?.key;
    }
    return (
      this.modelMapping()?.find(
        (mapping) => mapping[1] === this.getBoundParentMappingFieldname(),
      )?.[0] ?? 'id'
    );
  }
  private getBoundParentMappingFieldname() {
    if (this.boundFieldname()) {
      return this.boundFieldname();
    }
    const formGroup = this.formControl().parent.controls;
    return Object.keys(formGroup).find(
      (name) => this.formControl() === formGroup[name],
    );
  }

  protected onInputLostFocus(target: Node) {
    if (
      this.isAutoCompletePanelElement(target) ||
      this.isWithinSearchComponent(target)
    ) {
      this.focusInput();
      return;
    }
    if (!this.isOptionSelected() && !this.canceledByUser$.value) {
      this.isTryingToSelectOption.emit(true); //copy values with '+' in bewegungsdaten
    }
    this.stopFocusTimer$.next('stop');
    this.hideItems.set(true);
    this.showItemsOnNextFocusWithoutDelay = false;
    setTimeout(() => {
      if (!this.isOptionSelected() && !this.canceledByUser$.value) {
        this.trySelectOptionByInput$.next(this.value);
      } else if (
        this.formControl().value === '' ||
        this.canceledByUser$.value
      ) {
        this.formControl().setValue(undefined);
      }
      if (!this.destroyed) {
        this.focusLost.emit(target);
      }
    }, 100);
  }

  protected onInputGotFocus() {
    this.ensureLookupFilter();
    // copied from material autocomplete-trigger.ts
    if (!this._canOpenOnNextFocus) {
      this._canOpenOnNextFocus = true;
      return;
    }
    this.input().nativeElement.select();
    if (this.showItemsOnNextFocusWithoutDelay) {
      this.showItemsOnNextFocusWithoutDelay = false;
      this.showFilterValues();
    } else {
      // we have to disable the panel to prevent cached items to be shown immediately
      race(
        timer(SHOW_ITEMS_DELAY).pipe(map(() => 'show')),
        this.stopFocusTimer$.pipe(take(1)),
      )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((mode) => {
          if (mode === 'show' && this.focused && !this.disabled) {
            this.hideItems.set(false);
            this.showFilterValues();
          }
        });
    }
  }

  private focusInput() {
    this.input()?.nativeElement?.focus();
  }

  protected onInputClick() {
    if (!this.focused) {
      this.showItemsOnNextFocusWithoutDelay = true;
    }
    this.stopFocusTimer$.next('show');
    this.focusInput();
  }

  private isAutoCompletePanelElement(target: Node) {
    return isDescendantNode(
      this.autoCompleteTrigger()?.autocomplete?.panel?.nativeElement,
      target,
    );
  }

  private isWithinSearchComponent(target: Node) {
    return isDescendantNode(this.elementRef?.nativeElement, target);
  }

  private isWithinInputSuffix(target: Node) {
    return isDescendantNode(
      (node) => node?.['classList']?.contains('search-input-suffix-buttons'),
      target,
    );
  }

  private updateDisplayTextOnLoad() {
    if (this.updatedDisplayTextOnLoad() && this.input()?.nativeElement) {
      setTimeout(() => {
        this.input().nativeElement.value = this.updatedDisplayTextOnLoad();
        this.changeDetector.markForCheck();
      });
    }
  }

  protected isLinkActive = computed(
    () =>
      this.referenceRouterLink() &&
      this.formControlValue() &&
      this.isOptionSelected() &&
      !!formatObject(this.mappingParent(), this.referenceRouterLink(), {
        clearResultOnMissingProperty: true,
      }),
  );

  protected identifyOptionItem(item: BaseModel) {
    return item?.id ?? item?.[this.getBoundLookupModelFieldname()];
  }

  protected onMoreItemsButtonClick() {
    this.isLoadingMoreItems.set(true);
    this.queryOptionsByTerm$.next(this.queryOptionsByTerm$.value);
    this.focusInput();
  }

  protected get emptyStateHinzufuegenLink() {
    const hinzufuegenUrl = this.hinzufuegenRouterLink();
    if (hinzufuegenUrl == null) return undefined;
    return {
      actionHandler: () => {
        if (!this.subLohnkontextFacade) {
          this.router.navigate([hinzufuegenUrl], {
            state: { createRouteBackInfo: true },
          });
        }

        const abrechnungskreis = this.subLohnkontextFacade.abrechnungskreis();
        let queryParameter: Params = undefined;
        if (abrechnungskreis) {
          queryParameter = {
            lohnkontext: 'abrechnungskreis',
            lohnkontextId: abrechnungskreis.id,
          };
        } else {
          const currentLohnkontext =
            this.activatedRoute.snapshot.queryParams.lohnkontext;
          if (currentLohnkontext) {
            queryParameter = {
              lohnkontext: currentLohnkontext,
            };
          }
        }
        this.router.navigate([hinzufuegenUrl], {
          state: { createRouteBackInfo: true },
          queryParams: queryParameter,
        });
      },
    };
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      if (!this.destroyed) {
        this.isTryingToSelectOption.emit(false);
      }
      this.canceledByUser$.next(true);
      this._isLoading.set(false);
      this.isLoadingMoreItems.set(false);
      this.refreshRequired = true;
    } else if (event.key === 'Enter') {
      this.autoCompleteTrigger().closePanel();
    }
  }

  protected onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue === '') {
      this.isTryingToSelectOption.emit(false);
    } else {
      this.isTryingToSelectOption.emit(true);
    }
  }

  // copied from material autocomplete-trigger.ts
  private _canOpenOnNextFocus = true;
  private _windowBlurHandler = () => {
    // If the user blurred the window while the autocomplete is focused, it means that it'll be
    // refocused when they come back. In this case we want to skip the first focus event, if the
    // pane was closed, in order to avoid reopening it unintentionally.
    this._canOpenOnNextFocus =
      window.document.activeElement !== this.input().nativeElement ||
      this.autoCompleteTrigger()?.panelOpen;
  };

  ngOnDestroy() {
    this.destroyed = true;
    this.lookupFilterSubscription?.unsubscribe();
    // copied from material autocomplete-trigger.ts
    if (typeof window !== 'undefined') {
      window.removeEventListener('blur', this._windowBlurHandler);
    }
    this.matFormField?._elementRef?.nativeElement?.removeEventListener(
      'click',
      this.formFieldClickHandler,
    );
  }
  //#region MatFormField Implementation
  get value() {
    return this.formControl().value;
  }
  set value(val: string) {
    if (this.input()?.nativeElement) {
      this.input().nativeElement.value = val;
    }
  }
  get stateChanges() {
    return this.formFieldControl()?.stateChanges;
  }
  get id() {
    return this.formFieldControl().id;
  }
  get ngControl() {
    return this.formFieldControl().ngControl;
  }
  get focused() {
    return this.formFieldControl().focused;
  }
  get empty() {
    return this.formFieldControl().empty;
  }
  get shouldLabelFloat() {
    return this.formFieldControl().shouldLabelFloat;
  }
  get required() {
    return this.formFieldControl().required;
  }
  get disabled() {
    return this.formFieldControl().disabled;
  }
  get errorState() {
    return this.formFieldControl().errorState;
  }
  get controlType() {
    return this.formFieldControl().controlType;
  }
  setDescribedByIds(ids: string[]) {
    this.formFieldControl().setDescribedByIds(ids);
  }
  onContainerClick() {
    this.formFieldControl().onContainerClick();
  }
  //#endregion
}
