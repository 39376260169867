const DOUBLE_QUOTE_PLACEHOLDER = '§Q§';

export function encodeJsonString(input: string): string {
  return input?.replace(/"/g, DOUBLE_QUOTE_PLACEHOLDER);
}

export function decodeJsonString(encoded: string): string {
  return encoded?.replace(new RegExp(DOUBLE_QUOTE_PLACEHOLDER, 'g'), '"');
}

export function encodeToBase64(str: string): string {
  if (str == null) {
    return str;
  }
  const encoder = new TextEncoder();
  const bytes = encoder.encode(str);
  let binary = '';
  bytes.forEach((byte) => (binary += String.fromCharCode(byte)));
  const result = btoa(binary);
  return result;
}

export function decodeBase64(str: string): string {
  const base64Regex =
    /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/;
  if (!base64Regex.test(str ?? '')) {
    return str ?? '';
  }
  try {
    const binary = atob(str);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    return decoder.decode(bytes);
  } catch {
    return str;
  }
}
