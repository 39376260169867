import { Injectable, Signal, computed, inject, untracked } from '@angular/core';
import { AuthorizationService } from '@salary/common/authorization';
import { ToolbarDefinition } from '../../utils';
import { ToolbarComponent } from '../toolbar.component';

@Injectable()
export class ToolbarItemVisibleService {
  private authorizationService = inject(AuthorizationService);
  createItemVisibleSignal(
    toolbar: ToolbarComponent,
    definition: Signal<ToolbarDefinition>,
  ) {
    return computed(() => {
      const def = definition();
      const byDefinition = !def.buttonVisibility
        ? true
        : def.buttonVisibility();
      const emptyStateVisible = toolbar.emptyStateVisible();
      const viewModeDeletedVisible = toolbar.viewModeDeletedVisible();
      return untracked(() => {
        const byAuthorization = this.authorizationService.hasPermission(
          def.allowedByPermission,
        );
        const byEmptyState = ToolbarItemVisibleService.getEmptyStateVisibility(
          emptyStateVisible,
          def,
        );
        const byViewMode = ToolbarItemVisibleService.getViewModeVisibility(
          viewModeDeletedVisible,
          def,
        );
        return byAuthorization && byEmptyState && byViewMode && byDefinition;
      });
    });
  }

  private static getEmptyStateVisibility(
    isEmptyStateVisible: boolean,
    definition: ToolbarDefinition,
  ) {
    return (
      !isEmptyStateVisible ||
      (isEmptyStateVisible && definition.visibleInEmptyState)
    );
  }

  private static getViewModeVisibility(
    isViewModeDeletedVisible: boolean,
    definition: ToolbarDefinition,
  ): boolean {
    return (
      !definition.visibleInViewMode ||
      (isViewModeDeletedVisible &&
        definition.visibleInViewMode === 'deleted') ||
      (!isViewModeDeletedVisible && definition.visibleInViewMode === 'normal')
    );
  }
}
