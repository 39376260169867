import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
  input,
  output,
} from '@angular/core';
import { MatChipSelectionChange } from '@angular/material/chips';
import { MatMenuPanel } from '@angular/material/menu';

@Component({
  selector: 'salary-chips-filter',
  template: `<mat-chip-listbox
    [multiple]="multiple()"
    selectable
    [attr.data-testid]="testId()"
    [class.mat-mdc-chip-set-stacked]="orientation() === 'vertical'"
  >
    @for (data of chipsData(); track data.name) {
      <mat-chip-option
        [selectable]="canDeselectChip(data)"
        [selected]="data.selected"
        (selectionChange)="selectionChanged($event, data)"
        [attr.data-testid]="
          'chiplistbutton_' + data.name | convertSpecialCharacter
        "
        matBadge
        [salaryTwoDigitBadge]="data.chipsBadgeText?.()"
        salaryBadgeColor="primary"
        [class.badge-on-button]="data.badgeOnButton"
        [class]="data.classes"
      >
        <span class="chip-content">
          @if (data.chipsLeadingIcon) {
            <mat-icon class="chip-leading-icon">{{
              data.chipsLeadingIcon
            }}</mat-icon>
          }
          {{ data.name }}
          @if (data.chipsButtonIcon?.() && data.selected) {
            <button
              [matTooltip]="data.chipsButtonTooltip"
              matChipRemove
              (click)="data.chipsButtonHandler?.(data)"
              [attr.data-testid]="
                'chiplistbutton_' + data.name + '_filterbutton'
                  | convertSpecialCharacter
              "
              [matMenuTriggerFor]="data.menu"
            >
              <mat-icon>{{ data.chipsButtonIcon?.() }}</mat-icon>
            </button>
          }
        </span>
      </mat-chip-option>
    }
  </mat-chip-listbox>`,
  styles: `
    :host ::ng-deep {
      .badge-on-button .mat-badge-content {
        --mat-badge-container-overlap-offset: -15px;
      }

      .mat-badge-content {
        z-index: 1;
      }
    }

    .chip-content {
      display: flex;
      align-items: center;
    }
    .chip-leading-icon {
      padding-left: 4px;
      padding-right: 8px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ChipsFilterComponent {
  allowNothingSelected = input(true);
  multiple = input(true);
  filterData = input<ChipsFilterData[]>();
  filterChanged = output<ChipsFilterData[]>();
  testId = input<string>(undefined);
  orientation = input('horizontal');
  onlyUserEvent = input(false);
  protected chipsData = computed<ChipsFilterData[]>(
    () => {
      return this.filterData();
    },
    {
      equal: (prev: ChipsFilterData[], curr: ChipsFilterData[]) =>
        ChipsFilterComponent.chipsEqual(prev, curr),
    },
  );

  protected canDeselectChip(data: ChipsFilterData) {
    if (!data.selected) {
      return true;
    }
    if (!this.multiple()) {
      return this.allowNothingSelected() !== false;
    }
    return (
      this.chipsData().filter((data) => data.selected).length > 1 ||
      this.allowNothingSelected() !== false
    );
  }

  protected selectionChanged(
    event: MatChipSelectionChange,
    item: ChipsFilterData,
  ) {
    if (this.onlyUserEvent() && !event.isUserInput) {
      return;
    }
    if (!this.multiple()) {
      this.chipsData().forEach((item) => (item.selected = false));
    }
    item.selected = event.selected;
    this.filterChanged.emit(this.chipsData());
  }

  static chipsEqual(value1: ChipsFilterData[], value2: ChipsFilterData[]) {
    return (
      value1?.length === value2?.length &&
      value1?.every(
        (data, index) =>
          data.name === value2?.[index].name &&
          data.selected === value2?.[index].selected,
      )
    );
  }
}

export interface ChipsFilterData {
  name: string;
  selected: boolean;
  data?: unknown;
  //** used to store item in settings, if not specified name is used */
  settingsKey?: string;
  chipsButtonIcon?: Signal<string>;
  chipsButtonTooltip?: string;
  chipsBadgeText?: Signal<number>;
  badgeOnButton?: boolean;
  menu?: MatMenuPanel;
  chipsButtonHandler?: (chip: ChipsFilterData) => void;
  classes?: string;
  chipsLeadingIcon?: string;
}
