import { Injectable, inject } from '@angular/core';
import {
  IProcessManagerService,
  NOTIFICATION_SERVICE_TOKEN,
  ProcessDefinition,
} from '@salary/common/utils';
import { Subject } from 'rxjs';

@Injectable()
export class ProcessManagerService implements IProcessManagerService {
  processCompleted$ = new Subject<ProcessDefinition>();

  private readonly notificationService = inject(NOTIFICATION_SERVICE_TOKEN);

  private _registeredProcesses: Record<string, ProcessDefinition> = {};

  registerProcess(notificationProcess: ProcessDefinition) {
    if (!this._registeredProcesses[notificationProcess.id]) {
      this._registeredProcesses[notificationProcess.id] = notificationProcess;
    }
  }

  reportFinishedOperation(processId: string, errorMessage?: string) {
    const processDefinition = this.getProcessById(processId);
    processDefinition.reportedOperations.push({
      errorMessage: errorMessage,
    });
    this.showErrorImmediately(processDefinition, errorMessage);
    this.showMessageIfCompleted(processDefinition);
  }

  private showErrorImmediately(
    processDefinition: ProcessDefinition,
    errorMessage?: string,
  ) {
    if (processDefinition.showErrorsSeparately && errorMessage) {
      this.notificationService.showError(
        processDefinition.errorMessage,
        processDefinition.source,
        errorMessage,
      );
    }
  }

  private showMessageIfCompleted(processDefinition: ProcessDefinition) {
    if (
      processDefinition.numberOfOperations ===
      processDefinition.reportedOperations.length
    ) {
      this.processCompleted$.next(processDefinition);
      if (!processDefinition.hasErrors()) {
        this.showSuccessMessage(processDefinition.successMessage);
      } else if (!processDefinition.showErrorsSeparately) {
        this.showErrorMessage(processDefinition);
      }
      delete this._registeredProcesses[processDefinition.id];
    }
  }

  public getProcessById(processId: string) {
    const processDefinition = this._registeredProcesses[processId];
    if (!processDefinition) {
      throw new Error(
        `operation finished, but process with ${processId} was not registered`,
      );
    }
    return processDefinition;
  }

  cancelOperations(processId: string, numberOfCanceledOperations: number) {
    const processDefinition = this.getProcessById(processId);
    processDefinition.numberOfOperations -= numberOfCanceledOperations;
    this.showMessageIfCompleted(processDefinition);
  }

  private showSuccessMessage(message: string) {
    if (message != null) {
      this.notificationService.show(message);
    }
  }

  private showErrorMessage(processDefinition: ProcessDefinition) {
    const message = processDefinition.reportedOperations
      .filter((operation) => operation.errorMessage != null)
      .map((operationWithError) => operationWithError.errorMessage)
      .join(', ');
    this.notificationService.showError(
      processDefinition.errorMessage,
      processDefinition.source,
      message,
    );
  }
}
