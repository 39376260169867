import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '@salary/common/authentication';
import { getBenutzerDisplayText } from '@salary/common/utils';
import { AppSettingsDialogService } from '../app-settings';
@Component({
    selector: 'salary-current-user-dialog',
    templateUrl: 'current-user.dialog.html',
    styleUrl: 'current-user.dialog.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CurrentUserDialogComponent {
  private authenticationService = inject(AuthenticationService);
  protected username = computed(() =>
    !this.authenticationService.isAuthenticated()
      ? 'Nicht angemeldet'
      : getBenutzerDisplayText(this.authenticationService.benutzer()) ||
        'Kein Name vergeben',
  );
  private dialogRef = inject(MatDialogRef);
  private appSettingsDialogService = inject(AppSettingsDialogService);

  protected logout() {
    this.authenticationService.logout();
    this.dialogRef.close();
  }

  protected openUserSettingsDialog() {
    this.appSettingsDialogService.show();
    this.dialogRef.close();
  }
}
