import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type UnsavedChangesConfirmationKind = 'save' | 'discard';

export interface UnsavedChangesDialogData {
  saveAvailable: boolean;
  confirmationKind: UnsavedChangesConfirmationKind;
}
@Component({
    selector: 'salary-unsaved-changes-dialog',
    templateUrl: './unsaved-changes-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UnsavedChangesDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: UnsavedChangesDialogData) {}
}
