import {
  DestroyRef,
  Injectable,
  computed,
  inject,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogService } from '@salary/common/dialog';
import { SettingsFacade } from '@salary/common/facade';
import { FieldConfig } from '@salary/common/formly';
import { createCopy, filterNil, stringifyEquals } from '@salary/common/utils';

@Injectable()
export abstract class FormlySettingsServiceBase<T> {
  private dialogService = inject(DialogService);
  private settingsFacade = inject(SettingsFacade);
  private destroyRef = inject(DestroyRef);
  private setting = this.settingsFacade.selectBenutzerSettingByKey<T>(
    this.getSettingsKey(),
  );
  public settings = computed<T>(
    () => {
      const setting = this.setting();
      return untracked(() =>
        setting?.value ? setting.value : createCopy(this.getDefaultModel()),
      );
    },
    { equal: stringifyEquals },
  );

  abstract getFormly(model: T): FieldConfig[];

  abstract getSettingsKey(): string;

  abstract getDefaultModel(): T;

  openDialog() {
    const setting = this.settingsFacade.selectBenutzerSettingByKey<T>(
      this.getSettingsKey(),
    )();
    const s = setting?.value
      ? setting?.value
      : createCopy(this.getDefaultModel());
    this.createDialog(s)
      .pipe(filterNil(), takeUntilDestroyed(this.destroyRef))
      .subscribe((modifiedSettings) => this.saveSettings(modifiedSettings));
  }

  private createDialog(settings: T) {
    return this.dialogService
      .openFormlyDialog({
        data: {
          model: settings,
          fields: this.getFormly(settings),
          caption: 'Einstellungen',
        },
        width: '900px',
        autoFocus: false,
      })
      .afterClosed();
  }

  saveSettings(settings: T) {
    return this.settingsFacade.createOrUpdateUserSetting({
      key: this.getSettingsKey(),
      value: settings,
    });
  }
}
