import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';

@Component({
    selector: 'salary-grid-loading-header',
    template: `
    @if (loading?.()) {
      <mat-spinner
        diameter="18"
        data-testid="validation_loading"
        matTooltip="Validierungsergebnisse werden geladen..."
      />
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LoadingHeaderComponent {
  protected loading: Signal<boolean>;

  agInit(params): void {
    this.loading = params.loading;
  }
}
