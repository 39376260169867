import { Injectable } from '@angular/core';
import {
  BaseHttpCommandService,
  BaseHttpUtils,
  EndpointConfigurationCommand,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { Notification, NotificationStatus } from '@salary/common/dumb';
import { catchError, forkJoin, map, retry } from 'rxjs';
import { NOTIFICATION_DOMAINS } from './notifications-query.service';

@Injectable({ providedIn: 'root' })
export class NotificationsCommandService extends BaseHttpCommandService<Notification> {
  constructor(private configurationService: EnvironmentConfigService) {
    super(configurationService.apiCalculationUrl, 'notifications');
  }

  private getUrl(item: Notification | string) {
    return (
      this.configurationService?.[
        typeof item === 'string' ? item : item?.['domain']
      ] ?? this.configurationService.apiCalculationUrl
    );
  }

  override update(
    item: Notification,
    endpointConfiguration?: EndpointConfigurationCommand,
  ) {
    this.url = this.getUrl(item);
    return super.update(item, endpointConfiguration);
  }

  override deleteRestorable(
    item: Notification,
    endpointConfiguration?: EndpointConfigurationCommand,
  ) {
    this.url = this.getUrl(item);
    return super.deleteRestorable(item, endpointConfiguration);
  }

  deleteAll(status: NotificationStatus) {
    return forkJoin(
      NOTIFICATION_DOMAINS.map((domain) =>
        this.httpClient
          .delete<void>(`${this.getUrl(domain)}/${this.endpointFormat}`, {
            params: { status: <number>status },
          })
          .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError)),
      ),
    ).pipe(map(() => undefined));
  }
}
