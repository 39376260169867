import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'salary-html-edit-dialog',
    template: `
    <div class="top-container">
      <h1 mat-dialog-title>{{ data.caption }}</h1>
      <div mat-dialog-content style="padding-bottom: 4px">
        <salary-html-editor [(htmlText)]="data.text" />
      </div>

      <div mat-dialog-actions>
        <button
          mat-button
          [mat-dialog-close]="null"
          data-testid="cancel_banner_button"
        >
          Abbrechen
        </button>
        <button
          mat-button
          [mat-dialog-close]="data.text"
          data-testid="edit_banner_button"
        >
          Speichern
        </button>
      </div>
    </div>
  `,
    styles: `
    .top-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HtmlEditDialogComponent {
  protected data = inject<{ text: string; caption: string }>(MAT_DIALOG_DATA);
}
