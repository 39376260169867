import { ChangeDetectionStrategy, Component } from '@angular/core';
import { replaceSpecialCharacters } from '@salary/common/dumb';
import { DatepickerFieldConfig } from '../salary-formly';
import { DateEditorColumnOptions } from './column';
import { BaseFormlyEditorComponent } from './utils/base-formly-editor.component';
import { FormlyEditorComponentTemplates } from './utils/formly-editor-component.templates';

@Component({
    selector: 'salary-date-input-editor',
    template: FormlyEditorComponentTemplates.FORMLY_EDITOR_COMPONENT_TEMPLATE,
    styles: [
        FormlyEditorComponentTemplates.DEFAULTSTYLE,
        `
      :host ::ng-deep .mat-datepicker-toggle {
        display: flex;
        align-items: center;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DateInputEditorComponent extends BaseFormlyEditorComponent<DateEditorColumnOptions> {
  getSpecificFormlyConfig(): DatepickerFieldConfig {
    return {
      type: 'datepicker',
      dateFormat: this.editorOptions?.dateFormat,
      testId: replaceSpecialCharacters(`editor_${this.params.colDef.field}`),
      filter: () => this.editorOptions?.filter,
    };
  }

  static defaultValueSetter = (params) => {
    let valueChanged = false;
    if (params.newValue != null && params.oldValue != null) {
      valueChanged = !params.newValue.equals(params.oldValue);
    } else {
      valueChanged = params.newValue != params.oldValue;
    }

    if (valueChanged) {
      params.data[params.colDef.field] = params.newValue;
      return true;
    }
    return false;
  };
}
