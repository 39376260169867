import { computed, DestroyRef, inject, signal, untracked } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { isSalaryError } from '@salary/common/api/base-http-service';
import { HTMLText } from '@salary/common/dumb';
import { HTMLTextFacade, LohnkontextFacade } from '@salary/common/facade';
import {
  formatObject,
  getPrimaryUrlWithoutParams,
  stringifyEquals,
} from '@salary/common/utils';
import { distinctUntilChanged, filter, map, of, switchMap, take } from 'rxjs';
import { OnBoardingTextConfiguration } from '../list';

export class HtmlTextUtil {
  private facade = inject(HTMLTextFacade);
  private lohnkontextFacade = inject(LohnkontextFacade);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private htmlText = signal<HTMLText>(undefined, {
    equal: () => false,
  });
  public htmlTextKey = signal<string>(undefined);
  private htmlTextKey$ = toObservable(this.htmlTextKey);
  public htmlTextData = computed(
    () => {
      const lohnkontext = this.lohnkontextFacade.select.selectedLohnkontext();
      const htmlText = this.htmlText();
      return untracked(() => {
        if (!htmlText?.text) return undefined;
        const text = formatObject(lohnkontext, htmlText.text);
        return text == null ? undefined : { template: htmlText.text, text };
      });
    },
    { equal: stringifyEquals },
  );
  public htmlTextLoaded = signal(false);

  constructor() {
    this.htmlTextKey$
      .pipe(
        distinctUntilChanged(),
        switchMap((key) => {
          if (!key) {
            return of(undefined);
          } else {
            this.htmlTextLoaded.set(false);
            return this.facade.queryByKeyRequest(key);
          }
        }),
        takeUntilDestroyed(),
      )
      .subscribe((htmlText) => {
        this.htmlTextLoaded.set(true);
        if (isSalaryError(htmlText)) {
          this.htmlText.set(undefined);
          return;
        }
        this.htmlText.set(htmlText);
      });
  }
  public htmlTextChanged(text: string) {
    const currentValue = this.htmlText();
    if (currentValue) {
      if (text === '') {
        this.facade.deleteRestorable({ item: currentValue });
        this.facade.deleteFromCache(currentValue);
        this.htmlText.set(undefined);
      } else {
        currentValue.text = text;
        this.facade.update({ item: currentValue });
        this.facade.setToCache(currentValue);
        this.htmlText.set(currentValue);
      }
    } else {
      this.htmlTextKey$
        .pipe(
          take(1),
          switchMap((key) => {
            const newHtmlText: HTMLText = { text: text, key: key };
            return this.facade.create({ item: newHtmlText }).pipe(
              filter((res) => !isSalaryError(res)),
              map((res) => ({ ...newHtmlText, id: res.result })),
              takeUntilDestroyed(this.destroyRef),
            );
          }),
        )
        .subscribe((newItem) => {
          this.facade.setToCache(newItem);
          this.htmlText.set(newItem);
        });
    }
  }

  public initializeOnBordingBanners(
    configuration: OnBoardingTextConfiguration,
  ) {
    if (!configuration) return;
    if (configuration) {
      let onboardingTextKey = undefined;
      if (typeof configuration === 'string') {
        onboardingTextKey = configuration;
      } else {
        const suffix = getPrimaryUrlWithoutParams(this.router).split('/').pop();
        onboardingTextKey = configuration.find(
          (config) => config.routeSuffix === suffix,
        )?.key;
      }
      if (onboardingTextKey) {
        this.htmlTextKey.set(onboardingTextKey);
      }
    }
  }
}
