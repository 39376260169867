import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@salary/common/formly';
import { AccordionFieldConfig } from './accordion-field-config';

@Component({
    selector: 'salary-accordion',
    template: `
    <mat-accordion
      [id]="id"
      [multi]="field().multi"
      [class]="field().accordionClasses"
    >
      @for (
        panelField of field().fieldGroup;
        track panelField.id;
        let i = $index
      ) {
        <mat-expansion-panel
          [class]="field().panelClasses"
          [id]="panelField.id"
          [expanded]="field().expanded"
        >
          @let panelTitle = panelField.headerTitle;
          @let panelDescription = panelField.headerDescription;
          @let panelTitleLine2 = panelField.headerTitleLine2;
          <mat-expansion-panel-header
            [attr.data-testid]="
              'panelheader_' + panelTitle + '(' + i + ')'
                | convertSpecialCharacter
            "
          >
            <div class="header-container">
              <div class="header-first-line">
                <mat-panel-title #titleElement>
                  <salary-invalid-control-icon
                    [field]="panelField"
                    [testId]="'link_icon_' + panelTitle + '(' + i + ')'"
                    [connectedTo]="titleElement"
                  />
                  <span
                    style="min-height:17px"
                    [salaryEllipsis]="panelTitle"
                    [innerHtml]="panelTitle"
                  ></span>
                </mat-panel-title>
                @if (!!panelDescription) {
                  <mat-panel-description>
                    {{ panelDescription }}
                  </mat-panel-description>
                }
              </div>
              <span class="expandable-header-second-line">{{
                panelTitleLine2
              }}</span>
            </div>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <salary-form-field [field]="panelField" />
          </ng-template>
        </mat-expansion-panel>
      }
    </mat-accordion>
  `,
    styles: `
    .mat-expansion-panel-header-title {
      min-width: 0;
    }
    .mat-expansion-panel-header-description {
      justify-content: flex-end;
      flex-grow: unset;
      white-space: nowrap;
    }

    :host::ng-deep .mat-expansion-panel-header .mat-content {
      align-items: center;
    }

    .header-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .header-first-line {
      display: flex;
    }

    .expandable-header-second-line {
      font-size: 11px;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AccordionTypeComponent extends FieldType<AccordionFieldConfig> {}
