export class Guid {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  public static validator =
    /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/i;

  public static isGuid(guid: unknown) {
    return !!guid && Guid.validator.test(guid.toString());
  }

  public static create(): string {
    return window.isSecureContext
      ? crypto.randomUUID()
      : Guid.manuallyCreateUUID();
  }

  private static manuallyCreateUUID() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16),
    );
  }
}
