import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  inject,
} from '@angular/core';
import { NotificationStatus } from '@salary/common/dumb';
import { NotificationsFacade } from '@salary/common/facade';

@Component({
  selector: 'salary-notification-toolbar-item',
  template: `
    <salary-application-toolbar-item
      name="notifications"
      icon="notifications"
      [badgeText]="badgeText()"
      [tooltip]="tooltip()"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NotificationToolbarItemComponent implements OnInit {
  private notificationsFacade = inject(NotificationsFacade);
  private notArchivedEntities =
    this.notificationsFacade.select.notArchivedEntities;
  protected badgeText = computed(
    () =>
      this.notArchivedEntities().filter(
        (notification) => notification.status === NotificationStatus.Neu,
      ).length,
  );
  protected tooltip = computed(
    () =>
      'Benachrichtigungen' +
      (this.badgeText() ? ` (${this.badgeText()} ungelesen)` : ''),
  );

  ngOnInit() {
    this.notificationsFacade.startPolling();
  }
}
