import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DialogService } from '@salary/common/dialog';
import { DateTimeFormats, Termin } from '@salary/common/dumb';
import { map } from 'rxjs';
import { FormConfigBuilder } from '../salary-formly';
import { TermineService } from './termine-service';

@Component({
    selector: 'salary-termine-carousel',
    template: `
    <salary-carousel>
      @let termine = this.termine$ | async;
      @for (
        item of termine;
        track item.termin?.datum + item.termin?.uhrzeit;
        let i = $index
      ) {
        <ng-container *salaryCarouselItem>
          <div
            (click)="openTerminDialog(item)"
            style="cursor:pointer"
            matTooltip="Klicken um den Termin zu öffnen"
          >
            <div
              class="first-line"
              salaryEllipsis
              [showTooltip]="false"
              [attr.data-testid]="'first_line_' + i"
            >
              {{ item.line1 }}
            </div>
            <div
              class="second-line"
              salaryEllipsis
              [showTooltip]="false"
              [attr.data-testid]="'second_line_' + i"
            >
              {{ item.line2 }}
            </div>
          </div>
        </ng-container>
      }
    </salary-carousel>
  `,
    styles: `
    .first-line {
      font-size: 16px;
      line-height: 25px;
      padding-top: 8px;
    }
    .second-line {
      font-size: 11.5px;
      line-height: 13px;
      margin-top: -1px;
    }

    mat-icon {
      font-size: 17px !important;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TermineCarouselComponent {
  private dialogService = inject(DialogService);
  protected termine$ = inject(TermineService).termine$.pipe(
    map((termine) =>
      termine.results.map((termin) => this.formatTermin(termin)),
    ),
  );

  protected openTerminDialog(terminToDisplay: TerminToDisplay) {
    const b = new FormConfigBuilder<Termin>();
    b.input('name', { disabled: true });
    b.fxLayout({ twoSideBySide: true }).withFields((b) => [
      b.input('uhrzeit', { disabled: true }),
      b.checkbox('ganztaegig', {
        defaultValue: true,
        disabled: true,
      }),
    ]);
    b.textarea('beschreibung', {
      autosize: true,
      autosizeMinRows: 1,
      autosizeMaxRows: 3,
      maxLength: 250,
      disabled: true,
    });
    this.dialogService.openFormlyDialog<Termin>({
      data: {
        cancelButton: false,
        saveButton: false,
        caption: `Termin am ${terminToDisplay.termin.datum.toFormat(
          DateTimeFormats.DATE,
        )}`,
        model: {
          ...terminToDisplay.termin,
          uhrzeit: terminToDisplay.termin.datum.toFormat('HH:mm'),
        },
        modelClass: Termin,
        fields: b.build(),
      },
    });
  }

  private formatTermin(termin: Termin): TerminToDisplay {
    return {
      line1: `${termin.datum?.toFormat('dd.MM.yyyy')} - ${termin.name}`,
      line2: `${
        termin.ganztaegig
          ? 'Ganztägig'
          : termin.datum?.toFormat('HH:mm') + ' Uhr'
      } ${termin.beschreibung ? '- ' + termin.beschreibung : ''}`,
      termin: termin,
    };
  }
}

interface TerminToDisplay {
  line1: string;
  line2: string;
  termin: Termin;
}
