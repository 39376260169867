import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(
    _route: ActivatedRouteSnapshot,
    _detachedTree: DetachedRouteHandle,
  ): void {
    return;
  }
  shouldAttach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ) {
    return (
      future.routeConfig === curr.routeConfig ||
      (future.routeConfig.path === 'hinzufuegen' &&
        curr.routeConfig.path === ':id' &&
        future.component === curr.component) ||
      (future.routeConfig.path === ':id' &&
        curr.routeConfig.path === 'hinzufuegen' &&
        future.component === curr.component)
    );
  }
}
