import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  captionPlural,
  captionSingular,
  dateField,
  DateTimeFormats,
  fieldId,
  iconName,
} from '../decorators';
import { BaseModel } from './base';
@captionSingular('Abrechnungsfreigabe')
@captionPlural('Abrechnungsfreigaben')
@iconName('flyover')
export class Abrechnungsfreigabe extends BaseModel {
  @fieldId('nddCAB')
  @caption('Monat')
  @dateField(DateTimeFormats.MONTH_YEAR)
  freigabeMonat?: DateTime;
  @fieldId('slLCx1')
  @caption('Schätzung')
  @booleanField()
  freigabeSvSchaetzung?: boolean;
  @fieldId('mOJPKB')
  @caption('Abrechnung')
  @booleanField()
  freigabeAbrechnung?: boolean;
  @fieldId('6GjNNC')
  @caption('Elektronische Übermittlung')
  @booleanField()
  freigabeElektronischeUebermittlung?: boolean;
}
