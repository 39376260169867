import { Injectable, inject } from '@angular/core';
import { SalaryError } from '@salary/common/api/base-http-service';
import { DokumenteQueryService } from '@salary/common/api/data-services';
import { Dokument } from '@salary/common/dumb';
import { StandardFacade } from '@salary/common/standard-facade';
import { catchError, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DokumenteFacade extends StandardFacade<
  Dokument,
  DokumenteQueryService
> {
  constructor() {
    super(Dokument, inject(DokumenteQueryService));
  }

  getBlobById(id: string) {
    return this.queryService.getBlobById(id).pipe(
      catchError((error: SalaryError) => {
        this.notificationService.showError(
          'Die Datei konnte nicht heruntergeladen werden.',
          this.notificationSource,
          error?.message,
        );
        return of(undefined);
      }),
    );
  }

  openDocument(document: Blob) {
    if (!document) {
      return;
    }
    window.open(window.URL.createObjectURL(document), '_blank');
  }
}
