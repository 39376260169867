import { Injectable } from '@angular/core';
import {
  ApiUrls,
  BaseHttpQueryService,
  EndpointConfigurationQuery,
  EnvironmentConfigService,
  QueryOptions,
} from '@salary/common/api/base-http-service';
import { Notification } from '@salary/common/dumb';
import { flattenArray } from '@salary/common/utils';
import { catchError, forkJoin, map, of, tap } from 'rxjs';

export const NOTIFICATION_DOMAINS: ApiUrls[] = [
  'apiCalculationUrl',
  'apiConsolidationUrl',
];

@Injectable({ providedIn: 'root' })
export class NotificationsQueryService extends BaseHttpQueryService<Notification> {
  constructor(private configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiCalculationUrl,
      endpointFormat: 'notifications',
    });
  }
  protected override getPaged<Notification>(
    endpointConfiguration?: EndpointConfigurationQuery,
    options?: Omit<QueryOptions, 'withDefaultPagingParams'>,
  ) {
    return forkJoin(
      NOTIFICATION_DOMAINS.map((domain) =>
        super
          .getPaged<Notification>(endpointConfiguration, {
            ...options,
            url: this.configurationService[domain],
          })
          .pipe(
            tap((result) => {
              result?.results?.forEach((notification) => {
                notification['domain'] = domain;
              });
            }),
            catchError(() =>
              of({
                results: [],
                rowCount: 0,
                currentPage: 0,
                pageCount: 0,
                firstRowOnPage: 0,
                lastRowOnPage: 0,
              }),
            ),
          ),
      ),
    ).pipe(
      map((results) => {
        const allItems = flattenArray(results.map((result) => result.results));
        return {
          ...results.find((result) => result.results != null),
          results: allItems,
          rowCount: allItems.length,
        };
      }),
    );
  }
}
