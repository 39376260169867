import {
  CdkPortalOutlet,
  ComponentPortal,
  ComponentType,
} from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Type,
  inject,
  viewChild,
} from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModel } from '@salary/common/dumb';
import { FieldConfig } from '@salary/common/formly';
import { CaptionHelper } from '@salary/common/utils';
import { Observable, map, of, startWith } from 'rxjs';

export interface CrudSearchDialogWithFormComponent {
  form: FormGroup;
}

export interface CrudSearchDialogData {
  modelClass: Type<BaseModel>;
  modelItem: BaseModel;
  detailComponentType: ComponentType<unknown>;
  fields?: FieldConfig[];
}

@Component({
    selector: 'salary-crud-search-dialog',
    template: ` <h1 mat-dialog-title data-testid="dialog-title">
      {{ modelCaption }} {{ isNew ? 'anlegen' : 'bearbeiten' }}
    </h1>
    <mat-dialog-content>
      @if (data.detailComponentType) {
        <ng-template [cdkPortalOutlet]="componentPortal" />
      } @else {
        @if (data.fields) {
          <form [formGroup]="form">
            <salary-form
              [form]="form"
              [fields]="getFields"
              [model]="data.modelItem"
              [options]="options"
            />
          </form>
        }
      }
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        [mat-dialog-close]="undefined"
        data-testid="dialog-button2"
      >
        Abbrechen
      </button>
      <button
        mat-button
        type="submit"
        [mat-dialog-close]="data.modelItem"
        data-testid="dialog-button1"
        [disabled]="inValid$ | async"
      >
        Speichern
      </button>
    </mat-dialog-actions>`,
    styles: `
    .mat-mdc-dialog-content {
      padding-top: 10px !important;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CrudSearchDialogComponent implements AfterViewInit {
  protected readonly data = inject<CrudSearchDialogData>(MAT_DIALOG_DATA);
  protected readonly isNew = this.data.modelItem?.id == null;
  protected readonly modelCaption = CaptionHelper.getCaptionSingular(
    this.data.modelClass,
  );
  protected readonly componentPortal = new ComponentPortal(
    this.data.detailComponentType,
  );
  protected inValid$: Observable<boolean>;
  protected form: FormGroup;
  protected options = {
    formState: { modelClass: this.data.modelClass },
  };
  private portalOutlet = viewChild(CdkPortalOutlet);

  constructor() {
    if (this.data.modelItem == null) {
      this.data.modelItem = {};
    }
    if (this.data.fields) {
      this.form = new UntypedFormGroup({});
    }
  }

  protected getFields = () => {
    return this.data?.fields;
  };

  private initInvalidState() {
    const form =
      this.form ??
      (
        this.portalOutlet()
          ?.attachedRef as ComponentRef<CrudSearchDialogWithFormComponent>
      )?.instance?.form;
    if (form == null) {
      this.inValid$ = of(false);
    } else {
      this.inValid$ = form.statusChanges.pipe(
        map((s) => s === 'INVALID'),
        startWith(form.invalid),
      );
    }
    setTimeout(() => form.updateValueAndValidity());
  }

  ngAfterViewInit(): void {
    this.initInvalidState();
  }
}
