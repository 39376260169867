import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SALARY_IS_STABLE } from '@salary/common/utils';
import { DateTime } from 'luxon';
import { concat, distinctUntilChanged, map, startWith, timer } from 'rxjs';

@Component({
    selector: 'salary-current-date-label',
    template: ` <div data-testid="current_date_label">
    {{ displayText$ | async }}
  </div>`,
    styles: `
    div {
      font-size: 14px;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CurrentDateLabelComponent {
  protected displayText$ = concat(
    inject(SALARY_IS_STABLE),
    timer(0, 1000 * 60),
  ).pipe(
    startWith(<void>undefined),
    map(() => DateTime.now().toFormat(`EEE, d. MMMM y, 'KW' W`)),
    distinctUntilChanged(),
  );
}
