import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { EmptyStateSize } from '@salary/common/dumb-components';

@Component({
    selector: 'salary-item-not-found-empty-state',
    template: `
    <salary-empty-state
      testId="item_not_found_empty_state"
      iconName="sd_card_alert"
      [contentShift]="12"
      [size]="size()"
      label="Hoppla!"
      description="Der Datensatz existiert leider nicht mehr."
    />
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ItemNotFoundEmptyStateComponent {
  size = input<EmptyStateSize>('large');
}
