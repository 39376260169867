import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  output,
  signal,
  untracked,
  viewChild,
  viewChildren,
} from '@angular/core';
import {
  identifyToolbarDefinition,
  ToolbarDefinition,
} from '../utils/toolbar-definition';
import { ToolbarItemComponent } from './toolbar-item';
import { ToolbarItemVisibleService } from './toolbar-item-visible/toolbar-item-visible.service';

@Component({
  selector: 'salary-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
  providers: [ToolbarItemVisibleService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ToolbarComponent {
  classes = input<string>();
  toolbarDefinitions = input.required<ToolbarDefinition[]>();
  private readonly toolbarDefinitionsWithVisiblity = computed(() =>
    this.toolbarDefinitions().map((def) => ({
      definition: def,
      visible: this.toolbarItemVisibleService.createItemVisibleSignal(
        this,
        signal(def),
      ),
    })),
  );
  viewModeDeletedVisible = input(false);
  emptyStateVisible = input(false);
  hideItemsDirection = input<'leftToRight' | 'rightToLeft'>('rightToLeft');
  parentWidth = input<number>();
  private readonly ownWidth = signal<number>(undefined);
  private readonly width = computed(
    () => this.parentWidth() ?? this.ownWidth(),
  );
  private measuringContainer =
    viewChild<ElementRef<HTMLElement>>('measuringContainer');

  visibleItemsChanged = output<ToolbarDefinition[]>();
  private rootVisibleToolbarItems = viewChildren(ToolbarItemComponent);
  private readonly visibleItems = computed(() => {
    return this.toolbarDefinitionsWithVisiblity()
      .filter((toolbarItem) => toolbarItem.visible())
      .map((toolbarItem) => toolbarItem.definition);
  });
  private readonly moreOptionDefinition = computed(() =>
    this.toolbarDefinitions().find(
      (def) => def.actionType === 'moreOptionButton',
    ),
  );
  private initialMoreOptionsChildren = signal<ToolbarDefinition[]>(undefined);
  private readonly alwaysRootDefinitions = computed(() =>
    this.toolbarDefinitions().filter(
      (def) => def.alwaysRoot || def.actionType === 'moreOptionButton',
    ),
  );
  protected definitionsLeft = signal<ToolbarDefinition[]>([]);
  protected definitionsRight = signal<ToolbarDefinition[]>([]);
  protected moreOptionsLeft = signal(false);
  private toolbarItemVisibleService = inject(ToolbarItemVisibleService);
  private readonly BUFFER_SIZE = 15;
  private readonly INPUT_BUTTON_WIDTH = 202;
  private readonly ICON_BUTTON_WIDTH = 48;
  private readonly TOGGLE_BUTTON_WIDTH = 141;
  private readonly SPLIT_BUTTON_WIDTH = 140;
  private readonly COMPONENT_WIDTH = 202;
  private readonly BUTTON_WIDTH = 133;
  private readonly FILTER_BUTTON_WIDTH = 116;
  private itemWidths = new Map<ToolbarDefinition, number>();
  protected finishedMeasuring = signal(false);

  constructor() {
    effect(() => {
      if (
        this.toolbarDefinitions() != null &&
        this.initialMoreOptionsChildren() == null
      ) {
        untracked(() =>
          this.initialMoreOptionsChildren.set(
            this.moreOptionDefinition()?.children?.() ?? [],
          ),
        );
      }
    });
    let first = true;
    effect(() => {
      this.width();
      this.visibleItems();
      this.finishedMeasuring();
      if (!this.finishedMeasuring()) {
        return;
      }
      if (first) {
        first = false;
        return;
      }
      untracked(() => this.updateToolbarDefinitions());
    });
    effect(() => {
      const rootToolbarDefinitions = this.rootVisibleToolbarItems();
      const toolbarDefinitions = rootToolbarDefinitions.map((i) =>
        i.toolbarDefinition(),
      );
      untracked(() => {
        this.visibleItemsChanged.emit(toolbarDefinitions);
      });
    });

    const effectRef = effect(() => {
      const container = this.measuringContainer();
      untracked(() => {
        if (container == null) {
          return;
        }
        effectRef.destroy();
        queueMicrotask(() => {
          const items = container.nativeElement.querySelectorAll(
            'salary-toolbar-item',
          );
          items.forEach((item, index) => {
            const width = item?.getBoundingClientRect()?.width;
            this.itemWidths.set(
              this.toolbarDefinitions().filter(this.noWidth)[index],
              width,
            );
          });
          this.finishedMeasuring.set(true);
        });
      });
    });
  }

  private updateToolbarDefinitions() {
    const rootToolbarDefinitions = this.moveDefinitionsBySize();
    this.alignLeftAndRight(rootToolbarDefinitions);
  }

  private moveDefinitionsBySize() {
    let rootToolbarDefinitions = [...this.toolbarDefinitions()];
    const moreOptionDefinition = this.moreOptionDefinition();
    const nestedOrHide = this.getToolbarDefinitionsToSetNestedOrHide(
      moreOptionDefinition != null,
    );
    const definitionsToSetNested = nestedOrHide.nested;
    const definitionsToSetHide = nestedOrHide.hidden;
    if (moreOptionDefinition) {
      const value = this.initialMoreOptionsChildren()?.concat(
        definitionsToSetNested,
      );
      if (!moreOptionDefinition.children) {
        moreOptionDefinition.children = signal(value);
      } else {
        moreOptionDefinition.children.set(value);
      }

      //move to children
    }
    rootToolbarDefinitions = rootToolbarDefinitions.filter(
      (d) =>
        !definitionsToSetNested.includes(d) &&
        !definitionsToSetHide.includes(d),
    );
    return rootToolbarDefinitions;
  }

  private alignLeftAndRight(rootToolbarDefinitions: ToolbarDefinition[]) {
    this.definitionsLeft.set(
      rootToolbarDefinitions.filter(
        (definition) =>
          !definition.alignment || definition.alignment === 'left',
      ),
    );
    this.moreOptionsLeft.set(
      this.definitionsLeft().some(
        (def) => def.actionType === 'moreOptionButton',
      ),
    );

    this.definitionsRight.set(
      rootToolbarDefinitions?.filter(
        (definition) => definition.alignment === 'right',
      ),
    );
  }

  private getToolbarDefinitionsToSetNestedOrHide(
    moreOptionsAvailable: boolean,
  ): {
    nested: ToolbarDefinition[];
    hidden: ToolbarDefinition[];
  } {
    const result: {
      nested: ToolbarDefinition[];
      hidden: ToolbarDefinition[];
    } = { nested: [], hidden: [] };
    let moveableOrRemovableDefinitions = this.toolbarDefinitions().filter(
      (d) =>
        (moreOptionsAvailable && !this.alwaysRootDefinitions().includes(d)) ||
        d.removable,
    );
    let spaceLeft =
      Math.max(
        (this.width() ?? 0) -
          this.getWidthOfStaticElements(moveableOrRemovableDefinitions),
        0,
      ) - this.BUFFER_SIZE;

    if (this.hideItemsDirection() === 'leftToRight') {
      moveableOrRemovableDefinitions = moveableOrRemovableDefinitions.reverse();
    }
    for (const definition of moveableOrRemovableDefinitions) {
      spaceLeft = spaceLeft - this.getSpaceNeededByDefinition(definition);
      if (spaceLeft < 0) {
        if (definition.removable) {
          result.hidden.push(definition);
        } else {
          result.nested.push(definition);
        }
      }
    }
    return result;
  }

  protected handleResize(event: ResizeObserverEntry) {
    this.ownWidth.set(event.contentRect.width);
  }

  private getWidthOfStaticElements(
    moveableToolbarDefinitions: ToolbarDefinition[],
  ) {
    let result = 0;
    this.toolbarDefinitions()
      .filter((td) => !moveableToolbarDefinitions.includes(td))
      .forEach((def) => {
        result += this.getSpaceNeededByDefinition(def);
      });
    return result;
  }

  protected identifyToolbarDefinition(definition: ToolbarDefinition) {
    return identifyToolbarDefinition(definition);
  }

  private getSpaceNeededByDefinition(def: ToolbarDefinition): number {
    if (!this.visibleItems().includes(def)) {
      return 0;
    }
    const measured = this.itemWidths.get(def);
    if (measured) {
      return measured;
    }
    if (def.componentWidth) {
      return def.componentWidth;
    }
    switch (def.actionType) {
      case 'inputButton':
        return this.INPUT_BUTTON_WIDTH;
      case 'moreOptionButton':
        return this.ICON_BUTTON_WIDTH;
      case 'toggleButton':
        return this.TOGGLE_BUTTON_WIDTH;
      case 'button':
      case undefined:
        if (def.buttonCSSClass === 'no-margin') {
          return 30;
        }
        if (def.iconName) {
          return this.ICON_BUTTON_WIDTH;
        }
        return this.BUTTON_WIDTH;
      case 'splitButton':
        return this.SPLIT_BUTTON_WIDTH;
      case 'filterButton':
        return this.FILTER_BUTTON_WIDTH;
      case 'customComponent':
        return this.COMPONENT_WIDTH;
      default:
        return 0;
    }
  }

  protected noWidth(toolbarDefinition: ToolbarDefinition) {
    return toolbarDefinition.componentWidth == null;
  }
}
