import { Signal, Type, untracked, WritableSignal } from '@angular/core';
import { Params } from '@angular/router';
import { Permission } from '@salary/common/authorization';
import { convertToSignal } from '@salary/common/formly';
import { Observable } from 'rxjs';
import { FilterButtonDateConfiguration } from '../filter-button-date/filter-button-date.component';
import { FilterButtonConfiguration } from '../filter-button/filter-button.component';
import { HotKeyDefinition } from '../hotkeys-dialog';

export interface ToolbarDefinition {
  title: string | Signal<string>;
  iconName?: string;
  routerLink?: string;
  queryParams?: Params;
  /**if true is returned, routerlinks will not be executed. Return void or false if only
   * an actionHandler is defined or both (routerLink + actionHandler) should be executed.
   * actionHandlers will be executed before routerlinks */
  actionHandler?: (value?: unknown) => boolean | void;
  buttonDisabled?: Signal<boolean>;
  loading?: Signal<boolean>;
  buttonVisibility?: Signal<boolean>;
  allowedByPermission?: Permission;
  buttonCSSClass?: string;
  /** default is left */
  alignment?: 'left' | 'right';
  /** To work properly you have to set viewModeDeletedVisible input of ToolbarComponent accordingly. default is visible in both modes*/
  visibleInViewMode?: 'deleted' | 'normal';
  /** To work properly you have to set emptyStateVisible input of ToolbarComponent accordingly. default is false*/
  visibleInEmptyState?: boolean;
  actionType?:
    | 'button'
    | 'toggleButton'
    | 'splitButton'
    | 'inputButton'
    | 'moreOptionButton'
    | 'customComponent'
    | 'selectionList'
    | 'filterButton'
    | 'filterButtonDate';
  hotkey?: WritableSignal<HotKeyDefinition>;
  confirmation?: () => Observable<boolean>;
  /**Caution: do not change signal values on your own. It's done in toolbar implementation */
  children?: WritableSignal<ToolbarDefinition[]>;
  /** if true, never nest to moreOptionsButton */
  alwaysRoot?: boolean;
  /** if true, action can be hidden without moving to moreOptionsButton*/
  removable?: boolean;
  /** if not set, no debounce */
  debounceTime?: number;
  initialValue$?: Observable<unknown>;
  componentType?: Type<unknown>;
  /** ATM only supported on moreOptionsButton */
  testId?: string;
  customComponentInput?: Map<string, unknown>;
  customComponentOuptutHandler?: Map<string, (event: unknown) => void>;
  //**if not set a default value will be taken. custom-components are likely to have a deviant width*/
  componentWidth?: number;
  stretch?: boolean;
  shrink?: boolean;
  tooltip?: Signal<string>;
  /** only works for splitButton at the moment */
  badgeText?: Signal<string>;
  /** only works for splitButton at the moment */
  badgeClickHandler?: () => void;
  filterButtonConfiguration?: FilterButtonConfiguration;
  filterButtonDateConfiguration?: FilterButtonDateConfiguration;
}

export function identifyToolbarDefinition(
  toolbarDefinition: ToolbarDefinition,
): string {
  return untracked(
    () =>
      convertToSignal('title', toolbarDefinition)() +
      toolbarDefinition.iconName +
      toolbarDefinition.actionType,
  );
}
