import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  untracked,
} from '@angular/core';
import { TipsFacade } from '@salary/common/facade';

@Component({
  selector: 'salary-tips-toolbar-item',
  template: `
    <salary-application-toolbar-item
      name="tips"
      icon="lightbulb"
      tooltip="Tipps"
      [badgeText]="badgeText()"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TipsToolbarItemComponent {
  private tipsFacade = inject(TipsFacade);
  protected badgeText = computed(() => {
    const tips = this.tipsFacade.tips();
    const lastIdSeen = this.tipsFacade.lastIdSeen();
    return untracked(() => {
      if (tips.length === 0) {
        return undefined;
      }
      const lastEntryIndex = tips.findIndex((e) => e.id === lastIdSeen);
      if (lastEntryIndex === -1 || !lastIdSeen) {
        return tips.length;
      }
      return lastEntryIndex;
    });
  });
}
