import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'salary-lohnkontext-closed-icon-component',
    template: `
    <mat-icon
      matTooltip="Monat abgeschlossen"
      data-testid="lohnkontext-abrechnungen-closed-icon"
    >
      lock
    </mat-icon>
  `,
    styles: `
    :host {
      display: flex;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LohnkontextClosedIconComponent {}
