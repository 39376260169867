import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'striphtml',
    standalone: false
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string) {
    return StripHtmlPipe.transform(value);
  }
  static transform(value: string) {
    return value?.replace(/<\/?[^>]+(>|$)/g, '');
  }
}
