import { Signal, Type } from '@angular/core';
import { FieldConfig, FieldProp } from '@salary/common/formly';
import { Observable } from 'rxjs';
import { SortOrder } from '../../../utils';

export interface RepeatExpandablePanelFieldConfig<
  LISTMODEL extends MODEL[] = [],
  CHILDCONFIG = RepeatExpandablePanelChildConfig,
  MODEL = LISTMODEL extends (infer U)[] ? U : LISTMODEL,
> extends Omit<FieldConfig<LISTMODEL, CHILDCONFIG>, 'disabled'> {
  type?: 'repeatPanel';
  removeAvailable?: FieldProp<boolean, this>;
  addAvailable?: FieldProp<boolean, this>;
  addRemoveAvailable?: FieldProp<boolean, this>;
  /**necessary to get caption-singular and caption-plural to be used for 'hinzufügen-button' */
  modelClass?: Type<MODEL>;
  expanded?: boolean;
  /** a property of the bound model, used to sort the panels */
  sortProperty?: string;
  sortDirection?: SortOrder;
  /** given function is called each time the user adds a new panel.
   * the model parameter can be preselected */
  initialNewItemModel?: (
    list: LISTMODEL,
    formState: unknown,
    field: this,
  ) => MODEL;
  /** @see {@link https://material.angular.io/components/expansion/api#MatAccordion} */
  multi?: boolean;
  accordionClasses?: string;
  panelClasses?: string;
  headerTitle?: FieldProp<string, this>;
  headerTitleLine2?: string;
  headerDescription?: string;
  referenceRouterLink?: string;
  showEmptyState?: boolean;
  notFoundText?: FieldProp<string, this>;
  deleteAllData$?: Observable<void>;
  addItems?: Signal<{
    items: LISTMODEL;
    expandPanel?: boolean;
    deleteOldItems?: boolean;
  }>;
  filter?: Signal<(item: MODEL) => boolean>;
  preventExpansion?: boolean;
  withExpansionPanel?: boolean;
  expansionPanelTitle?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface RepeatExpandablePanelChildConfig<T = any>
  extends FieldConfig<T> {
  headerTitle?: FieldProp<string, this>;
  headerTitleLine2?: FieldProp<string, this>;
  headerDescription?: FieldProp<string, this>;
  removeAvailable?: FieldProp<boolean, this>;
  additionalIconName?: FieldProp<string, this>;
  referenceRouterLink?: string;
}

export function isRepeatPanelField(
  field: FieldConfig,
): field is RepeatExpandablePanelFieldConfig {
  return field?.type === 'repeatPanel';
}
