import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  inject,
  output,
} from '@angular/core';
import { ResizeObserverService } from './resize-oberserver.service';

@Directive({
  selector: '[salaryOnResize]',
  standalone: false,
})
export class ResizeObserverDirective implements AfterViewInit, OnDestroy {
  resize = output<ResizeObserverEntry>({ alias: 'salaryOnResize' });
  private readonly elementRef = inject(ElementRef);
  private readonly resizeObserverService = inject(ResizeObserverService);

  ngAfterViewInit() {
    this.observe();
  }

  ngOnDestroy() {
    this.unobserve();
  }

  private observe() {
    this.resizeObserverService.observe(
      this.elementRef.nativeElement,
      (resize) => {
        this.resize.emit(resize);
      },
    );
  }

  private unobserve() {
    this.resizeObserverService.unobserve(this.elementRef.nativeElement);
  }
}
