import { Directive, OnInit, computed, inject } from '@angular/core';
import { EndpointConfigurationQuery } from '@salary/common/api/base-http-service';
import { AuthenticationService } from '@salary/common/authentication';
import { BaseModel, Rolle, StringWithSuggestions } from '@salary/common/dumb';
import { getPrimaryUrlWithoutParams } from '@salary/common/utils';
import { Subject } from 'rxjs';
import { ImportRegistrationService } from '../import';
import { ListDsmPerspectivesContainerComponent } from '../list-dsm-perspectives-container';
import { ToolbarDefinition } from '../utils';
import { ListContainerComponent } from './list-container.component';
import { EmptyStateLohnkontextProperty } from './list-empty-state.component';

type COMMON_PERSPECTIVE_NAMES =
  | 'standard'
  | 'abrechnungskreis'
  | 'lizenznehmer'
  | 'mandant';

@Directive()
export abstract class ListDsmPerspectivesComponentBase<T extends BaseModel>
  extends ListContainerComponent<T>
  implements OnInit
{
  initialized$ = new Subject<void>();
  afterViewInit$ = new Subject<void>();
  customizeObjectBeforeSaveFunc: (objectToCustomize) => void;
  validPerspectives =
    inject(ListDsmPerspectivesContainerComponent, {
      optional: true,
    })?.links.map((l) => l.path) ?? [];
  readonly activeDsmPerspective: StringWithSuggestions<COMMON_PERSPECTIVE_NAMES> =
    this.getInitializedActiveDsmPerspective();
  protected authenticationService = inject(AuthenticationService);
  private importRegistrationService = inject(ImportRegistrationService);
  private isRolleAV = computed(
    () => this.authenticationService.benutzerRolle() === Rolle.AV,
  );
  rowCount = computed(() => this.pagingInfo()?.paging?.rowCount, {
    equal: () => false,
  });
  constructor() {
    super();
    if (this.isDsmPerspective()) {
      this.enabledRequestWaitForEndpointConfigurationReady();
    }
    if (this.activeDsmPerspective === 'standard') {
      const importDefinition = this.getImportToolbarDefinitionIfAvailable();
      if (importDefinition) {
        this.importToolbarDefinition.buttonVisibility = this.isRolleAV;
        this.importToolbarDefinition.children().push(importDefinition);
      }
    }
  }

  protected getInitializedActiveDsmPerspective() {
    return getPrimaryUrlWithoutParams(this.router).split('/').pop();
  }

  protected isDsmPerspective() {
    return this.validPerspectives.includes(this.activeDsmPerspective);
  }

  modifyComponent() {
    return;
  }

  /**
   * Modify endpointConfigs that are not meant for badge-queries.
   */
  modifyBadgeEndpointConfiguration(
    _dsmPerspective: string,
    _endpointConfiguration: EndpointConfigurationQuery,
  ) {
    return;
  }

  /**
   * Return an object to be shared between DSM-Compoments (called on tab-switch)
   */
  getSharedData(): unknown {
    return undefined;
  }

  /**
   * Called after component was activated. Shared Data received by getSharedData() will be passed.
   */
  setSharedData(_data: unknown) {
    return;
  }

  /**
   * Return names of query-parameters that should be preserved (on tab-switch)
   */
  getQueryParameterToPreserve(): string[] {
    return undefined;
  }

  filterToolbarDefinitionsInReadonlyMode() {
    this.hinzufuegenVisible.set(false);
    this.entfernenVisible.set(false);
    this.papierkorbVisible.set(false);
  }

  showCopyToolbarActions(
    visibleCopyActions: 'all' | 'lizenznehmer' | 'abrechnungskreis' | 'none',
  ) {
    this.copyToLizenznehmerVisible.set(
      visibleCopyActions === 'all' || visibleCopyActions === 'lizenznehmer',
    );
    this.copyToAbrechnungskreisVisible$.next(
      visibleCopyActions === 'all' || visibleCopyActions === 'abrechnungskreis',
    );
  }

  switchColumnsToReadonly() {
    this.columnDefinitions?.forEach(
      (columnDefinition) => (columnDefinition.editable = false),
    );
  }

  switchShowNewItemRow(show: boolean) {
    this.hinzufuegenVisible.set(show);
  }

  override customizeObjectBeforeSave(objectToSave: T) {
    this.customizeObjectBeforeSaveFunc?.(objectToSave);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.initialized$.next();
    this.hinzufuegenToolbarDefinition.routerLink = '../hinzufuegen';
    const queryParam = this.getRouteByContext();
    if (queryParam) {
      this.hinzufuegenToolbarDefinition.queryParams = {
        lohnkontext: queryParam,
      };
    }
    this.listConfiguration().emptyStateToolbarDefinition =
      this.activeDsmPerspective === 'standard'
        ? {
            ...this.importToolbarDefinition,
            actionType: 'splitButton',
            title: 'Importieren',
          }
        : undefined;
  }

  ngAfterViewInit() {
    this.afterViewInit$.next(); //initial refresh => endpointConfiguration signal changes are already done
  }

  private getRouteByContext() {
    if (this.activeDsmPerspective === 'lizenznehmer') {
      return 'lizenznehmer';
    } else if (this.activeDsmPerspective === 'abrechnungskreis') {
      return 'abrechnungskreis';
    }
    return null;
  }

  override getEmptyStateLohnkontextProperty(): EmptyStateLohnkontextProperty {
    if (this.activeDsmPerspective === 'lizenznehmer') {
      return 'lizenznehmer';
    } else if (this.activeDsmPerspective === 'abrechnungskreis') {
      return 'abrechnungskreis';
    } else if (this.activeDsmPerspective === 'mandant') {
      return 'mandant';
    }
    return 'none';
  }

  private getImportToolbarDefinitionIfAvailable(): ToolbarDefinition {
    const importConfig = this.importRegistrationService
      .importConfigs()
      .find((config) => config.facade === this.facade);
    if (!importConfig) {
      return undefined;
    }
    return {
      title: `als ${importConfig.format.slice(1).toUpperCase()}-Datei`,
      actionHandler: () => this.startImport(importConfig),
      visibleInEmptyState: true,
      buttonVisibility: this.isRolleAV,
    };
  }
}
