import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  effect,
  input,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Permission } from '../config/authorization-config';
import { AuthorizationService } from '../services/authorization.service';

@Directive({
    selector: '[salaryWithPermission]',
    standalone: false
})
export class WithPermissionDirective {
  permission = input.required<Permission>({ alias: 'salaryWithPermission' });
  visibleIf = input(true, { alias: 'salaryWithPermissionVisibleIf' });
  byRoute = input<string>(undefined, { alias: 'salaryWithPermissionByRoute' });
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    authorizationService: AuthorizationService,
    activatedRoute: ActivatedRoute,
  ) {
    effect(() => {
      const permission = this.permission();
      const route = this.byRoute();
      const visibleIf = this.visibleIf();
      untracked(() => {
        const granted = authorizationService.hasPermission(
          permission,
          route ?? activatedRoute.snapshot,
        );
        this.showHideHost(granted && visibleIf);
      });
    });
  }
  // from https://angular.io/guide/structural-directives
  showHideHost(visible: boolean) {
    if (visible && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!visible && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
