import {
  ChangeDetectorRef,
  EmbeddedViewRef,
  Pipe,
  PipeTransform,
  Type,
  inject,
} from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false,
  standalone: false,
})
export class FilterPipe implements PipeTransform {
  private componentReference = (
    inject(ChangeDetectorRef) as EmbeddedViewRef<Type<unknown>>
  ).context;
  transform<T>(items: T[], callback: (item: T) => boolean): T[] {
    if (!items || !callback || !items.filter) {
      return items;
    }
    const boundCallback = callback.bind(this.componentReference);
    return items.filter((item) => boundCallback(item));
  }
}
