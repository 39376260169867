import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { SidebarRoutingService } from '../services/sidebar-routing.service';

@Component({
  selector: 'salary-application-toolbar-item',
  template: `
    <button
      mat-icon-button
      class="application-toolbar-item"
      (click)="onClick()"
      [attr.data-testid]="'applicationtoolbar_' + name() + '_item'"
      [class.active-application-toolbar-item]="isActive()"
      [matTooltip]="tooltip()"
    >
      <mat-icon
        [class.active-application-toolbar-item]="isActive()"
        matBadge
        [salaryTwoDigitBadge]="badgeText()"
        [salaryAnimatedBadge]="badgeText()"
        salaryBadgeColor="warn"
        >{{ icon() }}</mat-icon
      >
    </button>
  `,
  styles: `
    .application-toolbar-item {
      padding: 0;
      min-width: 40px;
      height: 100%;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    mat-icon {
      z-index: -1;
      background-color: transparent !important;
    }
    :host ::ng-deep .mat-mdc-button-persistent-ripple {
      border-radius: 0;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ApplicationToolbarItemComponent {
  name = input<string>();
  icon = input<string>();
  badgeText = input<number>();
  tooltip = input<string>();
  private router = inject(Router);
  private sidebarRouting = inject(SidebarRoutingService);
  private navigationEnd = toSignal(
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
    { equal: () => false },
  );
  protected isActive = computed(
    () =>
      this.navigationEnd() &&
      this.sidebarRouting.isOutletPathActive('extras', this.name()),
  );

  protected onClick() {
    this.sidebarRouting.toggleSidebar(this.name());
  }
}
