import { ICellRendererParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { replaceSpecialCharacters } from '@salary/common/dumb';
import { Observable, filter } from 'rxjs';
import { getRendererCellValue } from '../utils/get-renderer-cell-value';
import { isReferenceDeleted } from '../utils/reference-deleted';
import { SalaryLink } from '../utils/salary-link-handler';
import { getRouterLinkValue } from './utils/list-util';

@Component({
  selector: 'salary-list-input-line-renderer',
  template: `
    <div
      class="line-renderer"
      [style.display]="isReferenceDeleted() ? 'flex' : undefined"
      [attr.data-testid]="testId()"
    >
      @if (isReferenceDeleted()) {
        <mat-icon
          [style.align-self]="isReferenceDeleted() ? 'center' : undefined"
          [attr.data-testid]="
            testId() + '_search_field_reference_icon' | convertSpecialCharacter
          "
          [matTooltip]="
            cellValue() + ' wurde in den Papierkorb verschoben.' | striphtml
          "
          [class]="
            lineNumber() === 1
              ? 'largeReferenceDeletedItem'
              : 'smallReferenceDeletedItem'
          "
          >link_off</mat-icon
        >
      }

      <div
        [style.align-self]="isReferenceDeleted() ? 'center' : undefined"
        style="width:100%"
        salaryEllipsis
        [class]="lineNumber() === 1 ? 'content-box' : 'smallFont'"
      >
        @if (routerLinkValue() && routerLinkText()) {
          <span>
            <a
              [style.align-self]="isReferenceDeleted() ? 'center' : undefined"
              class="linkColorAccent"
              [class]="lineNumber() === 1 ? 'content-box' : 'smallFont'"
              [routerLink]="routerLinkValue()"
              >{{ routerLinkText() }}</a
            >
            <mat-icon
              [routerLink]="routerLinkValue()"
              class="link-icon"
              [attr.data-testid]="testId() + '_link_icon'"
              >{{ routerLink() ? 'open_in_new' : 'link' }}</mat-icon
            >
          </span>
        } @else {
          {{ routerLinkText() }}
        }
        {{ routerLinkTextAfterLink() }}
      </div>
    </div>
  `,
  styles: `
    .line-renderer:hover .link-icon {
      display: inherit;
    }
    .content-box {
      min-height: 16.8px;
    }
    .smallReferenceDeletedItem {
      font-size: 11px;
      margin-right: 3px;
      width: 11px;
      height: 11px;
    }
    .largeReferenceDeletedItem {
      font-size: 21px;
      margin-right: 5px;
    }
    .smallFont {
      font-size: 11px;
      padding-top: 3px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ListInputLineRendererComponent implements OnInit {
  protected params = input<ICellRendererParams>();
  protected lineNumber = input<1 | 2>();
  protected routerLink = signal<string>(undefined);
  protected cellValue = signal<string>(undefined);
  private destroyRef = inject(DestroyRef);
  private cellRendererParamsInitialized = false;
  private fieldName = signal<string>(undefined);

  constructor() {
    effect(() => {
      this.updateCellValue();
    });

    effect(() => {
      const cellRendererParams = this.params();
      if (cellRendererParams != null && !this.cellRendererParamsInitialized) {
        this.cellRendererParamsInitialized = true;
        const refresh$: Observable<number> =
          cellRendererParams?.colDef?.cellRendererParams?.refreshCellRenderer$;
        if (refresh$) {
          refresh$
            .pipe(
              filter(
                (index) =>
                  index == null || index === cellRendererParams.node.rowIndex,
              ),
              takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(() => this.updateCellValue());
        }
      }
    });
  }

  private updateCellValue() {
    this.cellValue.set(getRendererCellValue(this.params(), this.lineNumber()));
  }

  protected isReferenceDeleted = computed(() => {
    return (
      this.params().data &&
      this.fieldName() &&
      isReferenceDeleted(this.fieldName(), this.params().data)
    );
  });
  protected testId = computed(() => {
    let testId =
      'renderer_' + this.fieldName() + '_' + this.params()?.node?.rowIndex;
    if (this.lineNumber() === 2) {
      testId = 'line2_' + testId;
    }
    return replaceSpecialCharacters(testId);
  });
  protected routerLinkValue = computed(() => {
    return this.getRouterLinkValue(this.cellValue());
  });
  protected routerLinkText = computed(() => {
    return this.getLinkText(this.cellValue());
  });

  protected routerLinkTextAfterLink = computed(() => {
    return this.getTextAfterLink(this.cellValue());
  });

  ngOnInit() {
    if (this.lineNumber() === 1) {
      this.routerLink.set(this.params().colDef.cellRendererParams?.routerLink);
      this.fieldName.set(this.params().colDef.field);
    } else {
      this.routerLink.set(this.params().colDef.cellRendererParams?.routerLink2);
      this.fieldName.set(
        this.params().colDef.cellRendererParams?.modelPropertyNameLine2,
      );
    }
  }

  getRouterLinkValue(cellValue: string) {
    return getRouterLinkValue(cellValue, this.params().data, this.routerLink());
  }

  getLinkText(value: string) {
    value = value?.toString();
    if (!value || this.routerLink()) {
      return value;
    }
    return SalaryLink.getLinkText(value);
  }

  getTextAfterLink(value: string) {
    value = value?.toString();
    if (this.routerLink()) {
      return '';
    }
    if (!SalaryLink.includesSalaryLink(value)) {
      return value;
    }
    const result = SalaryLink.getTextAfterLink(value)?.trim();
    return result === '-' ? '' : result;
  }
}
