import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SettingsFacade } from '@salary/common/facade';
import { Subject, takeUntil, timer } from 'rxjs';
@Component({
    selector: 'salary-loading-page',
    templateUrl: './loading-page.component.html',
    styleUrl: './loading-page.component.scss',
    animations: [
        trigger('slideContent', [
            state('leave', style({ transform: 'translate3d(0, 50%, 0)', opacity: 0 })),
            transition('void => leave', animate('500ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LoadingPageComponent implements OnInit, OnDestroy {
  private readonly darkOuterColor = '#36991a';
  private readonly lightOuterColor = '#8dbab9';
  private readonly darkInnerColor = '#303030;#36991a;#36991a';
  private readonly lightInlineColor = '#1a7572;#8dbab9;#8dbab9';
  protected animationState = signal<'void' | 'leave'>('void');
  protected darkMode = signal(false);
  protected innerColor = signal(this.lightInlineColor);
  protected outerColor = signal(this.lightOuterColor);
  protected longLoadingDetected = signal<boolean>(false);
  protected stopDetectLongLoading$ = new Subject<void>();
  private static readonly LONG_LOADING_THRESHOLD = 45 * 1000;
  private destroyRef = inject(DestroyRef);
  closeComponent = input(false);
  closeAnimationDone = output();

  constructor(settingFacade: SettingsFacade) {
    const effRef = effect(() => {
      const loaded = settingFacade.select.isLocalStorageSettingsLoaded();
      untracked(() => {
        if (loaded) {
          effRef.destroy();
          const settings = settingFacade.select.settings();
          const darkMode = settings.some(
            (setting) => setting.key === 'darkMode',
          );
          if (darkMode) {
            this.darkMode.set(true);
            this.innerColor.set(this.darkInnerColor);
            this.outerColor.set(this.darkOuterColor);
          }
        }
      });
    });
    effect(() => {
      const closeComponent = this.closeComponent();
      untracked(() => {
        if (closeComponent) {
          this.startExitAnimation();
        }
      });
    });
  }

  ngOnInit() {
    timer(LoadingPageComponent.LONG_LOADING_THRESHOLD)
      .pipe(
        takeUntil(this.stopDetectLongLoading$),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.longLoadingDetected.set(true));
  }

  ngOnDestroy() {
    this.stopDetectLongLoading$.next();
  }

  startExitAnimation() {
    this.stopDetectLongLoading$.next();
    this.longLoadingDetected.set(false);
    this.animationState.set('leave');
  }

  protected onAnimationDone(event: AnimationEvent) {
    if (event.toState === 'leave') {
      this.closeAnimationDone.emit();
    }
  }
}
