import { Injectable, computed, signal } from '@angular/core';
import { DateTimeFormats } from '@salary/common/dumb';
import {
  IAppLongRunningProcessService,
  LongRunningProcessDefinition,
} from '@salary/common/utils';
import { DateTime } from 'luxon';
@Injectable()
export class AppLongRunningProcessService
  implements IAppLongRunningProcessService
{
  longRunningProcesses = signal<LongRunningProcessDefinition[]>([]);
  buttonVisible = computed(() => this.longRunningProcesses().length > 0);

  addLongRunningProcess(resultItem: LongRunningProcessDefinition) {
    resultItem.processDate = DateTime.now().toFormat(DateTimeFormats.DATE_TIME);
    this.longRunningProcesses.update((list) => list.concat(resultItem));
  }
  longRunningResultChanged() {
    this.longRunningProcesses.update((list) => [...list]);
  }
}
