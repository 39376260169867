import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  effect,
  inject,
  output,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { SalaryLayoutLeftDirective } from './directives';
import { SidebarRoutingService } from './services/sidebar-routing.service';

@Component({
    selector: 'salary-layout',
    templateUrl: './layout.component.html',
    styleUrl: './layout.component.scss',
    animations: [
        trigger('leftSidenavExpandCollapse', [
            state('*', style({
                marginLeft: '{{margin_left}}',
            }), { params: { margin_left: '300px' } }),
            transition('* <=> *', [
                animate('750ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LayoutComponent {
  private breakpointObserver = inject(BreakpointObserver);
  protected isLeftHandset = toSignal(
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(map((result) => result.matches)),
  );

  protected isRightHandset = toSignal(
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
      .pipe(map((result) => result.matches)),
  );

  private router = inject(Router);

  private layoutLeft = contentChild(SalaryLayoutLeftDirective);
  protected isLayoutLeftExpanded = computed(() =>
    this.layoutLeft()?.isExpanded(),
  );
  private sidenavContainer = viewChild(MatSidenavContainer);
  protected drawerLeftMargin = computed(() => {
    if (this.isLeftHandset()) {
      return '0px';
    }
    return this.isLayoutLeftExpanded() ? '300px' : '65px';
  });
  private leftSidenav = viewChild<MatSidenav>('drawerLeft');
  leftPanelOverlayModeChanged = output<boolean>();
  protected sidebarRouting = inject(SidebarRoutingService);
  protected sidebarOpen = this.sidebarRouting.sidebarOpen;

  constructor() {
    effect(() => {
      if (this.isLayoutLeftExpanded() === false && this.sidenavContainer()) {
        setTimeout(() => this.sidenavContainer().updateContentMargins(), 500);
      }
    });
    effect(() => this.leftPanelOverlayModeChanged.emit(this.isLeftHandset()));
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        filter(() => this.isLeftHandset()),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.leftSidenav()?.close());
  }

  protected drawerRightOpenedChange(open: boolean) {
    this.sidebarRouting.sidebarOpenedChange(open);
  }
}
