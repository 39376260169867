import {
  DestroyRef,
  EventEmitter,
  Injectable,
  Injector,
  computed,
  effect,
  inject,
  runInInjectionContext,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  AbstractControlOptions,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  defineHiddenProp,
  mergeDeep,
  mergeDeepWithoutOverride,
} from '@salary/common/utils';
import { Subject } from 'rxjs';
import { convertToSignal } from '../pipes';
import { injectFormlyConfig } from '../utils';
import {
  FORMLY_VALIDATORS,
  VALIDATORS_TYPE,
  assignFieldValue,
  findControl,
  getField,
  getFieldValue,
  getKeyPath,
  hasKey,
  noParentIsHidden,
  registerControl,
  unregisterControl,
  wrapNgValidatorFn,
} from '../utils/helpers';
import { exactLengthValidator } from '../validation';
import { FieldArrayType } from './field-array-type';
import { FieldConfig, FormlyValueChangeEvent } from './field.config';

@Injectable()
export class FormBuilder {
  private injector = inject(Injector);
  formlyConfig = injectFormlyConfig();
  private formId = 0;
  private root: FieldConfig;
  build(field: FieldConfig) {
    if (!field.parent) {
      this.setOptions(field);
    }
    this._build(field);
  }

  private _build(field: FieldConfig) {
    if (!field) {
      return;
    }
    this.prePopulate(field);
    this.onPopulate(field);
    field.fieldGroup?.forEach((f) => this._build(f));
    this.postPopulate(field);
    field['extensionsExecuted'] = true;
  }
  postPopulate(field: FieldConfig) {
    if (this.root === field) {
      this.root = null;
      const markForCheck = this.setValidators(field);
      if (markForCheck && field.parent) {
        let parent = field.parent;
        while (parent) {
          if (hasKey(parent) || !parent.parent) {
            this.updateValidity(parent.formControl, true);
          }
          parent = parent.parent;
        }
      }
    }
    if (!field['extensionsExecuted']) {
      this.formlyConfig.postPopulateExtensions?.forEach((extension) => {
        runInInjectionContext(
          Injector.create({
            providers: [],
            parent: this.injector,
          }),
          () => extension(field),
        );
      });
    }
  }

  private updateValidity(c: AbstractControl, onlySelf = false) {
    const status = c.status;
    const value = c.value;
    c.updateValueAndValidity({ emitEvent: false, onlySelf });
    if (status !== c.status) {
      (c.statusChanges as EventEmitter<string>).emit(c.status);
    }

    if (value !== c.value) {
      (c.valueChanges as EventEmitter<unknown>).emit(c.value);
    }
  }

  private setOptions(field: FieldConfig) {
    field.form = field.form ?? new FormGroup({});
    field.options = field.options ?? {};
    const options = field.options;
    if (!options.build) {
      options.build = (f: FieldConfig = field) => {
        this.build(f);
        return f;
      };
    }
  }

  prePopulate(field: FieldConfig) {
    if (!this.root) {
      this.root = field;
    }
    if (!field.writableRootModel) {
      field.writableRootModel = this.root.writableRootModel;
    }
    if (!field.model) {
      field.model = computed(
        () => {
          if (!field.parent) {
            return field.writableRootModel();
          }

          return hasKey(field) && field.fieldGroup
            ? getFieldValue(field)
            : field.parent.model();
        },
        { equal: () => false },
      );
    }
    this.initRootOptions(field);
    this.initChildOptions(field);
    field._elementRef ??= signal(undefined);
    field.suffix ??= signal(undefined);
    field.prefix ??= signal(undefined);
    field.options.fieldChanges
      .pipe(takeUntilDestroyed(field._injector.get(DestroyRef)))
      .subscribe(() => {
        field.writableRootModel.set(field.writableRootModel());
      });
    Object.defineProperty(field, 'get', {
      value: (key: FieldConfig['key']) => getField(field, key),
      configurable: true,
    });
    if (!field['extensionsExecuted']) {
      this.formlyConfig.prePopulateExtensions?.forEach((extension) => {
        runInInjectionContext(
          Injector.create({
            providers: [],
            parent: this.injector,
          }),
          () => extension(field),
        );
      });
    }
  }
  initRootOptions(field: FieldConfig) {
    if (field.parent) {
      return;
    }
    field.options.formState = field.options.formState ?? {};
    if (!field.options.fieldChanges) {
      defineHiddenProp(
        field.options,
        'fieldChanges',
        new Subject<FormlyValueChangeEvent>(),
      );
    }

    field.options.resetModel = () => {
      field.options.build(field);
      field.form.reset(field.model());
    };
  }

  initChildOptions(field: FieldConfig) {
    const root = field.parent;
    if (!root) {
      return;
    }
    field._injector = root._injector;
    Object.defineProperty(field, 'options', {
      get: () => root.options,
      configurable: true,
    });
    Object.defineProperty(field, 'form', {
      get: () => field.parent.formControl,
      configurable: true,
    });
  }

  onPopulate(field: FieldConfig) {
    this.initFieldOptions(field);
    if (field.fieldArray) {
      FieldArrayType.onPopulate(field);
    }
    if (field.fieldGroup) {
      field.fieldGroup.forEach((f, index) => {
        if (f) {
          f.parent = field;
          f.index = index;
        }
        this.formId++;
      });
    }
    if (Object.hasOwn(field, 'fieldGroup') && !hasKey(field)) {
      defineHiddenProp(field, 'formControl', field.form);
    } else {
      this.addFormControl(field);
    }
    this.initHide(field);
    this.initFieldValidation(field);
  }
  initHide(field: FieldConfig) {
    if (field.hide == null) {
      return;
    }
    const hideAsSignal = convertToSignal('hide', field);
    effect(
      () => {
        const hide = hideAsSignal();
        untracked(() => {
          this.changeHideState(field, hide);
        });
      },
      { injector: field._injector },
    );
  }

  private changeHideState(field: FieldConfig, hide: boolean) {
    if (field.fieldGroup) {
      field.fieldGroup.forEach((f) => this.changeHideState(f, hide));
    }
    if (field.formControl && hasKey(field)) {
      if (hide) {
        unregisterControl(field, true);
      } else if (noParentIsHidden(field)) {
        if (
          field.noDefaultValueIfHidden &&
          field.defaultValue != null &&
          getFieldValue(field) == null
        ) {
          assignFieldValue(field, field.defaultValue);
        }
        registerControl(field, undefined);
      }
    }
  }

  private addFormControl(field: FieldConfig) {
    if (field.fieldArray) {
      return;
    }
    let control = findControl(field);

    if (!control) {
      const controlOptions: AbstractControlOptions = {
        updateOn: field.updateOn,
      };

      if (field.fieldGroup) {
        control = new FormGroup({}, controlOptions);
      } else {
        const value = hasKey(field) ? getFieldValue(field) : field.defaultValue;
        control = new FormControl(
          { value, disabled: !!convertToSignal('disabled', field)() },
          {
            ...controlOptions,
            nonNullable: true,
          },
        );
      }
      registerControl(field, control);
    } else if (
      field.noDefaultValueIfHidden !== true ||
      noParentIsHidden(field)
    ) {
      registerControl(field, control);
    }
  }

  private initFieldOptions(field: FieldConfig) {
    mergeDeep(field, {
      id: this.getFieldId(`formly_${this.formId}`, field, field.index),
      hooks: {},
    });
    if (!field._highlightField) {
      field._highlightField = signal(undefined, { equal: () => false });
    }
    if (!field._validationMarkerText) {
      field._validationMarkerText = signal(undefined);
    }

    if (!field.type && field.fieldGroup) {
      field.type = 'formly-group';
    }

    if (field.type) {
      const config = this.formlyConfig.fieldTypes.find(
        (c) => c.name === field.type,
      );
      const defaultOptions = config.component['defaultOptions'];
      if (defaultOptions) {
        mergeDeepWithoutOverride(field, defaultOptions);
      }
      if (
        hasKey(field) &&
        field.defaultValue != null &&
        getFieldValue(field) == null
      ) {
        if (!field.noDefaultValueIfHidden) {
          assignFieldValue(field, field.defaultValue);
        }
      }
    }
  }

  getFieldId(formId: string, field: FieldConfig, index: string | number) {
    if (field.id) {
      return field.id;
    }
    return [formId, field.type, field.key, index].join('_');
  }

  private initFieldValidation(field: FieldConfig) {
    const validators: ValidatorFn[] = [];
    if (!(Object.hasOwn(field, 'fieldGroup') && !hasKey(field))) {
      validators.push(this.getPredefinedFieldValidation(field));
    }

    if (field.validators) {
      for (const validatorName of Object.keys(field.validators)) {
        validators.push(
          wrapNgValidatorFn(
            field,
            field.validators[validatorName],
            validatorName,
          ),
        );
      }
    }

    defineHiddenProp(field, '_validators', validators);
  }

  private setValidators(field: FieldConfig, disabled = false) {
    const disabledSignal = convertToSignal('disabled', field);
    if (disabled === false && hasKey(field) && disabledSignal()) {
      disabled = true;
    }
    let markForCheck = false;
    field.fieldGroup?.forEach(
      (f) => f && this.setValidators(f, disabled) && (markForCheck = true),
    );
    if (
      hasKey(field) ||
      !field.parent ||
      (!hasKey(field) && !field.fieldGroup)
    ) {
      const { formControl: c } = field;
      if (c) {
        if (hasKey(field) && c instanceof FormControl) {
          if (disabled && c.enabled) {
            c.disable({ emitEvent: false, onlySelf: true });
            markForCheck = true;
          }

          if (!disabled && c.disabled) {
            c.enable({ emitEvent: false, onlySelf: true });
            markForCheck = true;
          }
        }

        if (c.validator == null && this.hasValidators(field)) {
          c.setValidators(() => {
            const v = Validators.compose(
              this.mergeValidators<ValidatorFn>(field),
            );

            return v ? v(c) : null;
          });
          markForCheck = true;
        }

        if (markForCheck) {
          this.updateValidity(c, true);
          // update validity of `FormGroup` instance created by field with nested key.
          let parent = c.parent;
          for (let i = 1; i < getKeyPath(field).length; i++) {
            if (parent) {
              this.updateValidity(parent, true);
              parent = parent.parent;
            }
          }
        }
      }
    }

    return markForCheck;
  }

  private hasValidators(field: FieldConfig): boolean {
    const c = field.formControl;
    if (
      (c?.['_fields']?.length > 1 &&
        c?.['_fields'].some((f) => f['_validators'].length > 0)) ||
      field['_validators'].length > 0
    ) {
      return true;
    }
    return field.fieldGroup?.some(
      (f) => f?.fieldGroup && !hasKey(f) && this.hasValidators(f),
    );
  }

  private mergeValidators<T>(field: FieldConfig): T[] {
    const validators = [];
    const c = field.formControl;
    if (c?.['_fields']?.length > 1) {
      c['_fields']
        .filter((f: FieldConfig) => !convertToSignal('hide', f)())
        .forEach((f: FieldConfig) => validators.push(...f['_validators']));
    } else if (field['_validators']) {
      validators.push(...field['_validators']);
    }

    if (field.fieldGroup) {
      field.fieldGroup
        .filter((f) => f?.fieldGroup && !hasKey(f))
        .forEach((f) => validators.push(...this.mergeValidators(f)));
    }
    return validators;
  }

  private getPredefinedFieldValidation(field: FieldConfig): ValidatorFn {
    let VALIDATORS: VALIDATORS_TYPE[] = [];
    FORMLY_VALIDATORS.filter((opt) => field[opt] != null).forEach((opt) => {
      const validatorAsSignal = convertToSignal(opt, field);
      effect(
        () => {
          const validationEnabled = validatorAsSignal();
          untracked(() => {
            VALIDATORS = VALIDATORS.filter((o) => o !== opt);
            if (validationEnabled != null && validationEnabled !== false) {
              VALIDATORS.push(opt);
            }
            if (field.formControl) {
              this.updateValidity(field.formControl);
            }
          });
        },
        { injector: field._injector },
      );
    });

    return (control: AbstractControl) => {
      if (VALIDATORS.length === 0) {
        return null;
      }
      return Validators.compose(
        VALIDATORS.map((opt) => () => {
          // 'required' would be of type FieldProp, but it's not needed here
          const value = field[opt] as number;
          switch (opt) {
            case 'required':
              return Validators.required(control);
            case 'maxLength':
              return Validators.maxLength(value)(control);
            case 'minLength':
              return Validators.minLength(value)(control);
            case 'exactLength':
              return exactLengthValidator(value)(control);
            default:
              return null;
          }
        }),
      )(control);
    };
  }
}
