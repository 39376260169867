import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { AppDownloadConfigurationOption } from './utils/app-download-configuration.options';

@Component({
    selector: 'salary-app-download',
    template: `
    <div class="container">
      <mat-label data-testid="app_download_description">{{
        appDownloadConfigurationOption().description
      }}</mat-label>
      <div style="flex: auto"></div>
      <a
        mat-button
        [href]="appDownloadConfigurationOption().url"
        data-testid="app_download_download_button"
        >Herunterladen</a
      >
    </div>
  `,
    styles: [
        `
      .container {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppDownloadComponent {
  appDownloadConfigurationOption = input<AppDownloadConfigurationOption>();
}
